import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import {
    getCurrentUser,
    getPermission,
    getFilterDate,
    setFilterDate,
} from "../../helpers/Utils";
import SingleList from "./single-list";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import InputFilter from "../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";

const AllServices = (props) => {
    const history = useHistory();
    const [ref_filter, setRef_filter] = useState(false);
    const [proName_filter, setProName_filter] = useState(false);
    const [price_filter, setPrice_filter] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [timer, setTimer] = useState(null);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/new-service");
        }
    };
    const getAllServices = (e) => {
        setSearchValue(e);
        localStorage.setItem('search', `service, ${e}`)
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getServices(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getServices = (e) => {
        setData([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setData(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setData(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };
    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "product_name") {
            localStorage.setItem("productName", e);
        }
        if (type == "price") {
            localStorage.setItem("price", e);
        }
        setData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/services?&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&name=${localStorage.getItem("productName") !== null
                    ? localStorage.getItem("productName")
                    : ""
                }&price=${localStorage.getItem("price") !== null
                    ? localStorage.getItem("price")
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setData(res.data.products);
                    setRef_filter(false);
                    setProName_filter(false);
                    setPrice_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(ele[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/services/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    getServices();
                    setdynamic_description(res.data.message);
                }
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/service-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };
    const getDuplicate = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }

        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/services/duplicate-service`,
                {
                    id: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/service/${res.data.data.id}`);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    useEffect(()=>{
        let savedSearch = localStorage.getItem('search');
        if (savedSearch && savedSearch.split(',')[0] === 'service') {
            setSearchValue(savedSearch.split(',')[1])
            getServices(savedSearch.split(',')[1])
        } else {
            getServices();
        }
    }, [])
    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {/* {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          warning
          showCancel
          
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            deleteMultiple();
            setdynamic_title("Deleted");
            setdynamic_description("Your file has been deleted.");
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">
            You won&apos;t be able to revert this!
          </span>
        </SweetAlert>
      ) : null} */}
            <div className="page-content">
                <MetaTags>
                    <title>All Services | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="page-title">{props.t("All Services")} </h6>
                                </Col>
                                <Col md={8}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                value={searchValue}
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getAllServices(e.target.value)}
                                            />
                                        </div>
                                        {getPermission().services.services.create.is_checked ===
                                            1 ? (
                                            <>
                                                <div className="btn-group me-2">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            className="btn btn-primary"
                                                        // id="dropdown-basic"
                                                        >
                                                            {props.t("More")}
                                                            {/* <i className="fa fa-caret-down ms-2"></i> */}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {/* <Dropdown.Item>
                            <li className="nav-item mb-2">
                              {" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setconfirm_alert(true);
                                }}
                              >
                                Batch delete
                              </span>
                            </li>
                          </Dropdown.Item> */}

                                                            <Dropdown.Item>
                                                                <li className="nav-item mb-2">
                                                                    {" "}
                                                                    <span
                                                                        // className="dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDuplicate()}
                                                                    >
                                                                        {props.t("Duplicate")}
                                                                    </span>
                                                                </li>

                                                                {/* </ul> */}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <li className="nav-item mb-2">
                                                                    <span
                                                                        // className="dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getExport()}
                                                                    >
                                                                        {props.t("Export")}
                                                                    </span>
                                                                </li>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>

                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => createNew()}
                                                >
                                                    {props.t("Create New")}
                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                    />
                                                </th>
                                                <th>{props.t("Image")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Name")}
                                                        {proName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setProName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("productName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setProName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setProName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            proName_filter={proName_filter}
                                                            setProName_filter={setProName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Price")}
                                                        {price_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setPrice_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("price") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setPrice_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setPrice_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            price_filter={price_filter}
                                                            setPrice_filter={setPrice_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Promotional")}</th>
                                                <th>{props.t("Activity")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map((item, i) => (
                                                    <SingleList
                                                        data={item}
                                                        key={i}
                                                        i={i}
                                                        selectedIds={selectedIds}
                                                        setSelectedIds={setSelectedIds}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(AllServices);
AllServices.propTypes = {
    t: PropTypes.any,
};

import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const axios = require("axios").default;

import { getCurrentUser, getPermission } from "../../helpers/Utils";
import BrandModal from "./brand-modal";

function SingleList(props) {
    const [brand, setBrand] = useState(props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const editBrand = () => {
        setIsOpen(true);
        setIsOpenModal(true);
    };

    const deleteBrand = () => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/brand/${brand.id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                props.setAllBrands((prevBrands) =>
                    prevBrands.filter((b) => b.id !== brand.id) 
                );
                setdynamic_description(res.data.message);
            });
    };

    if (brand === null) {
        return "";
    } else
        return (
            <>
                {isOpen ? (
                    <BrandModal
                        isOpenModalBrand={isOpenModal}
                        setIsOpenModalBrand={setIsOpenModal}
                        setBrands={setBrand}
                        brands={brand}
                        isCatalog={true}
                    />
                ) : null}
                <tr>
                    <td
                        role="button"
                        onClick={
                            getPermission().product_categories.product_categories.edit
                                .is_checked === 1
                                ? () => editBrand()
                                : ""
                        }
                    >
                        {brand.name}
                    </td>
                    <td
                        role="button"
                        onClick={
                            getPermission().product_categories.product_categories.edit
                                .is_checked === 1
                                ? () => editBrand()
                                : ""
                        }
                    >
                        {brand.description}
                    </td>
                    {getPermission().product_categories.product_categories.delete
                        .is_checked === 1 ? (
                        <td className="text-end">
                            <Button
                                color="danger"
                                onClick={() => {
                                    setconfirm_alert(true);
                                }}
                            >
                                {props.t("Delete")}
                            </Button>
                        </td>
                    ) : (
                        ""
                    )}
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel

                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            deleteBrand(brand.id);
                            setconfirm_alert(false);
                            setsuccess_dlg(true);
                            setdynamic_title("Deleted");
                            setdynamic_description("The brand has been deleted.");
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
            </>
        );
}
export default withTranslation()(SingleList);
SingleList.propTypes = {
    t: PropTypes.any,
};

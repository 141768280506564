import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Alert, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import AllAssestCustom from "../../components/Assests/all-assests";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import Dropdown from "react-bootstrap/Dropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import InputFilter from "../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";

const AssestCustom = (props) => {
    const history = useHistory();
    const [ref_filter, setRef_filter] = useState(false);
    const [cliName_filter, setCliName_filter] = useState(false);
    const [name_filter, setName_filter] = useState(false);
    const [serial_filter, setSerial_filter] = useState(false);
    const [showAssets, setShowAssets] = useState([]);
    const [error, setError] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [timer, setTimer] = useState(null);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/create-assests");
        }
    };
    const getAllAssests = (e) => {
        setSearchValue(e);
        localStorage.setItem('search', `asset, ${e}`)
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            AllAssests(e);
        }, 1000);
        setTimer(newTimer);
    };

    const AllAssests = (e) => {
        setShowAssets([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-assets?search=${e}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setShowAssets(res.data.client_assets);
                    setError(res.data.message);
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-assets`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setShowAssets(res.data.client_assets);
                    setError(res.data.message);
                });
        }
    };

    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "client_name") {
            localStorage.setItem("clientName", e);
        }
        if (type == "name") {
            localStorage.setItem("name", e);
        }
        if (type == "serial_number") {
            localStorage.setItem("serialNumber", e);
        }
        setShowAssets([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets?&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&client_name=${localStorage.getItem("clientName") !== null
                    ? localStorage.getItem("clientName")
                    : ""
                }&name=${localStorage.getItem("name") !== null
                    ? localStorage.getItem("name")
                    : ""
                }&serial_number=${localStorage.getItem("serialNumber") !== null
                    ? localStorage.getItem("serialNumber")
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setShowAssets(res.data.client_assets);
                    setRef_filter(false);
                    setCliName_filter(false);
                    setName_filter(false);
                    setSerial_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };
    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    AllAssests();
                    setdynamic_description(res.data.message);
                }
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };

    useEffect(()=>{
        let savedSearch = localStorage.getItem('search');
        if (savedSearch && savedSearch.split(',')[0] === 'asset') {
            setSearchValue(savedSearch.split(',')[1])
            AllAssests(savedSearch.split(',')[1])
        } else {
            AllAssests();
        }
    }, [])

    const getDuplicate = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select one document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets/duplicate-asset`,
                {
                    id: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/assets/${res.data.data.id}`);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {/* {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          warning
          showCancel
          
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            deleteMultiple();
            setdynamic_title("Deleted");
            setdynamic_description("Your file has been deleted.");
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">
            You won&apos;t be able to revert this!
          </span>
        </SweetAlert>
      ) : null} */}
            <div className="page-content">
                <MetaTags>
                    <title>Assets Services | Clouget</title>
                </MetaTags>
                <Container fluid className="mt-3">
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={4}>
                                <h6 className="page-title">{props.t("Assets")}</h6>
                            </Col>
                            <Col md={8}>
                                <div className="float-end d-flex">
                                    <div className="me-2">
                                        <input
                                            className="form-control"
                                            type="search"
                                            value={searchValue}
                                            placeholder={props.t("Search here")}
                                            onChange={(e) => getAllAssests(e.target.value)}
                                        />
                                    </div>
                                    <div className="btn-group me-2">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                className="btn btn-primary"
                                            // id="dropdown-basic"
                                            >
                                                {props.t("More")}
                                                {/* <i className="fa fa-caret-down ms-2"></i> */}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item>
                          <li className="nav-item mb-2">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setconfirm_alert(true);
                              }}
                            >
                              Batch delete
                            </span>
                          </li>
                        </Dropdown.Item> */}
                                                <Dropdown.Item>
                                                    <li className="nav-item">
                                                        <span
                                                            // className="dropdown-item"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => getDuplicate()}
                                                        >
                                                            {props.t("Duplicate")}
                                                        </span>
                                                    </li>
                                                    {/* </ul> */}
                                                    {/* </ul> */}
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <li className="nav-item mb-2">
                                                        <span
                                                            // className="dropdown-item"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => getExport()}
                                                        >
                                                            {props.t("Export")}
                                                        </span>
                                                    </li>
                                                    {/* </ul> */}
                                                    {/* </ul> */}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {getPermission().client_assets.client_assets.create
                                        .is_checked === 1 ? (
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => createNew()}
                                        >
                                            {props.t("Create New")}
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Col>
                            {/* <Col md={6}>
                <div className="float-end d-flex">
                  <div className="me-3">
                    <input
                      style={{
                        width: "350px",
                        height: "36px",
                        borderRadius: "5px",
                      }}
                      type="search"
                      placeholder="Search here"
                      //   onChange={handleChange}
                      //   value={searchInput}
                    />
                  </div>
              </Col> */}
                        </Row>
                    </div>
                    <Card>
                        <CardBody>
                            {error ? (
                                <Alert color="danger" className="my-3">
                                    {props.t(error)}
                                </Alert>
                            ) : null}
                            <div className="table-responsive">
                                <Table striped className="align-middle">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    onClick={(e) => selectAllCheck(e.target)}
                                                    className="p-0 d-inline-block"
                                                    type="checkbox"
                                                />
                                            </th>
                                            <th>{props.t("Image")}</th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    {props.t("Reference")}
                                                    {ref_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setRef_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("reference") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setRef_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        ref_filter={ref_filter}
                                                        setRef_filter={setRef_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    {props.t("Name")}
                                                    {name_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setName_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("name") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setName_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setName_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        name_filter={name_filter}
                                                        setName_filter={setName_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>{props.t("Identifier")}</th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    {props.t("Serial Number")}
                                                    {serial_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setName_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("serialNumber") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setSerial_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setSerial_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        serial_filter={serial_filter}
                                                        setSerial_filter={setSerial_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    {props.t("Client")}
                                                    {cliName_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setCliName_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("clientName") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setCliName_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCliName_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        cliName_filter={cliName_filter}
                                                        setCliName_filter={setCliName_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>{props.t("First Pending Event")}</th>
                                            <th>{props.t("Pending Documents")}</th>
                                            <th>{props.t("Pending Incidents")}</th>
                                            <th>{props.t("Pending Events")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showAssets &&
                                            showAssets.map((item, i) => (
                                                <AllAssestCustom
                                                    data={item}
                                                    key={i}
                                                    selectedIds={selectedIds}
                                                    setSelectedIds={setSelectedIds}
                                                />
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(AssestCustom);
AssestCustom.propTypes = {
    t: PropTypes.any,
};

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "./single-list";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import InputFilter from "../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";

const AllProducts = (props) => {
    const [data, setData] = useState([]);
    const [originData, setOriginData] = useState([]);
    const [ref_filter, setRef_filter] = useState(false);
    const [proName_filter, setProName_filter] = useState(false);
    const [price_filter, setPrice_filter] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState(false);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [selectedIds, setSelectedIds] = useState([]);
    const [timer, setTimer] = useState(null);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        let savedSearch = localStorage.getItem('search');
        if (savedSearch && savedSearch.split(',')[0] === 'product') {
            setSearchValue(savedSearch.split(',')[1])
            getProducts(savedSearch.split(',')[1])
        } else {
            getProducts();
        }
    }, [])

    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/new-product");
        }
    };

    const getAllProducts = (e) => {
        setSearchValue(e);
        localStorage.setItem('search', `product, ${e}`)
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getProducts(e);
        }, 1000);
        setTimer(newTimer);
    };

    const history = useHistory();

    const getProducts = (e) => {
        setData([]);
        setOriginData([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setData(res.data.products);
                        setOriginData(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setData(res.data.products);
                        setOriginData(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    const getSearchedList = (e, type) => {
        setError("");
        // if (type == "categoryName") {
        //     localStorage.setItem("categoryName", e);
        //     if (e !== "") {
        //         let tempData = originData.filter(item => item.product_category_name && item.product_category_name.includes(e));
        //         setData(tempData);
        //     } else {
        //         setData(originData)
        //     }
        //     setCategoryFilter(false);
        //     return;
        // }

        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "product_name") {
            localStorage.setItem("productName", e);
        }
        if (type == "price") {
            localStorage.setItem("price", e);
        }
        if (type == "is_promotional") {
            localStorage.setItem("is_promotional", e ? "1" : "");
        }
        setData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products?&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&name=${localStorage.getItem("productName") !== null
                    ? localStorage.getItem("productName")
                    : ""
                }&price=${localStorage.getItem("price") !== null
                    ? localStorage.getItem("price")
                    : ""
                }&is_promotional=${localStorage.getItem("is_promotional") !== null
                    ? localStorage.getItem("is_promotional")
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setData(res.data.products);
                    setOriginData(res.data.products);
                    setRef_filter(false);
                    setProName_filter(false);
                    setPrice_filter(false);
                    setCategoryFilter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            // var elem = document.getElemmentsByName("chk")
            // for (var j = 0; j < elem.length; j++) {
            //     if (elem[j].type == "checkbox") {
            //         elem[j].checked = false;
            //         var array = selectedIds;
            //         var index = array.indexOf(parseInt(elem[j].value));
            //         if (index !== -1) {
            //             array.splice(index, 1);
            //         }
            //     }
            // }
            var elem = document.getElementsByName("chk")
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false
                    var array = selectedIds
                    var index = array.indexOf(parseInt(elem[j].value))
                    if (index !== -1) {
                        array.splice(index, 1)
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    getAllProducts();
                    setsuccess_dlg(true);
                    setdynamic_title("Deleted");
                    setdynamic_description(res.data.message);
                }
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };

    const getDuplicate = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 3000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products/duplicate-product`,
                {
                    id: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/product/${res.data.data.id}`);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setconfirm_alert(false);
                        deleteMultiple();
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    <span className="text-danger">
                        You won&apos;t be able to revert this!
                    </span>
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success={dynamic_title == "Deleted" ? true : false}
                    error={dynamic_title == "Failed" ? true : false}
                    title={props.t(dynamic_title)}
                    onConfirm={() => {
                        setsuccess_dlg(false);
                    }}
                >
                    {props.t(dynamic_description)}
                </SweetAlert>
            ) : null}
            <div className="page-content">
                <MetaTags>
                    <title>All Products | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="page-title">{props.t("All Products")}</h6>
                                </Col>
                                <Col md={8}>
                                    <div className="float-end  d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                value={searchValue}
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getAllProducts(e.target.value)}
                                            />
                                        </div>
                                        <div className="btn-group me-2">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className="btn btn-primary"
                                                // id="dropdown-basic"
                                                >
                                                    {props.t("More")}
                                                    {/* <i className="fa fa-caret-down ms-2"></i> */}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {getPermission().products.products.delete.is_checked ===
                                                        1 &&
                                                        <Dropdown.Item>
                                                            <li className="nav-item mb-2">
                                                                {" "}
                                                                <span
                                                                    //   className="dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setconfirm_alert(true);
                                                                    }}
                                                                >
                                                                    {props.t("Batch delete")}
                                                                </span>
                                                            </li>
                                                        </Dropdown.Item>
                                                    }
                                                    <Dropdown.Item>
                                                        <li className="nav-item mb-2">
                                                            {" "}
                                                            <span
                                                                // className="dropdown-item"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => getDuplicate()}
                                                            >
                                                                {props.t("Duplicate")}
                                                            </span>
                                                        </li>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <li className="nav-item mb-2">
                                                            <span
                                                                // className="dropdown-item"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => getExport()}
                                                            >
                                                                {props.t("Export")}
                                                            </span>
                                                        </li>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        {getPermission().products.products.create.is_checked ===
                                            1 ? (
                                            <>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => createNew()}
                                                >
                                                    {props.t("Create New")}
                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}{" "}
                                    </div>
                                </Col>
                                {/* <Col md={6}>
                  <div className="float-end d-none d-md-block">
                    {getPermission().products.products.create.is_checked ===
                    1 ? (
                      <Link to="/new-product" className="btn btn-primary">
                        Create New
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                        name="chk"
                                                    />
                                                </th>
                                                <th>#</th>
                                                <th>{props.t("Image")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Name")}
                                                        {proName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setProName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("productName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setProName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setProName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            proName_filter={proName_filter}
                                                            setProName_filter={setProName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Price")}
                                                        {price_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setPrice_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("price") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setPrice_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setPrice_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            price_filter={price_filter}
                                                            setPrice_filter={setPrice_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                {/* <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Category Name")}
                                                        {categoryFilter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCategoryFilter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("categoryName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCategoryFilter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCategoryFilter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            categoryFilter={categoryFilter}
                                                            setCategoryFilter={setCategoryFilter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th> */}
                                                <th>
                                                    {props.t("Promotional")}
                                                    <input className="ms-2" type="checkbox" name="is_promotional" onChange={(e) => getSearchedList(e.target.checked, "is_promotional")} />
                                                </th>
                                                <th>{props.t("Stock")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map((item, i) => (
                                                    <SingleList
                                                        data={item}
                                                        key={i}
                                                        i={i}
                                                        selectedIds={selectedIds}
                                                        setSelectedIds={setSelectedIds}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(AllProducts);
AllProducts.propTypes = {
    t: PropTypes.any,
};

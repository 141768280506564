import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function BarChart(props) {
  // let data = {
  //   labels: props.data.labels,
  //   datasets: props.data.data,
  // };


  const [data, setData] = useState({});

  useEffect(() => {
    if (!props.data || !Array.isArray(props.data.labels)) {
      console.error("Invalid data:", props.data);
      return;
    }

    if (typeof props.t !== "function") {
      console.error("props.t is not a function");
      return;
    }

    const newData = props.data.data.map((e) => {
      return {
        ...e,
        label: props.t(e.label),
      };
    });

    const newLables = props.data.labels.map((e) => {
      return props.t(e);
    });


    setData({
      labels: newLables,
      datasets: newData,
    });
  }, [props.data, props.t]);

  return <Bar data={data} />;
}

export default withTranslation()(BarChart);
BarChart.propTypes = {
  t: PropTypes.any,
};

import React, { useState } from "react";
import RateModal from "./priceRateModal";
import SweetAlert from "react-bootstrap-sweetalert";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";

function SingleList(data) {
  const [rate, setRate] = useState(data.data && data.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const deleteRate = () => {
    if (data.type == "service") {
      axios
        .delete(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/service-rates/${rate.id}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setRate(null);
          setdynamic_description(res.data.message);
        });
    } else {
      axios
        .delete(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/product-rates/${rate.id}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setRate(null);
          setdynamic_description(res.data.message);
        });
    }
  };
  const editRole = () => {
    setIsOpen(true);
    setIsOpenModal(true);
  };

  if (rate === null) {
    return "";
  } else
    return (
      <>
        {isOpen ? (
          <RateModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            rateId={rate.id}
            setRate={setRate}
            setIsOpen={setIsOpen}
            type={data.type}
          />
        ) : null}
        <tr>
          <td
            role="button"
            onClick={
              getPermission().price_rates.price_rates.edit.is_checked === 1
                ? () => editRole()
                : ""
            }
          >
            {data.productRates ? (
              data.productRates.map((NavItem, index) => {
                // Check if NavItem is defined and has the name property
                if (NavItem && NavItem.name) {
                  return <span key={index}>{NavItem.name}</span>;
                } else {
                  return null; // Or return a fallback value if you prefer
                }
              })
            ) : (
              <span>{rate.name}</span>
            )}
          </td>

          <td
            role="button"
            onClick={
              getPermission().price_rates.price_rates.edit.is_checked === 1
                ? () => editRole()
                : ""
            }
          >
            ${" "}
            {data.productRates ? (
              data.productRates.map((NavItem, index) => {
                if (NavItem && NavItem.sales_price) {
                  return <span key={index}>{NavItem.sales_price}</span>;
                }
                return null; // Handle undefined case
              })
            ) : (
              <span>{rate.sales_price}</span>
            )}
          </td>

          <td
            role="button"
            onClick={
              getPermission().price_rates.price_rates.edit.is_checked === 1
                ? () => editRole()
                : ""
            }
          >
            {data.productRates ? (
              data.productRates.map((NavItem, index) => {
                if (NavItem && NavItem.discount) {
                  return <span key={index}>{NavItem.discount}</span>;
                }
                return null;
              })
            ) : (
              <span>{rate.discount}</span>
            )}
          </td>

          {
            getPermission().other_config.show_margin.is_checked === 0 ? ("") : 
            (
              <>
                <td
                  role="button"
                  onClick={
                    getPermission().price_rates.price_rates.edit.is_checked === 1
                      ? () => editRole()
                      : ""
                  }
                >
                  {data.productRates ? (
                    data.productRates.map((NavItem, index) => {
                      if (NavItem && NavItem.purchase_margin) {
                        return <span key={index}>{NavItem.purchase_margin}</span>;
                      }
                      return null;
                    })
                  ) : (
                    <span>{rate.purchase_margin}</span>
                  )}
                </td>

                <td
                  role="button"
                  onClick={
                    getPermission().price_rates.price_rates.edit.is_checked === 1
                      ? () => editRole()
                      : ""
                  }
                >
                  {data.productRates ? (
                    data.productRates.map((NavItem, index) => {
                      if (NavItem && NavItem.sales_margin) {
                        return <span key={index}>{NavItem.sales_margin}</span>;
                      }
                      return null;
                    })
                  ) : (
                    <span>{rate.sales_margin}</span>
                  )}
                </td>
              </>
            )                                              
          }
        </tr>

        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteRate(rate.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </>
    );
}

export default SingleList;

/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const UnpaidRefund = (props) => {
  const [unpaidRef, setUnpaidRef] = useState(props.data);

  let selectedRoleId = [];
  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);
  return (
    <>
      <tr style={{ cursor: "pointer" }}>
        <td>
          <input
            onChange={(e) => getSelectedId(unpaidRef.id, e)}
            name="chk"
            value={unpaidRef.id}
            className="p-0 d-inline-block"
            type="checkbox"
          />
        </td>
        <td role="button" className="py-1">
          <Link
            to={`/invoicing-service/${unpaidRef.id}`}
            style={{ color: " inherit" }}
          >
            {unpaidRef.reference}
            {unpaidRef.reference_number}
          </Link>
        </td>
        <td role="button" className="py-1">
          <Link
            to={`/invoicing-service/${unpaidRef.id}`}
            style={{ color: " inherit" }}
          >
            {unpaidRef.created_by_name}
          </Link>
        </td>
        <td role="button" className="py-1">
          <Link
            to={`/invoicing-service/${unpaidRef.id}`}
            style={{ color: " inherit" }}
          >
            {unpaidRef.status == "unpaid"
              ? "Unpaid"
              : unpaidRef.status == "paid"
              ? "Paid"
              : unpaidRef.status}
          </Link>
        </td>
        <td role="button" className="py-1">
          <Link
            to={`/invoicing-service/${unpaidRef.id}`}
            style={{ color: " inherit" }}
          >
            {unpaidRef.date}
          </Link>
        </td>
        <td role="button" className="py-1">
          <Link
            to={`/invoicing-service/${unpaidRef.id}`}
            style={{ color: " inherit" }}
          >
            {unpaidRef.amount}
          </Link>
        </td>
        <td role="button" className="py-1">
          <Link
            to={`/invoicing-service/${unpaidRef.id}`}
            style={{ color: " inherit" }}
          >
            {unpaidRef.amount_due}
          </Link>
        </td>
      </tr>
    </>
  );
};
export default UnpaidRefund;

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, Alert, CardBody } from "reactstrap";
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../helpers/Utils";
const axios = require("axios").default;
import AllInvoices from "../../components/AllInvoices/all-invoices";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import SendEmail from "../sendEmail/index";
import MultipleChoice from "../../components/filters/multiChoice";
import DateFilter from "../../components/filters/dateFilter";
import InputFilter from "../../components/filters/searchFilter";
import AmountFilter from "../../components/filters/amountFilter";
import DownloadOption from "../../pages/Invoicing/download";
import fileSaver from "file-saver";
import PrintOption from "../MorePrintOption";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";
import SweetAlert from "react-bootstrap-sweetalert";
import Paginations from "../../components/pagination";

const InvoicingCustom = (props) => {
  const history = useHistory();
  const [sort, setSort] = useState(false);
  const [sort2, setSort2] = useState(false);
  const [sort3, setSort3] = useState(false);
  const [sort4, setSort4] = useState(false);
  const [sort5, setSort5] = useState(false);
  const [sort6, setSort6] = useState(false);
  const [sort7, setSort7] = useState(false);
  const [sort8, setSort8] = useState(false);
  const [sort9, setSort9] = useState(false);
  const [sort10, setSort10] = useState(false);
  const [ref_filter, setRef_filter] = useState(false);
  const [cliName_filter, setCliName_filter] = useState(false);
  const [title_filter, setTitle_filter] = useState(false);
  const [createdby_filter, setCreatedBy_filter] = useState(false);
  const [amount_filter, setAmount_filter] = useState(false);
  const [statusINV_filter, setstatusINV_filter] = useState(false);
  const [statusVeronica_filter, setstatusVeronica_filter] = useState(false);
  const [date_filter, setdate_filter] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [error, setError] = useState("");
  const [mainError, setMainError] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [selectedIds, setSelectedIds] = useState([]);
  const [timer, setTimer] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [link, setLink] = useState("");
  const [format, setFormat] = useState("");
  const [template1, setTemplate1] = useState("0");
  const [priceTax, setPriceTax] = useState("");
  const [mailingFormat, setMailingFormat] = useState("");
  const [productService, setProductService] = useState("");
  const [defaultConfig, setDefaultConfig] = useState("");
  const [success, setSuccess] = useState("");
  const [email, setEmail] = useState(getCurrentUser().email);
  const [email2, setEmail2] = useState("");
  const [selectedType, setSelectedType] = useState([]);
  const [selectedNum, setSelectedNum] = useState([]);
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [link1, setLink1] = useState("");
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [signedBox, setSignedBox] = useState("");
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
      1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
      null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [paginationData, setPaginationData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [mailPDFFormat, setMailPDFFormat] = useState("");

  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      history.push("/invoicing-service");
    }
  };

  useEffect(() => {
    let savedSearch = localStorage.getItem('search');
    if (savedSearch && savedSearch.split(',')[0] === 'invoice') {
      setSearchValue(savedSearch.split(',')[1].trim())
      getInvoiceData(savedSearch.split(',')[1].trim())
    } else {
      getInvoiceData()
    }
  }, [])

  const getSearchData = (e) => {
    setSearchValue(e);
    localStorage.setItem('search', `invoice, ${e}`)
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getInvoiceData(e);
    }, 1000);
    setTimer(newTimer);
  };

  const getInvoiceData = (e) => {
    setInvoiceData([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices?type=Normal Invoice&search=${e != undefined ? e : ""
        }&status=${localStorage.getItem("statusINV") !== null
          ? localStorage.getItem("statusINV")
          : ""
        }&veronica_status=${localStorage.getItem("statusVeronica") !== null
          ? localStorage.getItem("statusVeronica")
          : ""
        }&reference_number=${localStorage.getItem("reference") !== null
          ? localStorage.getItem("reference")
          : ""
        }&title=${localStorage.getItem("title") !== null
          ? localStorage.getItem("title")
          : ""
        }&client_name=${localStorage.getItem("clientName") !== null
          ? localStorage.getItem("clientName")
          : ""
        }&created_by_name=${localStorage.getItem("createdBy") !== null
          ? localStorage.getItem("createdBy")
          : ""
        }&amount=${localStorage.getItem("amount") !== null
          ? localStorage.getItem("amount")
          : ""
        }&date_start_date=${getFilterDate() && getFilterDate().from !== null
          ? getFilterDate().from
          : ""
        }&date_end_date=${getFilterDate() && getFilterDate().to !== null
          ? getFilterDate().to
          : ""
        }&sortBy=${localStorage.getItem("leadSort") !== null
          ? localStorage.getItem("leadSort")
          : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setMainError("");
          setInvoiceData(res.data.data.data);
          setPaginationData(res.data.data);
        } else {
          setMainError(res.data.message);
        }
      });
  };

  const paginateData = (link) => {
    setPaginationData("");
    setInvoiceData([]);
    setLoader(<Loader />);
    let url = `${link}&type=Normal Invoice&reference_number=${localStorage.getItem("reference") !== null
      ? localStorage.getItem("reference")
      : ""
      }&title=${localStorage.getItem("title") !== null
        ? localStorage.getItem("title")
        : ""
      }&status=${localStorage.getItem("statusINV") !== null
        ? localStorage.getItem("statusINV")
        : ""
      }&veronica_status=${localStorage.getItem("statusVeronica") !== null
        ? localStorage.getItem("statusVeronica")
        : ""
      }&client_name=${localStorage.getItem("clientName") !== null
        ? localStorage.getItem("clientName")
        : ""
      }&created_by_name=${localStorage.getItem("createdBy") !== null
        ? localStorage.getItem("createdBy")
        : ""
      }&amount=${localStorage.getItem("amount") !== null
        ? localStorage.getItem("amount")
        : ""
      }&date_start_date=${getFilterDate() && getFilterDate().from !== null
        ? getFilterDate().from
        : ""
      }&date_end_date=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
      }&sortBy=${localStorage.getItem("leadSort") !== null
        ? localStorage.getItem("leadSort")
        : ""
      }&search=${searchValue}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setMainError("");
          setInvoiceData(res.data.data.data);
          setPaginationData(res.data.data);
        } else {
          setMainError(res.data.message);
        }
      });
  };

  const getSearchedList = (e, type) => {
    setError("");
    if (type == "reference_number") {
      localStorage.setItem("reference", e);
    }
    if (type == "client_name") {
      localStorage.setItem("clientName", e);
    }
    if (type == "title") {
      localStorage.setItem("title", e);
    }
    if (type == "created_by_name") {
      localStorage.setItem("createdBy", e);
    }
    if (type == "statusINV") {
      localStorage.setItem("statusINV", e);
    }
    if (type == "statusVeronica") {
      localStorage.setItem("statusVeronica", e);
    }
    if (type == "date") {
      setFilterDate(e);
    }
    if (type == "amount") {
      localStorage.setItem("amount", e);
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e);
    }
    setInvoiceData([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices?type=Normal Invoice&status=${localStorage.getItem("statusINV") !== null
          ? localStorage.getItem("statusINV")
          : ""
        }&veronica_status=${localStorage.getItem("statusVeronica") !== null
          ? localStorage.getItem("statusVeronica")
          : ""
        }&reference_number=${localStorage.getItem("reference") !== null
          ? localStorage.getItem("reference")
          : ""
        }&title=${localStorage.getItem("title") !== null
          ? localStorage.getItem("title")
          : ""
        }&client_name=${localStorage.getItem("clientName") !== null
          ? localStorage.getItem("clientName")
          : ""
        }&created_by_name=${localStorage.getItem("createdBy") !== null
          ? localStorage.getItem("createdBy")
          : ""
        }&amount=${localStorage.getItem("amount") !== null
          ? localStorage.getItem("amount")
          : ""
        }&date_start_date=${getFilterDate() && getFilterDate().from !== null
          ? getFilterDate().from
          : ""
        }&date_end_date=${getFilterDate() && getFilterDate().to !== null
          ? getFilterDate().to
          : ""
        }&sortBy=${localStorage.getItem("leadSort") !== null
          ? localStorage.getItem("leadSort")
          : ""
        }&search=${searchValue}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          //   setLoader("");
          setInvoiceData(res.data.data.data);
          setPaginationData(res.data.data);
          setRef_filter(false);
          setCliName_filter(false);
          setTitle_filter(false);
          setdate_filter(false);
          setCreatedBy_filter(false);
          setAmount_filter(false);
          setstatusINV_filter(false);
          setstatusVeronica_filter(false);
        } else {
          setError(res.data.message);
          //   setlead_filter(false);
        }
      });
  };

  const authorizeVeronica = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/veronica/create-and-authorize-invoice/${id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess("Invoice Authorized Successfully!");
          getInvoiceData(searchValue);
        } else {
          if (res.data.data !== undefined) {
            setError(res.data.data?.detalleError);
          } else {
            setError(res.data.message);
          }
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 5000);
      });
  };

  const cancelVeronica = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/veronica/cancel-invoice/${id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess("Invoice Canceled Successfully!");
          getInvoiceData(searchValue);
        } else {
          if (res.data.data !== undefined) {
            setError(res.data.data?.detalleError);
          } else {
            setError(res.data.message);
          }
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 5000);
      });
  };

  const notifyVeronica = (id, a) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/veronica/invoice-notify/${id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess("Notified Successfully!");
          getInvoiceData();
        } else {
          if (res.data.result !== undefined) {
            setError(res.data.result.messages[0]);
          } else {
            setError(res.data.message);
          }
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 5000);
      });
  };

  const downloadVeronica = (id, type, redirect) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/veronica/generate-invoice-pdf/${id}?type=${type}&logo=1`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
          // responseType: "blob",
        }
      )
      .then(function (res) {
        if (res.data.status) {
          var url = res.data.data?.urlReporte;
          window.open(url, "_blank");
          //   var blob = new Blob([res.data], {
          //     type: "application/pdf",
          //   });
          //   if (redirect) {
          //     var url = window.URL.createObjectURL(blob);
          //     window.open(url, "_blank");
          //   } else {
          //     fileSaver.saveAs(blob, `INVOICE-${id}.${type}`);
          //     setSuccess("Downloaded Successfully!");
          //   }
          //   setError("");
        } else {
          if (res.data.result !== undefined) {
            setError(res.data.result.messages[0]);
          } else {
            setError(res.data.message);
          }
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 5000);
      });
  };

  const selectAllCheck = (check) => {
    if (check.checked) {
      var ele = document.getElementsByName("chk");
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true;
          selectedIds.push(parseInt(ele[i].value));
        }
      }
    } else {
      var elem = document.getElementsByName("chk");
      for (var j = 0; j < elem.length; j++) {
        if (elem[j].type == "checkbox") {
          elem[j].checked = false;
          var array = selectedIds;
          var index = array.indexOf(parseInt(elem[j].value));
          if (index !== -1) {
            array.splice(index, 1);
          }
        }
      }
    }
  };

  const getDuplicate = () => {
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }

    let ids = selectedIds[0];
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices/duplicate_invoice`,
        {
          id: ids,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res) {
          history.push(`/invoicing-service/${res.data.data.id}`);
        } else {
          setError(res.data.message);
        }
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  const getExport = () => {
    let ids = selectedIds?.join(",");
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/clients/export-receipt`,
        {
          type: "Normal Invoice",
          status:
            localStorage.getItem("statusINV") !== null
              ? localStorage.getItem("statusINV")
              : "",
          veronica_status:
            localStorage.getItem("statusVeronica") !== null
              ? localStorage.getItem("statusVeronica")
              : "",
          reference_number:
            localStorage.getItem("reference") !== null
              ? localStorage.getItem("reference")
              : "",
          title:
            localStorage.getItem("title") !== null
              ? localStorage.getItem("title")
              : "",
          client_name:
            localStorage.getItem("clientName") !== null
              ? localStorage.getItem("clientName")
              : "",
          created_by_name:
            localStorage.getItem("createdBy") !== null
              ? localStorage.getItem("createdBy")
              : "",
          amount:
            localStorage.getItem("amount") !== null
              ? localStorage.getItem("amount")
              : "",
          date_start_date:
            getFilterDate() && getFilterDate().from !== null
              ? getFilterDate().from
              : "",
          date_end_date:
            getFilterDate() && getFilterDate().to !== null
              ? getFilterDate().to
              : "",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setSelectedIds([]);
          window.location.href = res.data.url;
        }
      });
  };

  const getSendEmail = () => {
    setLink("");
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    let ids = selectedIds[0];
    let type = selectedType[0];
    setIsOpenModal(true);
    const formData = new FormData();
    formData.append("format", mailPDFFormat);
    formData.append("template_id", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    // formData.append(
    //     "prices_tax",
    //     priceTax == true || priceTax !== "0" ? "1" : "0"
    //   );
    formData.append("id", ids);
    formData.append("type", type);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            // 'content-type': 'application/json',
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLink(res.data.url);
          setEmail([res.data?.client_email]);
        }
      });
  };

  const getSendEmail2 = () => {
    // setLink("");
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    let ids = selectedIds[0];
    let type = selectedType[0];
    const formData = new FormData();
    formData.append("send_to", email);
    formData.append("cc", email2);
    formData.append("body", messageData);
    formData.append("format", format);
    formData.append("template1", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    formData.append("id", ids);
    formData.append("type", type);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLink(res.data.url);
          setSuccess(res.data.message);
          setError("");
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
          setIsOpenModal(false);
        }, 3000);
      });
  };

  const getGenerate = (type) => {
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    let ids = selectedIds[0];
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/generates`,
        {
          id: ids,
          from_type: "Normal Invoice",
          to_type: type,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          {
            history.push(
              `/invoicing-service/${res.data.data.id}generate=${res.data.data.generated_from}`
            );
          }
        } else {
          setError(res.data.message);
        }
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  const getDefaultPdf = () => {
    // setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/get-default-pdf?type=Normal Invoice`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setFormat(res.data.data.format);
          setMailingFormat(res.data.data.mailing_format);
          setPriceTax(res.data.data.price_after_tax);
          setProductService(res.data.data.include_main_image);
          getTemplate(res.data.data.type);
        } else {
          //   setError(res.data.message);
        }
      });
  };

  const deleteMultiple = () => {
    let filteredIds = selectedIds.filter(id => {
      let invoice = invoiceData.find(item => item.id == id);
      return invoice && invoice.veronica_status !== "cancelled" && invoice.veronica_status !== "authorized";
    });

    let ids = filteredIds.join(",");
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices/batch-delete`,
        {
          ids: ids,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          getInvoiceData(searchValue);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 5000);
      });
  };

  useEffect(() => {
    // getInvoiceData();
    getDefaultPdf();
    getMessage();
  }, []);

  const getprint = () => {
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    let ids = selectedIds[0];
    let type = selectedType[0];
    const formData = new FormData();
    formData.append("format", format);
    formData.append("template1", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    formData.append("id", ids);
    formData.append("type", type);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          openInNewTab(res.data.url);
        }
      });
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getTemplate = (type) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/my_templates?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setTemplateData(res.data.templates);
        } else {
          setError(res.data.message);
        }
      });
  };

  const getDownloadPDF = () => {
    setLink1("");
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    let ids = selectedIds[0];
    let type = selectedType[0];
    const formData = new FormData();
    formData.append("format", format);
    formData.append("template1", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    formData.append("id", ids);
    formData.append("type", type);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLink1(res.data.url);
          setIsDownloadModal(true);
        }
      });
  };

  const getDownloadPDF2 = () => {
    setLink1("");
    let ids = selectedIds[0];
    let type = selectedType[0];
    const formData = new FormData();
    formData.append("format", format);
    formData.append("template1", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    formData.append("id", ids);
    formData.append("type", type);
    formData.append("download", "1");

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
          responseType: "arraybuffer",
        }
      )
      .then(function (res) {
        if (res.data) {
          var blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fileSaver.saveAs(blob, `INVOICE${selectedNum[0]}.pdf`);
        }
        setIsDownloadModal(false);
      });
  };

  const getPrintOption = () => {
    setLink1("");
    if (selectedIds.length > 1) {
      setError("This action can only be carried out on a single document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    if (selectedIds.length < 1) {
      setError("Please select document");
      setTimeout(() => {
        setError("");
      }, 4000);
      return false;
    }
    let ids = selectedIds[0];
    let type = selectedType[0];
    const formData = new FormData();
    formData.append("format", format);
    formData.append("template1", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    formData.append("id", ids);
    formData.append("type", type);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLink1(res.data.url);
          setIsPrintModal(true);
        }
      });
  };

  const getPrintOption2 = () => {
    setLink1("");
    let ids = selectedIds[0];
    let type = selectedType[0];
    const formData = new FormData();
    formData.append("format", format);
    formData.append("template1", template1);
    formData.append("prices_tax", priceTax ? "1" : "0");
    formData.append("mailing_format", mailingFormat ? "1" : "0");
    formData.append("product_service", productService ? "1" : "0");
    formData.append("default_config", defaultConfig ? "1" : "0");
    formData.append("disable_signed", signedBox ? "1" : "0");
    formData.append("id", ids);
    formData.append("type", type);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          openInNewTab(res.data.url);
        }
      });
  };

  const getMessage = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/get-message`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          const originalString =
            res.data.settings.email_configuration_client_emails_message;
          setMessageData(originalString.replace(/\n/g, "<br />"));
        } else {
          setError(res.data.message);
        }
      });
  };

  const [isGenerateSubmenuToggle, setGenerateSubmenuToggle] = useState(false);
  const toggleGenerateSubMenu = (e) => {
    e.stopPropagation();
    setGenerateSubmenuToggle(!isGenerateSubmenuToggle);
  };

  return (
    <React.Fragment>
      <CreateTrialModal
        isTrialModal={isTrialModal}
        setIsTrialModal={setIsTrialModal}
        nonActive={nonActive}
      />
      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            deleteMultiple();
            setdynamic_title("Deleted");
            setdynamic_description("Your file has been deleted.");
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">
            {props.t("You want to deleted selected items?")}
          </span>
        </SweetAlert>
      ) : null}
      {isDownloadModal ? (
        <DownloadOption
          isDownloadModal={isDownloadModal}
          setIsDownloadModal={setIsDownloadModal}
          link={link1}
          setLink={setLink1}
          type={selectedType[0]}
          //   type={saleData.reference_type}
          id={selectedIds[0]}
          refNum={selectedNum[0]}
          setFormat={setFormat}
          setTemplate1={setTemplate1}
          setPriceTax={setPriceTax}
          setSignedBox={setSignedBox}
          setMailingFormat={setMailingFormat}
          setProductService={setProductService}
          setDefaultConfig={setDefaultConfig}
          getDownloadPDF={getDownloadPDF}
          getDownloadPDF2={getDownloadPDF2}
          templateData={templateData}
        />
      ) : (
        ""
      )}
      {isOpenModal ? (
        <SendEmail
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          link={link}
          // setLink={setLink}
          //   id={ids}
          setEmail2={setEmail2}
          setEmail={setEmail}
          email={email}
          email2={email2}
          setFormat={setMailPDFFormat}
          setTemplate1={setTemplate1}
          setPriceTax={setPriceTax}
          setMailingFormat={setMailingFormat}
          setProductService={setProductService}
          setDefaultConfig={setDefaultConfig}
          getSendEmail={getSendEmail}
          getSendEmail2={getSendEmail2}
          success={success}
          error={error}
          type={selectedType[0]}
          messageData={messageData}
          setMessageData={setMessageData}
          format={mailPDFFormat}
          mailingFormat={mailingFormat}
          priceTax={priceTax}
          productService={productService}
          setSignedBox={setSignedBox}
          id={selectedIds[0]}
        />
      ) : (
        ""
      )}
      {
        format != '' &&
        <PrintOption
          isPrintModal={isPrintModal}
          setIsPrintModal={setIsPrintModal}
          link={link1}
          setLink={setLink1}
          type={selectedType[0]}
          //   type={saleData.reference_type}
          id={selectedIds[0]}
          refNum={selectedNum[0]}
          pdfType={'Normal Invoice'}
          format={format}
          template1={template1}
          setFormat={setFormat}
          setTemplate1={setTemplate1}
          setPriceTax={setPriceTax}
          setMailingFormat={setMailingFormat}
          setProductService={setProductService}
          setDefaultConfig={setDefaultConfig}
          getPrintOption={getPrintOption}
          getPrintOption2={getPrintOption2}
          templateData={templateData}
          setSignedBox={setSignedBox}
        />
      }
      <div className="page-content">
        <MetaTags>
          <title>Invoices | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="company_list">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={6}>
                  <h6 className="page-title">{props.t("Invoices")}</h6>
                </Col>
                <Col md={6}>
                  <div className="float-end d-flex">
                    <div className="me-2">
                      <input
                        className="form-control"
                        type="search"
                        value={searchValue}
                        placeholder={props.t("Search here")}
                        onChange={(e) => getSearchData(e.target.value)}
                      />
                    </div>
                    {getPermission().invoices.invoices.create.is_checked ===
                      1 ? (
                      <>
                        <div className="btn-group me-2">
                          <MDBContainer>
                            <MDBDropdown>
                              <MDBDropdownToggle className="btn btn-primary">
                                {props.t("More")}
                              </MDBDropdownToggle>
                              <MDBDropdownMenu>
                                {getPermission().invoices.invoices.delete
                                  .is_checked === 1 ? (
                                  <MDBDropdownItem>
                                    <span
                                      className="nav-item dropdown-item"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setconfirm_alert(true);
                                      }}
                                    >
                                      {props.t("Batch delete")}
                                    </span>
                                  </MDBDropdownItem>
                                ) : (
                                  <MDBDropdownItem>
                                    <span className="nav-item dropdown-item disabled">
                                      {props.t("Batch delete")}
                                    </span>
                                  </MDBDropdownItem>
                                )}
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getDownloadPDF()}
                                  >
                                    {props.t("Download as pdf")}
                                  </span>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  {
                                    format &&
                                    <span
                                      className="nav-item dropdown-item"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => getPrintOption()}
                                    >
                                      {props.t("Print")}
                                    </span>
                                  }
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getDuplicate()}
                                  >
                                    {props.t("Duplicate")}
                                  </span>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getExport()}
                                  >
                                    {props.t("Export")}
                                  </span>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getSendEmail()}
                                  >
                                    {props.t("Send")}
                                  </span>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={toggleGenerateSubMenu}
                                    onMouseOver={toggleGenerateSubMenu}
                                  >
                                    {props.t("Generate")}
                                  </span>
                                  {isGenerateSubmenuToggle && (
                                    <ul className="dropdown-menu dropdown-submenu">
                                      <MDBDropdownItem>
                                        <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            getGenerate("Refund Invoice")
                                          }
                                        >
                                          {props.t("Refund Invoice")}
                                        </span>
                                      </MDBDropdownItem>
                                    </ul>
                                  )}
                                </MDBDropdownItem>
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          </MDBContainer>
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={() => createNew()}
                        >
                          {props.t("Create New")}
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <Card>
              <CardBody>
                {mainError ? (
                  <Alert color="danger">{props.t(mainError)}</Alert>
                ) : (
                  ""
                )}
                {success ? (
                  <Alert color="success">{props.t(success)}</Alert>
                ) : null}
                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                <div className="table-responsive">
                  <Table striped className="align-middle">
                    <thead>
                      <tr>
                        <th>
                          <input
                            onClick={(e) => selectAllCheck(e.target)}
                            className="p-0 d-inline-block"
                            type="checkbox"
                          />
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Reference")}
                            {ref_filter ? (
                              <>
                                <span
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setRef_filter(false)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("reference") == "" ? (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setRef_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() => setRef_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <InputFilter
                              ref_filter={ref_filter}
                              setRef_filter={setRef_filter}
                              getSearchedList={getSearchedList}
                            />
                            {sort ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort2(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "reference_number,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "619%"
                                        : "806%"
                                        }, 1px)`,
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort2(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "reference_number,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "693%"
                                        : "879%"
                                        }, 0px)`,
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort(true),
                                      setSort2(false),
                                      setSort3(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "reference_number,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "619%"
                                        : "806%"
                                        }, 1px)`,
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort2(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "reference_number,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "693%"
                                        : "879%"
                                        }, 0px)`,
                                    }}
                                  ></i>
                                </span>
                              </>
                            )}
                            {sort2 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort2(false),
                                      setSort(true),
                                      setSort3(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "reference_number,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "693%"
                                        : "879%"
                                        }, 0px)`,
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Client")}
                            {cliName_filter ? (
                              <>
                                <span
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setCliName_filter(false)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("clientName") == "" ? (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setCliName_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() => setCliName_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <InputFilter
                              cliName_filter={cliName_filter}
                              setCliName_filter={setCliName_filter}
                              getSearchedList={getSearchedList}
                            />
                            {sort5 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort6(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort4(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "client_name,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "611%"
                                        : "535%"
                                        }, 1px)`,
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort6(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort4(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "client_name,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "685%"
                                        : "607%"
                                        }, 0px)`,
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort5(true),
                                      setSort2(false),
                                      setSort(false),
                                      setSort4(false),
                                      setSort3(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "client_name,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "611%"
                                        : "535%"
                                        }, 1px)`,
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort6(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort4(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "client_name,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "685%"
                                        : "607%"
                                        }, 0px)`,
                                    }}
                                  ></i>
                                </span>
                              </>
                            )}
                            {sort6 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort2(false),
                                      setSort5(true),
                                      setSort(false),
                                      setSort4(false),
                                      setSort3(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "client_name,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: `translate(${localStorage.getItem("i18nextLng") ===
                                        "sp"
                                        ? "685%"
                                        : "607%"
                                        }, 0px)`,
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("E-billing status")}
                            {statusVeronica_filter ? (
                              <>
                                <span
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() =>
                                    setstatusVeronica_filter(false)
                                  }
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("statusVeronica") ==
                                  "" ? (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setstatusVeronica_filter(true)
                                    }
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() =>
                                      setstatusVeronica_filter(true)
                                    }
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <MultipleChoice
                              statusVeronica_filter={statusVeronica_filter}
                              setstatusVeronica_filter={
                                setstatusVeronica_filter
                              }
                              getSearchedList={getSearchedList}
                            />
                          </div>
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Created by")}
                            {createdby_filter ? (
                              <>
                                <span
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setCreatedBy_filter(false)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("createdBy") == "" ? (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setCreatedBy_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() => setCreatedBy_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <InputFilter
                              createdby_filter={createdby_filter}
                              setCreatedBy_filter={setCreatedBy_filter}
                              getSearchedList={getSearchedList}
                            />
                            {sort3 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort4(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "created_by_name,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: "translate(840%, 1px)",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort4(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "created_by_name,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(914%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort3(true),
                                      setSort2(false),
                                      setSort(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "created_by_name,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: "translate(840%, 1px)",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort4(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "created_by_name,ASC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(914%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            )}
                            {sort4 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort2(false),
                                      setSort3(true),
                                      setSort(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList(
                                        "created_by_name,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(914%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Status")}
                            {statusINV_filter ? (
                              <>
                                <span
                                  className=""
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setstatusINV_filter(false)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("statusINV") == "" ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setstatusINV_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() => setstatusINV_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <MultipleChoice
                              statusINV_filter={statusINV_filter}
                              setstatusINV_filter={setstatusINV_filter}
                              getSearchedList={getSearchedList}
                            />
                          </div>
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Date")}
                            {date_filter ? (
                              <>
                                <span
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setdate_filter(false)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {getFilterDate() &&
                                  getFilterDate().from == "" ? (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setdate_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() => setdate_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <DateFilter
                              date_filter={date_filter}
                              setdate_filter={setdate_filter}
                              getSearchedList={getSearchedList}
                            />
                            {sort9 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort10(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort4(false),
                                      getSearchedList("date,ASC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: "translate(475%, 1px)",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort10(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort4(false),
                                      getSearchedList("date,ASC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(548%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort9(true),
                                      setSort2(false),
                                      setSort(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort3(false),
                                      setSort10(false),
                                      getSearchedList("date,DESC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: "translate(475%, 1px)",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort10(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort9(false),
                                      setSort4(false),
                                      getSearchedList("date,ASC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(548%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            )}
                            {sort10 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort2(false),
                                      setSort9(true),
                                      setSort(false),
                                      setSort4(false),
                                      setSort5(false),
                                      setSort6(false),
                                      setSort7(false),
                                      setSort8(false),
                                      setSort3(false),
                                      setSort10(false),
                                      getSearchedList("date,DESC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(548%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Amount")}
                            {amount_filter ? (
                              <>
                                <span
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => setAmount_filter(false)}
                                >
                                  <i className="fa fa-filter ms-2"></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("amount") == "" ? (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setAmount_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      color: "orange",
                                    }}
                                    onClick={() => setAmount_filter(true)}
                                  >
                                    <i className="fa fa-filter ms-2"></i>
                                  </span>
                                )}
                              </>
                            )}
                            <AmountFilter
                              amount_filter={amount_filter}
                              setAmount_filter={setAmount_filter}
                              getSearchedList={getSearchedList}
                            />
                          </div>
                        </th>
                        <th>{props.t("Activity")}</th>
                        <th>{props.t("Margin")}</th>
                        <th>
                          <div
                            className="position-relative"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.t("Unpaid")}
                            {sort7 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort8(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort4(false),
                                      setSort7(false),
                                      setSort6(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList("amount_due,ASC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: "translate(396%, 5px)",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort8(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort4(false),
                                      setSort7(false),
                                      setSort6(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList("amount_due,ASC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(470%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort7(true),
                                      setSort2(false),
                                      setSort(false),
                                      setSort4(false),
                                      setSort3(false),
                                      setSort6(false),
                                      setSort9(false),
                                      setSort10(false),
                                      setSort5(false),
                                      setSort8(false),
                                      getSearchedList(
                                        "amount_due,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-up ms-2"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      transform: "translate(396%, 5px)",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSort8(true),
                                      setSort(false),
                                      setSort3(false),
                                      setSort2(false),
                                      setSort5(false),
                                      setSort4(false),
                                      setSort7(false),
                                      setSort6(false),
                                      setSort9(false),
                                      setSort10(false),
                                      getSearchedList("amount_due,ASC", "sort");
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(470%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            )}
                            {sort8 ? (
                              <>
                                <span
                                  className="fs-5"
                                  style={{ cursor: "pointer", color: "orange" }}
                                  onClick={() => {
                                    setSort2(false),
                                      setSort7(true),
                                      setSort(false),
                                      setSort4(false),
                                      setSort3(false),
                                      setSort6(false),
                                      setSort9(false),
                                      setSort10(false),
                                      setSort5(false),
                                      setSort8(false),
                                      getSearchedList(
                                        "amount_due,DESC",
                                        "sort"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-sort-down"
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                      transform: "translate(470%, 0px)",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData &&
                        invoiceData.map((item, i) => (
                          <AllInvoices
                            key={i}
                            data={item}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            setSelectedType={setSelectedType}
                            selectedType={selectedType}
                            setSelectedNum={setSelectedNum}
                            selectedNum={selectedNum}
                            authorizeVeronica={authorizeVeronica}
                            cancelVeronica={cancelVeronica}
                            setError={setError}
                            setSuccess={setSuccess}
                            downloadVeronica={downloadVeronica}
                            getInvoiceData={getInvoiceData}
                          />
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            {paginationData ? (
              <Paginations data={paginationData} paginate={paginateData} />
            ) : (
              ""
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(InvoicingCustom);
InvoicingCustom.propTypes = {
  t: PropTypes.any,
};

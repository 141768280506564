import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Modal,
  Input,
} from "reactstrap";
// import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import CategoryModal from "./ticketExpensesModel";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
const axios = require("axios").default;

import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../../assets/images/def-img.svg";
import Loader from "../../../components/Loader";
import ClientAddress from "../../../components/ClientAddresses";
import ProductTechEstimates from "../../../components/Product/estimates";
import ClientWorkAddress from "../../../components/ClientAddresses/workAddress";
import NewPayment from "../../../components/PaymentOption/payment_option";
import ClientList from "../../../components/supplierList";
import AssetsNew from "../../../components/Assests/assests-new";
import { Link } from "react-router-dom";
import DeliveryOptionModal from "../../../components/DeliveryOption/modal";
import AssignUserList from "../../../components/AssignUserList";
import SupplierCustomDropdown from "../../../components/SupplierDropdown";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBContainer,
} from "mdb-react-ui-kit";

let totalAmount;
let subTotal;
let subTotalShow;
let dValue;
const EditTicketsAndExpenses = ({
  match: {
    params: { id },
  },
}) => {
  const [ptId] = useState(parseInt(id, 10));
  // const EditTicketsAndExpenses = () => {
  const [data, setData] = useState([]);
  const [bank, setBank] = useState(false);
  const [newDisable, setNewDisable] = useState(false);
  const [clients, setClients] = useState("");
  const [workData, setWorkData] = useState("");
  const [clientNameData, setClientNameData] = useState("");
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [isWorkAddress, setIsWorkAddress] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [clientData, setClientData] = useState("");
  const [assetData, setAssetData] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [isOpenModal, setIsOpenModal] = useState(false);
  //   const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [image, setImage] = useState("");
  const [selectedCate, setSelectedCate] = useState("");
  const [assetId, setAssetId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [referenceType, setReferenceType] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [paymentData, setPaymentData] = useState("");
  const [isOpenModalNew, setIsOpenModalNew] = useState(false);
  const [assetModal, setassetModal] = useState(false);
  const [selectClientModal, setSelectClientModal] = useState(false);
  const [selectClientModal2, setSelectClientModal2] = useState(false);
  const [selectClientId, setSelectClientId] = useState("");
  const [selectClientId2, setSelectClientId2] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [selectedCates, setSelectedCates] = useState("");
  //   const [dueDate, setDueDate] = useState("");
  const [deliveryOption, setDeliveryOption] = useState("");
  const [deliveryOptionModal, setDeliveryOptionModal] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("");
  const [client2, setClient2] = useState("");
  const [assignedModal, setAssignedModal] = useState(false);
  const [assignedModal2, setAssignedModal2] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [assignedUserName, setAssignedUserName] = useState();
  const [assignedUserId, setAssignedUserId] = useState("");
  //   const [assignedUserId, setAssignedUserId] = useState("");
  const [paidUserName, setPaidUserName] = useState("");
  const [paidUserId, setPaidUserId] = useState("");
  const [assignUsers, setAssignUsers] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [purchaseTicket, setPurchaseTicket] = useState("");
  const [paidStatus, setPaidStatus] = useState("0");
  const [clientLoader, setClientLoader] = useState(false);
  const [mainFormDisabled, setMainFormDisabled] = useState(false);
  const [timer, setTimer] = useState(null);
  const [allRef, setAllRef] = useState([]);
  const [defaultRef, setDefaultRef] = useState("");
  const [refNum, setRefNum] = useState("");
  //   const [fields, setFields] = useState({
  //     formValues: [
  //       {
  //         reference: "",
  //         reference_id: "",
  //         name: "",
  //         description: "",
  //         base_price: "",
  //         quantity: "1",
  //         discount: "",
  //         amount: "",
  //         vat: "20",
  //         tax: "25",
  //         subtotal: "",
  //       },
  //     ],
  //   });
  const getSuppliers = (e) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getClientData(e);
    }, 1000);
    setTimer(newTimer);
  };
  const productCreateSubmit = (e, value) => {
    setLoading(true);
    let setPaid = "1";
    if (value.set_as_paid !== "1") {
      setPaid = "0";
    } else {
      setPaid = "1";
    }
    const formData = new FormData();
    formData.append("reference", value.reference);
    formData.append("reference_number", e.target.reference_number.value);
    formData.append("supplier_id", selectClientId);
    formData.append("status", value.status !== null ? value.status : "");
    formData.append("amount", value.amount);
    formData.append("payment_option", selectedCates);
    formData.append("date", value.date);
    formData.append("payment_date", value.payment_date);
    formData.append(
      "description",
      value.description !== null ? value.description : ""
    );
    formData.append("set_as_paid", setPaid);
    formData.append("comments", value.comments);
    formData.append("private_comments", e.target.private_comments.value);
    formData.append("asset_id", assetId);
    formData.append("employee", assignedUserId !== null ? assignedUserId : "");
    formData.append("paid_by", paidUserId);
    formData.append("category_id", value.category);
    formData.append("bank_account", value.bank_account);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/purchase-tickets/${ptId}?_method=PUT`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };
  const getSinglePurchaseTicket = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/purchase-tickets/${ptId}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res) {
          setPurchaseTicket(res.data.data);
          setRefNum(res.data.data.reference_number);
          setSelectClientId(res.data.data.supplier_id);
          setClientNameData(res.data.data.supplier_name);
          setAssignedUserId(res.data.data.employee);
          setPaidUserId(res.data.data.paid_by);
          setPaidUserName(res.data.data.paid_by_name);
          setAssignedUserName(res.data.data.employee_name);
          setSelectedCates(res.data.data.payment_option);
          setSelectedCate(res.data.data.category_id);
        }
      });
  };
  useEffect(() => {
    getSinglePurchaseTicket();
  }, []);
  // const getAssignUser = () => {
  //     axios
  //         .get(
  //             `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //                 "companyId"
  //             )}/users`,
  //             {
  //                 headers: {
  //                     Authorization: `Bearer ${getCurrentUser().token}`,
  //                 },
  //             }
  //         )
  //         .then(function (res) {
  //             if (res.data.status) {
  //                 setAssignUsers(res.data.users);
  //             }
  //         });
  // };
  const [fieldSection, setFieldSection] = useState({
    formValues: [{ message: "", cast: "" }],
  });
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  function handleSelectDeliveryOption(e) {
    setSelectedDeliveryOption(e.value);
  }

  let selectDeliveryOption = [];
  if (deliveryOption !== undefined) {
    {
      deliveryOption &&
        deliveryOption.map((item) => {
          selectDeliveryOption.push({
            value: item.id,
            label: item.name,
          });
        });
    }
  }
  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Expense`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
          res.data.reference?.map((item) => {
            if (item.by_default === "1") {
              setDefaultRef(item.prefix);
            }
          });
        } else {
          //   setError(res.data.message);
        }
      });
  };
  const NewpaymentShow = () => {
    setPaymentLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPaymentData(res.data.PaymentOptions);
        setPaymentLoader(false);
      });
  };

  const getAllBanks = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/bank_accounts`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setAllBanks(res.data.bank_accounts);
          setError("");
        } else {
          setError(res.data.message);
        }
      });
  };

  const getClientData = (e) => {
    setClientLoader(true);
    setAllClients([]);
    let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
      "companyId"
    )}/suppliers`;
    if (e !== undefined) {
      url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
        "companyId"
      )}/suppliers?search=${e}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setAllClients(res.data.suppliers);
        } else {
          setAllClients([]);
          setError(res.data.message);
        }
        setClientLoader(false);
        setTimeout(() => {
          setError("");
          //   setSuccess("");
        }, 2000);
      });
  };

  useEffect(() => {
    getAllBanks();
    getClientData();
    getReferences();
  }, []);

  const getAssignedUser = (id, name) => {
    setAssignedUserId(id);
    setAssignedUserName(name);
  };
  const getPaidUser = (id, name) => {
    setPaidUserId(id);
    setPaidUserName(name);
  };

  const getAssignUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/users`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAssignUsers(res.data.users);
        }
      });
  };

  useEffect(() => {
    NewpaymentShow();
    getAssignUser();
    // getDeliveryOption();
  }, []);

  let optionClients = [];
  if (allClients !== undefined) {
    {
      allClients &&
        allClients.map((client) => {
          optionClients.push({
            value: client.id,
            label: client.name,
          });
        });
    }
  }

  let optionPayment = [];
  if (paymentData !== undefined) {
    {
      paymentData &&
        paymentData.map((payment) => {
          optionPayment.push({
            value: payment.id,
            label: payment.name,
          });
        });
    }
  }

  function handleSelectCategoryAll(selectedcate) {
    setSelectedCates(selectedcate.value);
    if (selectedcate.value === 1) {
      setBank(true);
    } else {
      setBank(false);
    }
  }

  function handleAssets(selectedcate) {
    setAssetId(selectedcate.value);
  }

  //   const addFormFields = () => {
  //     setFields({
  //       formValues: [
  //         ...fields.formValues,
  //         {
  //           reference: "",
  //           reference_id: "",
  //           name: "",
  //           description: "",
  //           base_price: "",
  //           quantity: "1",
  //           discount: "",
  //           amount: "",
  //           vat: "20",
  //           tax: "25",
  //           subtotal: "",
  //         },
  //       ],
  //     });
  //   };

  //   totalAmount = fields.formValues.reduce(
  //     (totalAmount, currentValue) =>
  //       (totalAmount = totalAmount + currentValue.amount),
  //     0
  //   );

  //   subTotal = fields.formValues.reduce(
  //     (subTotal, currentValue) => (subTotal = subTotal + currentValue.subtotal),
  //     0
  //   );

  const metaDiscount = (e) => {
    if (e == undefined) {
      subTotal = fields.formValues.reduce(
        (subTotal, currentValue) =>
          (subTotal = subTotal + currentValue.subtotal),
        0
      );
      subTotalShow = subTotal - (subTotal * discountValue) / 100;
      dValue = subTotal - subTotalShow;
    } else {
      setDiscountValue(e);
      subTotalShow = subTotal - (subTotal * e) / 100;
      dValue = subTotal - subTotalShow;
    }
  };

  const setComImg = (e) => {
    setImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/products`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setError("");
          setData(res.data.products);
        } else {
          setError(res.data.message);
        }
      });
  };

  const clientDetail = (e) => {
    setSelectClientId(e.id);
    setClientNameData(e.legal_name);
    setClientData(e.address);
    setClients(e);
    getSingleClientsAddress(e.id);
    AssetsData(e.id);
    setNewDisable(true);
    // setBank(true);
  };

  const clientDetail2 = (e) => {
    setSelectClientId2(e);
    let index = allClients.findIndex((x) => x.id == e);
    if (index != -1) {
      setClient2(allClients[index].name);
    }
  };

  const getSingleClientsAddress = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-addresses?client_id=${e}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setAllAddress(res.data.client_addresses)
      });
  };

  const productCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/expense_categories`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllCategories(res.data.expense_categories);
      });
  };

  const AssetsData = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-assets?client_id=${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAssetData(res.data.client_assets);
      });
  };

  let optionAssets = [];
  if (assetData !== undefined) {
    {
      assetData &&
        assetData.map((asset) => {
          optionAssets.push({
            value: asset.id,
            label: asset.name,
          });
        });
    }
  }

  let optionCategory = [];
  if (allCategories !== undefined) {
    {
      allCategories &&
        allCategories.map((category) => {
          optionCategory.push({
            value: category.id,
            label: category.name,
          });
        });
    }
  }

  function handleSelectCategory(selectedcate) {
    setSelectedCate(selectedcate.value);
  }

  const getType = (e) => {
    setReferenceType(e.target.value);
    setRefNum("");
  };

  const getAllClients = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/suppliers`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setError("");
          setAllClients(res.data.suppliers);
        } else {
          setError(res.data.message);
        }
      });
  };

  useEffect(() => {
    productCategories();
    getProducts();
    getAllClients();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Edit Tickets And Expenses | Clouget</title>
        </MetaTags>
        {isOpenAddress ? (
          <ClientAddress
            setIsOpenModal={setIsOpenAddress}
            clients={clients}
            setClientData={setClientData}
            setClientNameData={setClientNameData}
          />
        ) : (
          ""
        )}
        {deliveryOptionModal ? (
          <DeliveryOptionModal
            setDeliveryOptionModal={setDeliveryOptionModal}
            // getDeliveryOption={getDeliveryOption}
          />
        ) : (
          ""
        )}
        {isWorkAddress ? (
          <ClientWorkAddress
            setIsOpenModal={setIsWorkAddress}
            clients={clients}
            setWorkData={setWorkData}
            setClientNameData={setClientNameData}
          />
        ) : (
          ""
        )}
        <CategoryModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          getAllCategories={productCategories}
          setSelectedCategory={setSelectedCategory}
        />

        <Container fluid>
          <div className="create_company">
            {loader !== "" ? (
              loader
            ) : (
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  productCreateSubmit(e, v);
                }}
                disabled={mainFormDisabled ? true : false}
              >
                <div className="page-title-box">
                  <Row className="align-items-center">
                    <Col md={6}>
                      {/* <h6 className="page-title">Edit Tickets And Expenses </h6> */}
                    </Col>
                    <Col md={6}>
                      <div className="float-end d-flex">
                        <div className="btn-group me-2">
                          <MDBContainer>
                            <MDBDropdown>
                              <MDBDropdownToggle className="btn btn-primary">
                                More
                              </MDBDropdownToggle>
                              <MDBDropdownMenu>
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getDuplicate()}
                                  >
                                    Duplicate
                                  </span>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <span
                                    className="nav-item dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setconfirm_alert(true);
                                    }}
                                  >
                                    {props.t("Delete")}
                                  </span>
                                </MDBDropdownItem>
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          </MDBContainer>
                        </div>
                        {getPermission().clients.clients.edit.is_checked ===
                        0 ? (
                          isEditable ? (
                            <>
                              {loading ? (
                                <button
                                  className="btn btn-primary  disabled"
                                  type="button"
                                  disabled
                                >
                                  Loading
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary "
                                  type="submit"
                                >
                                  Update
                                </button>
                              )}
                              <span
                                onClick={() => setIsEditable(false)}
                                className="btn btn-primary  ms-2"
                              >
                                {props.t("Cancel")}
                              </span>
                            </>
                          ) : (
                            <span
                              onClick={() => setIsEditable(true)}
                              className="btn btn-primary "
                            >
                              Edit
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                {success ? (
                  <Alert color="success">{props.t(success)}</Alert>
                ) : null}

                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <label>Reference:</label>
                          <div className="d-flex">
                            <AvField
                              style={{
                                width: "100px",
                              }}
                              type="select"
                              name="reference"
                              value={purchaseTicket.reference}
                              disabled={isEditable ? false : true}
                              onChange={(e) => getType(e)}
                            >
                              {allRef &&
                                allRef.map((item) => (
                                  <>
                                    <option value={item.prefix}>
                                      {item.prefix}
                                    </option>
                                  </>
                                ))}
                            </AvField>
                            <div className="w-100 ps-3">
                              <Input
                                className="w-100 quantity"
                                type="number"
                                step="1"
                                min="1"
                                disabled={isEditable ? false : true}
                                name="reference_number"
                                value={refNum}
                                // value={purchaseTicket.reference_number}
                                // placeholder={
                                //   referenceType === "exp" ? "Automatic" : ""
                                // }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="required">
                            <label>Suppliers:</label>
                          </div>
                          <div className="d-flex">
                            <div className="pe-2 w-100 client_custom-dropdown">
                              <SupplierCustomDropdown
                                data={allClients}
                                getClientData={getClientData}
                                clientLoader={clientLoader}
                                setMainFormDisabled={setMainFormDisabled}
                                setClientSelect={setSelectClientId}
                                setClientAddress={setClientData}
                                setClientFull={setClients}
                                clientNameData={clientNameData}
                                setClientNameData={setClientNameData}
                                clientDetail={clientDetail}
                                getAssests={AssetsData}
                                isEditable={isEditable}
                              />
                            </div>
                            {/* <div className="pe-2 w-100">
                                                        <Select
                                                            options={
                                                                optionClients
                                                            }
                                                            onChange={(e) =>
                                                                clientDetail(
                                                                    e.value
                                                                )
                                                            }
                                                            defaultValue={
                                                                selectClientId
                                                            }
                                                            name="client_id"
                                                            isDisabled={
                                                                paymentLoader
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div> */}
                            <div className="pe-2">
                              <button
                                className="btn btn-light"
                                onClick={() => {
                                  setSelectClientModal(true), getClientData();
                                }}
                                type="button"
                              >
                                <i className="fas fa-search" />
                              </button>
                            </div>
                            <Link
                              className={`btn btn-light ${
                                selectClientId == "" ? "disabled" : ""
                              }`}
                              to={`/suppliers/${selectClientId}`}
                              target="_blank"
                            >
                              <i className="fas fa-eye" />
                            </Link>
                          </div>
                          <div className="mb-3 mt-2">
                            <AvField
                              label="Amount($)"
                              type="number"
                              name="amount"
                              readOnly={isEditable ? false : true}
                              value={purchaseTicket.amount}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label>Payment Option</label>
                          <div className="d-flex justify-content-between">
                            {paymentLoader ? (
                              <div className="pe-2">
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="w-100 pe-2">
                              <AvField
                                type="select"
                                name="payment_option"
                                disabled={isEditable ? false : true}
                                value={selectedCates}
                                onChange={(e) => {
                                  setSelectedCates(e.target.value),
                                    e.target.value === "1"
                                      ? setBank(true)
                                      : setBank(false);
                                }}
                              >
                                <option value="" hidden>
                                  --
                                </option>
                                {paymentData &&
                                  paymentData.map((payment) => (
                                    <option value={payment.id} key={payment.id}>
                                      {payment.name}
                                    </option>
                                  ))}
                              </AvField>
                            </div>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => setIsOpenModalNew(true)}
                              style={{
                                whiteSpace: "nowrap",
                              }}
                              type="button"
                              disabled={isEditable ? false : true}
                            >
                              + New
                            </button>
                          </div>
                        </div>
                        {bank ? (
                          <div className="mb-3">
                            <AvField
                              label="Bank Account:"
                              type="select"
                              name="bank_account"
                              disabled={isEditable ? false : true}
                              value={purchaseTicket.bank_account}
                            >
                              <option value="no_account">
                                Without bank account
                              </option>
                              {allBanks &&
                                allBanks.map((bank, i) => (
                                  <option value={bank.id} key={i}>
                                    {bank.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                        ) : (
                          ""
                        )}
                        {paidStatus ? (
                          <div className="mb-3">
                            <label>Paid by</label>
                            {/* <input> */}
                            {/* type="text"
                                       name="pri"
                                          value={
                                           getCurrentUser().email
                                         
                                         }    */}

                            <span
                              onClick={() => setAssignedModal2(true)}
                              className="form-control"
                              style={{
                                minHeight: "35px",
                                backgroundColor: isEditable ? "" : "#f1f1f1",
                                pointerEvents: isEditable ? "" : "none",
                              }}
                            >
                              {paidUserName}
                            </span>
                            {/* </input> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            label="Date"
                            type="date"
                            name="date"
                            readOnly={isEditable ? false : true}
                            className="form-control"
                            value={purchaseTicket.date}
                          />
                        </div>

                        <div className="mb-3">
                          <label>Category</label>
                          <div className="d-flex">
                            <div className="w-100 pe-2">
                              {/* <Select
                              options={optionCategory}
                              onChange={handleSelectCategory}
                            /> */}
                              <AvField
                                type="select"
                                name="category"
                                disabled={isEditable ? false : true}
                                value={purchaseTicket.category_id}
                              >
                                <option value="" hidden>
                                  Select Expense category...
                                </option>
                                {allCategories &&
                                  allCategories.map((category) => (
                                    <option
                                      value={category.id}
                                      key={category.id}
                                    >
                                      {category.name}
                                    </option>
                                  ))}
                              </AvField>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                setIsOpenModal(true);
                              }}
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                              disabled={isEditable ? false : true}
                            >
                              New
                            </button>
                          </div>
                        </div>
                        {/* </div> */}
                        <div className="mb-3">
                          <AvField
                            label="Description:"
                            name="description"
                            readOnly={isEditable ? false : true}
                            value={purchaseTicket.description}
                          >
                            {/* <option value="" hidden>
                            Select
                          </option>
                          <option value="sales">Sales</option> */}
                          </AvField>
                        </div>
                        <div className="mb-3">
                          <label>Employee:</label>
                          {/* <input> */}
                          {/* type="text"
                              name="pri"
                                 value={
                                  getCurrentUser().email
                                
                                }    */}

                          <span
                            onClick={() => setAssignedModal(true)}
                            className="form-control"
                            style={{
                              minHeight: "35px",
                              backgroundColor: isEditable ? "" : "#f1f1f1",
                              pointerEvents: isEditable ? "" : "none",
                            }}
                          >
                            {assignedUserName}
                          </span>
                        </div>
                        <div className="mb-3">
                          <AvField
                            label="Status:"
                            type="select"
                            name="status"
                            disabled={isEditable ? false : true}
                            value={purchaseTicket.status}
                            onChange={(e) =>
                              setPaidStatus(e.target.value === "paid" ? 1 : 0)
                            }
                          >
                            <option value="" hidden>
                              Select
                            </option>
                            <option value="unpaid">Unpaid</option>
                            <option value="paid">Paid</option>
                          </AvField>
                        </div>
                        {paidStatus ? (
                          <div className="mb-3">
                            <AvField
                              label="Payment Date"
                              type="date"
                              disabled={isEditable ? false : true}
                              name="payment_date"
                              value={purchaseTicket.payment_date}
                              className="form-control"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Nav tabs>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({
                          active: customActiveTab === "4",
                        })}disabled`}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      >
                        <span className="d-none d-sm-block">Attachments</span>
                      </NavLink>
                    </Nav>
                  </CardBody>
                </Card>
              </AvForm>
            )}
          </div>
          {assignedModal ? (
            <AssignUserList
              setAssignedModal={setAssignedModal}
              getAssignedUser={getAssignedUser}
              data={assignUsers}
            />
          ) : (
            ""
          )}

          {assignedModal2 ? (
            <AssignUserList
              setAssignedModal={setAssignedModal2}
              getAssignedUser={getPaidUser}
              data={assignUsers}
            />
          ) : (
            ""
          )}
        </Container>
      </div>

      <Modal size="lg" isOpen={assetModal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            New Assest
          </h5>
        </div>
        <div className="modal-body p-0">
          <AssetsNew
            clientData={clients}
            modalData={assetModal}
            setassetModal={setassetModal}
            AssetsData={AssetsData}
          />
        </div>
      </Modal>

      {isOpenModalNew ? (
        <NewPayment
          setIsOpenModal={setIsOpenModalNew}
          NewpaymentShow={NewpaymentShow}
        />
      ) : (
        ""
      )}
      {selectClientModal ? (
        <ClientList
          setSelectClientModal={setSelectClientModal}
          setSelectClientId={setSelectClientId}
          clients={allClients}
          clientDetail={clientDetail}
          getSuppliers={getSuppliers}
        />
      ) : (
        ""
      )}
      {selectClientModal2 ? (
        <ClientList
          setSelectClientModal={setSelectClientModal2}
          setSelectClientId={setSelectClientId2}
          clients={allClients}
          clientDetail={clientDetail2}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default EditTicketsAndExpenses;

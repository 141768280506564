import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function LineChart(props) {
    // let chartData = {
    //     labels: props.data.labels,
    //     datasets: props.data.data,
    // };

    // console.log("chartData", chartData);

    const [data, setData] = useState({});

    useEffect(() => {
        if (!props.data || !Array.isArray(props.data.data)) {
            console.error("Invalid data:", props.data);
            return;
        }

        if (typeof props.t !== "function") {
            console.error("props.t is not a function");
            return;
        }

        const newData = props.data.data.map((e) => {
            return {
                ...e,
                label: props.t(e.label),
            };
        });

        setData({
            labels: props.data.labels,
            datasets: newData,
        });
    }, [props.data, props.t]);

    return <Line data={data} />;
}

export default withTranslation()(LineChart);
LineChart.propTypes = {
    t: PropTypes.any,
};
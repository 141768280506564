import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Table,
  Modal,
  Input,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import countryList from "react-select-country-list";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import ClientCategory from "../../Category/client/client-category";
let cc = require("currency-codes");
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import NewPayment from "../../../components/PaymentOption/payment_option";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import PropTypes from "prop-types";
// const CreateSupplier = ({
//   match: {
//     params: { id },
//   },
// }) => {
//   const [userId] = useState(parseInt(id, 10));
const CreateSupplier = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("general");
  const [referenceType, setReferenceType] = useState("SUP");
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedCate, setSelectedCate] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [swift, setSwift] = useState(false);
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [allAddress, setAllAddress] = useState([]);
  const [paymentData, setPaymentData] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [isOpenModalNew, setIsOpenModalNew] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [allRef, setAllRef] = useState([]);
  const [defaultRef, setDefaultRef] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [company, setCompany] = useState(null);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const getSingleCompany = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/companies/${localStorage.getItem(
          "companyId"
        )}?company_id=${localStorage.getItem("companyId")}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader(false);
        setCompany(res.data.company);
      });
  };

  useEffect(() => {
    getSingleCompany();
  }, []);

  const newUserSubmit = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    let subject_to_vat = "";
    let subject_to_income_tax = "";
    formData.append("reference", value.reference);
    formData.append("reference_number", value.reference_number);
    formData.append("legal_name", value.legal_name);
    formData.append("tin", value.tin);
    formData.append("phone_1", value.phone_1);
    formData.append("address", value.address);
    formData.append("state", value.state);
    formData.append("name", value.name);
    formData.append("email", value.email);
    formData.append("city", value.city);
    formData.append("zip_code", value.zip_code);
    formData.append("fax", value.fax);
    formData.append("website", value.website);
    formData.append("comments", value.comments);
    formData.append("created_from", value.created_from);
    formData.append("phone_2", phone2);
    formData.append("payment_date", value.payment_date);
    formData.append("payment_option_id", value.payment_option_id);
    formData.append("discount", value.discount);
    formData.append("country", e.target.country.value);
    // formData.append("rate", value.rate);
    formData.append("currency", value.currency);
    // if (e.target.subject_to_vat.value == "on") {
    //   subject_to_vat = 1;
    // }
    formData.append("subject_to_vat", "1");
    // if (e.target.subject_to_income_tax.value == "on") {
    //   subject_to_income_tax = 1;
    // }
    formData.append("subject_to_income_tax", "1");
    // formData.append("maximum_risk", value.maximum_risk);
    formData.append("payment_terms_id", value.payment_terms_id);
    formData.append("payment_adjustment", value.payment_adjustment);
    formData.append("agent", value.agent);
    // formData.append("invoice_to", value.invoice_to);
    formData.append("bank_account_format", value.bank_account_format);
    formData.append("bank_account_account", value.bank_account_account);
    formData.append(
      "bank_account_account_holder",
      value.bank_account_account_holder
    );
    // formData.append("bank_account_bic", value.bank_account_bic);
    if (value.swift_aba) {
      formData.append("swift_aba", value.swift_aba);
    }
    if (value.ced_ruc) {
      formData.append("ced_ruc", value.ced_ruc);
    }
    formData.append("bank_account_name", value.bank_account_name);
    formData.append("bank_account_description", value.bank_account_description);
    if (selectedCate) {
      formData.append("supplier_category", selectedCate);
    }
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/suppliers`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          history.push(`/suppliers/${res.data.Supplier.id}`);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  //   const getContactAll = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //           "companyId"
  //         )}/supplier-contacts`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getCurrentUser().token}`,
  //           },
  //         }
  //       )
  //       .then(function (res) {
  //         setContactsAll(res.data.supplier_contacts);
  //       });
  //   };

  //   const getSingleContact = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //           "companyId"
  //         )}/supplier-contacts?supplier_id=${userId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getCurrentUser().token}`,
  //           },
  //         }
  //       )
  //       .then(function (res) {
  //         setContactsAll(res.data.supplier_contacts);
  //       });
  //   };

  //   useEffect(() => {
  //     getSingleContact();
  //     getContactAll();
  //   }, []);
  //   const createContact = (e, value) => {
  //     setLoading(true);
  //     const formData = new FormData();
  //     formData.append("name", value.name);
  //     formData.append("phone", value.phone);
  //     formData.append("email", value.email);
  //     formData.append("supplier_id", userId);
  //     formData.append("fax", value.fax);
  //     formData.append("position", value.position);
  //     formData.append("comments", value.comments);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //           "companyId"
  //         )}/supplier-contacts`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-type": "multipart/form-data",
  //             Authorization: `Bearer ${getCurrentUser().token}`,
  //           },
  //         }
  //       )
  //       .then(function (res) {
  //         if (res.data.status) {
  //           setError("");
  //           setSuccess(res.data.message);
  //           setIsOpenContact(false);
  //           setLoading(false);
  //           //   getSingleContact();
  //         } else {
  //           setError(res.data.message);
  //           setSuccess("");
  //         }
  //         setTimeout(() => {
  //           setError("");
  //           setSuccess("");
  //         }, 4000);
  //       });
  //   };
  //   const getSingleClientsAddress = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //           "companyId"
  //         )}/supplier-addresses?supplier_id=${userId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getCurrentUser().token}`,
  //           },
  //         }
  //       )
  //       .then(function (res) {
  //         setAllAddress(res.data.supplier_addresses);
  //       });
  //   };

  //   useEffect(() => {
  //     getSingleClientsAddress();
  //   }, []);
  const NewpaymentShow = () => {
    setPaymentLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPaymentData(res.data.PaymentOptions);
        setPaymentLoader(false);
      });
  };

  const getAllPayment = () => {
    setPaymentData([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-terms`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setPaymentTerm(res.data.payment_terms);
        }
      });
  };
  const getCountry = (e) => {
    setPhone(e);
  };
  const getCountry2 = (e) => {
    setPhone2(e);
  };

  const getType = (e) => {
    setReferenceType(e.target.value);
  };

  let optionCategory = [];
  if (allCategories !== undefined) {
    {
      allCategories &&
        allCategories.map((category) => {
          optionCategory.push({
            value: category.id,
            label: category.name,
          });
        });
    }
  }

  function handleSelectCategory(selectedcate) {
    setSelectedCate(selectedcate.value);
  }
  const CategoriesClient = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-categories?type=supplier`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllCategories(res.data.client_categories);
      });
  };
  //   const selectClient = (e, item) => {
  //     clientAddress = item;
  //     if(props.setClientNameData !== undefined) {
  //       props.setClientNameData(item.name);
  //     }
  //     if(props.setClientData !== undefined) {
  //         props.setClientData(item.address);
  //     }
  //     if(props.setDelAddress !== undefined) {
  //       props.setDelAddress(item.address);
  //     }
  //     props.setIsOpenModal(false)
  //   };
  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Supplier`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
          res.data.reference?.map((item) => {
            if (item.by_default === "1") {
              setDefaultRef(item.prefix);
            }
          });
        } else {
          //   setError(res.data.message);
        }
      });
  };

  useEffect(() => {
    NewpaymentShow();
    getReferences();
    // getClients();
    getAllPayment();
    CategoriesClient();
  }, []);
  const createOtherAddress = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    // formData.append("supplier_id", userId);
    formData.append("address", value.address);
    formData.append("state", value.state);
    formData.append("city", value.city);
    formData.append("zip_code", value.zip_code);
    formData.append("country", value.country);
    formData.append("type", value.type);
    formData.append("extra_information", value.extra_information);
    formData.append("description", value.description);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/supplier-addresses`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          setIsOpenAddress(false);
          setLoading(false);
          getSingleClientsAddress();
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Supplier | Clouget</title>
        </MetaTags>
        {modal ? (
          <ClientCategory
            setIsOpenModal={setModal}
            CategoriesClient={CategoriesClient}
          />
        ) : (
          ""
        )}
        <Container fluid>
          <div className="create_company">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                newUserSubmit(e, v);
              }}
            >
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h6 className="page-title">
                      {props.t("Create new supplier")}
                    </h6>
                  </Col>
                  <Col md={6}>
                    <div className="float-end d-flex">
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          {props.t("Loading")}
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          {props.t("Save")}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}

              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <label>{props.t("Reference")}</label>
                      <div className="d-flex">
                        <AvField
                          style={{
                            width: "100px",
                          }}
                          type="select"
                          name="reference"
                          value={defaultRef}
                          onChange={(e) => getType(e)}
                        >
                          {allRef &&
                            allRef.map((item, i) => (
                              <option value={item.prefix} key={i}>
                                {item.prefix}
                              </option>
                            ))}
                          <option value="manual">{props.t("MANUAL")}</option>
                        </AvField>
                        <div className="w-100 ps-3">
                          <AvField
                            className="mb-3 w-100"
                            type="number"
                            name="reference_number"
                            placeholder={props.t("Automatic")}
                          />
                        </div>
                      </div>
                      <div className="mb-3 required">
                        <AvField
                          label={props.t("Legal Name")}
                          type="text"
                          name="legal_name"
                          required="required"
                        />
                      </div>

                      <div className="mb-3 required">
                        <AvField
                          label={props.t("Ced/Ruc")}
                          type="number"
                          onKeyPress={(e) => {
                            if (e.target.value.length == 13) e.preventDefault();
                          }}
                          name="tin"
                          required="required"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <AvField
                        className="mb-3"
                        label={props.t("Name")}
                        type="text"
                        name="name"
                      />
                      <div className="mb-3">
                        <AvField
                          label={props.t("Email")}
                          type="email"
                          name="email"
                        />
                      </div>

                      <AvField
                        className="mb-3"
                        placeholder={props.t("Enter phone number")}
                        label={props.t("Phone 1")}
                        type="number"
                        name="phone_1"
                        maxLength={10}
                        validate={{
                          maxLength: {
                            value: 10,
                            errorMessage: props.t(
                              "The number cannot be more than 10 digits."
                            ),
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <AvField
                        className="mb-3"
                        label={props.t("Address")}
                        type="text"
                        name="address"
                        value={company?.city}
                      />
                      {loader ? (
                        <Spinner />
                      ) : (
                        <AvField
                          className="mb-3"
                          label={props.t("State/Province")}
                          type="text"
                          name="state"
                          value={company?.state}
                        />
                      )}
                      <div className="mb-3">
                        <AvField
                          type="select"
                          name="country"
                          label="Country"
                          defaultValue="Ecuador"
                        >
                          <option value="">Select</option>
                          {countryList().data.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <AvField
                        className="mb-3"
                        placeholder={props.t("Enter phone number")}
                        label={props.t("Phone 2")}
                        type="number"
                        name="phone_2"
                        maxLength={10}
                        validate={{
                          maxLength: {
                            value: 10,
                            errorMessage: props.t(
                              "The number cannot be more than 10 digits."
                            ),
                          },
                        }}
                      />
                      {loader ? (
                        <Spinner />
                      ) : (
                        <AvField
                          className="mb-3"
                          label={props.t("City/town")}
                          type="text"
                          name="city"
                          value={company?.city}
                        />
                      )}
                      <AvField
                        className="mb-3"
                        label={props.t("Zip code")}
                        type="text"
                        name="zip_code"
                      />
                    </Col>
                  </Row>
                  <Nav tabs className="mt-5">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "general",
                        })}
                        onClick={() => {
                          toggleCustom("general");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("General")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "commercial",
                        })}
                        onClick={() => {
                          toggleCustom("commercial");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Commercial")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "special_prices",
                        })}
                        onClick={() => {
                          toggleCustom("special_prices");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Special Prices")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "bank_account",
                        })}
                        onClick={() => {
                          toggleCustom("bank_account");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Bank Account")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "contacts",
                        })}
                        onClick={() => {
                          toggleCustom("contacts");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Contacts")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "other_addresses",
                        })}
                        onClick={() => {
                          toggleCustom("other_addresses");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Other addresses")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "assets",
                        })}
                        onClick={() => {
                          toggleCustom("assets");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Assets")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "attachments",
                        })}
                        onClick={() => {
                          toggleCustom("attachments");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Attachments")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "balance",
                        })}
                        onClick={() => {
                          toggleCustom("balance");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Balance")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "related",
                        })}
                        onClick={() => {
                          toggleCustom("related");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Related")}
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="p-3">
                    <TabPane tabId="general">
                      <Row>
                        <Col lg="6">
                          <AvField
                            className="mb-3"
                            label={props.t("Fax")}
                            type="text"
                            name="fax"
                          />
                          <AvField
                            className="mb-3"
                            label={props.t("Website")}
                            type="text"
                            name="website"
                            placeholder="web Format"
                          />
                        </Col>
                        <Col lg="6">
                          <label>{props.t("Supplier Category")}</label>
                          <div className="d-flex mb-3">
                            <div className="w-100 me-2">
                              <Select
                                options={optionCategory}
                                onChange={handleSelectCategory}
                                name="supplier_category"
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                setModal(true);
                              }}
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                            >
                              {props.t("New")}
                            </button>
                          </div>
                          <AvField
                            className="mb-3"
                            label={props.t("Created from")}
                            type="text"
                            name="created_from"
                            value="WEB"
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <AvField
                            className="mb-3"
                            label={props.t("Comments")}
                            type="textarea"
                            name="comments"
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="commercial">
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <label>{props.t("Payment Option")}</label>
                            <div className="d-flex justify-content-between">
                              {paymentLoader ? (
                                <div className="pe-2">
                                  <Spinner
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="w-100 pe-2">
                                <AvField type="select" name="payment_option_id">
                                  <option value="" hidden>
                                    {props.t("Select")}
                                  </option>
                                  {paymentData &&
                                    paymentData.map((item, i) => (
                                      <option
                                        value={item.id}
                                        key={i}
                                        // label={item.name}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                </AvField>
                              </div>
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenModalNew(true)}
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                                type="button"
                              >
                                + {props.t("New")}
                              </button>
                            </div>
                          </div>

                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="payment_date"
                              label={props.t("Payment Date")}
                            >
                              <option value="">
                                {props.t("Select a Date")}...
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              className="mb-3"
                              label={props.t("Discount (%)")}
                              type="text"
                              name="discount"
                              value="0.00"
                            />
                          </div>
                          {/* <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="rate"
                                                            label="Rate"
                                                        >
                                                            <option value="sale_price">
                                                                Sale price
                                                            </option>
                                                            <option value="category_rate">
                                                                Category Rate
                                                            </option>
                                                        </AvField>
                                                    </div> */}
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="currency"
                              label={props.t("Currency")}
                              value="USD"
                            >
                              {cc.data.map((currency) => (
                                <option
                                  value={currency.code}
                                  key={currency.code}
                                >
                                  {currency.code} - {currency.currency}
                                </option>
                              ))}
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <label>Subject to VAT:</label>
                            <Input
                              type="checkbox"
                              className="ms-2"
                              name="subject_to_vat"
                            />
                          </div> */}
                          {/* <div className="mb-3">
                                                        <AvField
                                                            className="mb-3"
                                                            label="Maximum Risk($)"
                                                            type="number"
                                                            name="maximum_risk"
                                                            defaultValue="0.00"
                                                        />
                                                    </div> */}
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <AvField
                              label={props.t("Payment Term")}
                              type="select"
                              name="payment_terms_id"
                            >
                              {/* <option value="0">Immediate Payment</option> */}
                              {paymentTerm &&
                                paymentTerm.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              type="select"
                              name="payment_terms"
                              label="Payment Terms"
                            >
                              <option value="">Select a Payment Term...</option>
                              <option value="immediate_payment">
                                Immediate Payment
                              </option>
                              <option value="days_payment">
                                30-60-90 Days Payment
                              </option>
                            </AvField>
                          </div> */}
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="payment_adjustment"
                              label={props.t("Payment Adjustment")}
                            >
                              <option value="unspecified">
                                {props.t("Unspecified")}
                              </option>
                              <option value="previous_than">
                                {props.t("Previous than")}
                              </option>
                              <option value="later_than">
                                {props.t("Later than")}
                              </option>
                              <option value="closest_to">Closest to</option>
                            </AvField>
                          </div>
                          <AvField
                            className="mb-3"
                            label={props.t("Agent")}
                            type="text"
                            name="agent"
                            value={getCurrentUser().email}
                          />
                          {/* <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="invoice_to"
                                                            label="Invoice to"
                                                            value="USD"
                                                        >
                                                            {clients &&
                                                                clients.map(
                                                                    (
                                                                        client
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                client.id
                                                                            }
                                                                        >
                                                                            {
                                                                                client.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </AvField>
                                                    </div> */}
                          {/* <div className="mb-3">
                            <label>Subject to Income Tax:</label>
                            <Input
                              type="checkbox"
                              className="ms-2"
                              name="subject_to_income_tax"
                            />
                          </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="bank_account">
                      <Row>
                        <Col lg="12">
                          <div className="mb-3">
                            <label>{props.t("Account type")}</label>
                            <AvRadioGroup
                              name="bank_account_format"
                              className="radiobutton"
                            >
                              <AvRadio
                                label={props.t("AHORROS")}
                                value="ahorros"
                                onClick={() => setSwift(false)}
                              />
                              <AvRadio
                                label={props.t("CORRIENTE")}
                                value="corriente"
                                onClick={() => setSwift(false)}
                              />
                              {/* <AvRadio label="Swift/ABA" value="swift/aba" onClick={() => setSwift(true)}/> */}
                            </AvRadioGroup>
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Bank Name")}
                              type="text"
                              name="bank_account_name"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Account number")}
                              type="text"
                              name="bank_account_account"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Account Holder Name")}
                              type="text"
                              name="bank_account_account_holder"
                            />
                          </div>
                          {/* <div className="mb-3">
                                                        <AvField
                                                        label="BIC/SWIFT"
                                                        type="text"
                                                        name="bank_account_bic"
                                                        />
                                                    </div> */}
                          <div className="mb-3">
                            <AvField
                              label={props.t("ABA/SWIFT")}
                              type="text"
                              name="swift_aba"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("CED/RUC")}
                              type="number"
                              onKeyPress={(e) => {
                                if (e.target.value.length == 13)
                                  e.preventDefault();
                              }}
                              name="ced_ruc"
                            />
                          </div>
                          {/* <div className="mb-3">
                                                        <AvField
                                                        label="Description"
                                                        type="textarea"
                                                        name="bank_account_description"
                                                        />
                                                    </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="contacts">
                      <button
                        type="button"
                        onClick={() => {
                          setIsOpenContact(true);
                        }}
                        className=" float-end d-none d-md-block btn btn-primary mb-3"
                        data-toggle="modal"
                        data-target=".bs-example-modal-lg"
                      >
                        {props.t("New")}
                      </button>
                    </TabPane>
                    <TabPane tabId="other_addresses">
                      <button
                        type="button"
                        onClick={() => {
                          setIsOpenAddress(true);
                        }}
                        className=" float-end d-none d-md-block btn btn-primary mb-3"
                        data-toggle="modal"
                        data-target=".bs-example-modal-lg"
                      >
                        {props.t("New")}
                      </button>
                      <div className="mdb-datatable mt-3">
                        <Table className="align-middle">
                          <thead>
                            <tr>
                              <th>{props.t("Address")}</th>
                              <th>{props.t("City/Town")}</th>
                              <th>{props.t("State")}</th>
                              <th>{props.t("Zip/Postal Code")}</th>
                              <th>{props.t("Country")}</th>
                              <th>{props.t("Extra Info")}</th>
                              <th>{props.t("Description")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allAddress &&
                              allAddress.map((item, i) => (
                                <tr
                                  key={i}
                                  // onClick={(e) => selectClient(e, item)}
                                >
                                  <td>{item.address}</td>
                                  <td>{item.city}</td>
                                  <td>{item.state}</td>
                                  <td>{item.zip}</td>
                                  <td>{item.country}</td>
                                  <td>{item.extra_information}</td>
                                  <td>{item.description}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        {/* {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                        {loader} */}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </AvForm>
          </div>
          {/* <Modal size="lg" isOpen={isOpenContact} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                New contact
              </h5>
              <button
                type="button"
                className="cross__btn btn btn-primary waves-effect waves-light"
                onClick={() => setIsOpenContact(false)}
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <AvForm
                onValidSubmit={(e, v) => {
                  createContact(e, v);
                }}
              >
                <Row>
                  <Col lg={6}>
                    <AvField
                      className="mb-3"
                      label="Name"
                      type="text"
                      name="name"
                    />
                    <AvField
                      className="mb-3"
                      label="Phone"
                      type="text"
                      name="phone"
                    />
                    <AvField
                      className="mb-3"
                      label="Email"
                      type="email"
                      name="email"
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      className="mb-3"
                      label="Fax"
                      type="text"
                      name="fax"
                    />
                    <AvField
                      className="mb-3"
                      label="Position"
                      type="text"
                      name="position"
                    />
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3 ">
                      <AvField
                        className="mb-3"
                        label="Comments"
                        type="textarea"
                        name="comments"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <div className="text-end">
                    {loading ? (
                      <button
                        className="btn btn-primary  disabled"
                        type="button"
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button className="btn btn-primary " type="submit">
                        Save
                      </button>
                    )}
                    <button
                      className="btn btn-primary ms-2"
                      type="button"
                      onClick={() => setIsOpenContact(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </AvForm>
            </div>
          </Modal> */}
          <Modal size="lg" isOpen={isOpenAddress} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                {props.t("Other Addresses")}
              </h5>
              <button
                type="button"
                className="cross__btn btn btn-primary waves-effect waves-light"
                onClick={() => setIsOpenAddress(false)}
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <AvForm
                onValidSubmit={(e, v) => {
                  createOtherAddress(e, v);
                }}
              >
                <Row>
                  <Col lg={6}>
                    <AvField
                      className="mb-3"
                      label={props.t("Address")}
                      type="text"
                      name="address"
                    />
                    <AvField
                      className="mb-3"
                      label={props.t("State/Province")}
                      type="text"
                      name="state"
                    />
                    <div className="mb-3">
                      <AvField
                        type="select"
                        name="country"
                        label={props.t("Country")}
                      >
                        {countryList().data.map((item) => (
                          <option value={item.label} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </AvField>
                    </div>
                    <AvField
                      className="mb-3"
                      label={props.t("Type")}
                      type="select"
                      name="type"
                    >
                      <option value="invoicing">{props.t("Invoicing")}</option>
                      <option value="delivery">
                        {props.t("Delivery/Work")}
                      </option>
                      <option value="other">{props.t("Other")}</option>
                    </AvField>
                  </Col>
                  <Col lg={6}>
                    <AvField
                      className="mb-3"
                      label={props.t("City/town")}
                      type="text"
                      name="city"
                    />
                    <AvField
                      className="mb-3"
                      label={props.t("Zip code")}
                      type="text"
                      name="zip_code"
                    />
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3 mt-3">
                      <AvField
                        label={props.t("Extra Info")}
                        type="text"
                        name="extra_information"
                      />
                    </div>
                    <div className="mb-3 mt-3">
                      <AvField
                        label={props.t("Description")}
                        type="textarea"
                        name="description"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <div className="text-end">
                    {loading ? (
                      <button
                        className="btn btn-primary  disabled"
                        type="button"
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button className="btn btn-primary " type="submit">
                        Update
                      </button>
                    )}
                    <button
                      className="btn btn-primary ms-2"
                      type="button"
                      onClick={() => setIsOpenAddress(false)}
                    >
                      {props.t("Cancel")}
                    </button>
                  </div>
                </div>
              </AvForm>
            </div>
          </Modal>
        </Container>
      </div>
      {isOpenModalNew ? (
        <NewPayment
          setIsOpenModal={setIsOpenModalNew}
          NewpaymentShow={NewpaymentShow}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
export default withTranslation()(CreateSupplier);
CreateSupplier.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useMemo, useEffect } from "react";
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
import {
    Alert,
    Modal
} from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ElectronicModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [file, setFile] = useState("");
    const [description, setDescription] = useState(props.sinCom?.electronic_sig_desc);
    const [password, setPassword] = useState(props.sinCom?.electronic_sig_password);
    const [expire_date, setExpireDate] = useState(props.sinCom?.electronic_sig_expire_date);

    useEffect(() => {
        setDescription(props.sinCom?.electronic_sig_desc);
        setPassword(props.sinCom?.electronic_sig_password);
        setExpireDate(props.sinCom?.electronic_sig_expire_date);
    }, [props])

    function convertFileToBase64(file, callback) {
        const reader = new FileReader();

        reader.onload = function () {
            const base64String = reader.result.split(',')[1];
            callback(base64String);
        };

        reader.readAsDataURL(file);
    }

    const getFile = (e) => {
        const selectedFile = e.target.files[0];

        setFile(selectedFile);
        // if (selectedFile) {
        //     convertFileToBase64(selectedFile, (base64String) => {
        //     });
        // }
    };

    const submitSignature = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("certificate", file);
        formData.append("description", description);
        formData.append("password", password);
        // formData.append("expire_date", expire_date);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/add-electronic-signature`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess("Added Successfully!");
                    props.setSinCom(prevState => ({
                        ...prevState,
                        electronic_sig_expire_date: res.data.electronic_sig_expire_date
                    }));
                    setTimeout(() => {
                        props.setElectronicModal(false)
                    }, 4000);
                } else {
                    if (res.data.result !== undefined) {
                        setError(res.data.result.messages[0]);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 9000);
            });
    }

    return (
        <>
            <Modal size="" isOpen={props.electronicModal} centered={true}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.t("Electronic Signature")}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setElectronicModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? <Alert color="success">{success}</Alert> : null}

                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <AvForm className="form-horizontal">
                        <div className="mb-3 required">
                            <label>{props.t("Certificate")}</label>
                            <AvField
                                type="file"
                                name="certificate"
                                // accept=".pdf,.docx,.png,.jpg,.jpeg,.webp,.doc"
                                onChange={e => getFile(e)}
                            // disabled={props?.data.electronic_sig_added === 1 ? true : false}
                            />
                        </div>
                        <div className="mb-3 required">
                            <AvField
                                label={props.t("Description")}
                                type="textarea"
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            />
                        </div>
                        <div className="mb-3 required">
                            <AvField
                                label={props.t("Password")}
                                type="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </div>
                        <div className="mb-3">
                            <AvField
                                label={props.t("Expire Date")}
                                onChange={(e) => setExpireDate(e.target.value)}
                                dateFormat="MMMM d, yyyy"
                                type="date"
                                name="expired_date"
                                autoComplete="off"
                                className="is-untouched is-pristine av-valid form-control"
                                value={expire_date}
                                disabled
                            />
                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button className="btn btn-primary" type="button">
                                {props.t("Loading")}
                            </button>
                        ) : (
                            props?.sinCom.electronic_sig_added === 1 ?
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => submitSignature()}
                                >
                                    {props.t("Update")}
                                </button>
                                :
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => submitSignature()}
                                >
                                    {props.t("Submit")}
                                </button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default withTranslation()(ElectronicModal);
ElectronicModal.propTypes = {
    t: PropTypes.any,
};
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceAttachment = (props) => {
  const [user, setUser] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isOpenModals, setIsOpenModals] = useState(false);
  const [attachmentDesc, setAttachmentDesc] = useState(props.data.description);
  let selectedRoleId = [];
  let selectedRolName = [];
  const deleteUser = (Id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoice-attachments/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setUser(null);
        setdynamic_description(res.data.message);
      });
  };

  const updateTechnical = () => {
    const formData = new FormData();
    formData.append("description", attachmentDesc);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices-attachments/${user.id}?_method=PUT`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser(res.data.client_attachment);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsOpenModals(false);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const getSelectedId = (e, check, name) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      selectedRolName.push(name);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueName = selectedRolName.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      selectedRolName = uniqueName;
      props.setSelectedIds(selectedRoleId);
      props.setSelectedName(selectedRolName);
    } else {
      selectedRoleId = props.selectedIds;
      selectedRolName = props.selectedName;
      var array = selectedRoleId;
      var array2 = selectedRolName;
      var index = array.indexOf(e);
      var index2 = array2.indexOf(name);
      if (index !== -1) {
        array.splice(index, 1);
      }
      if (index2 !== -1) {
        array2.splice(index2, 1);
      }
      props.setSelectedIds(selectedRoleId);
      props.setSelectedName(selectedRolName);
    }
  };
  useEffect(() => {
    selectedRoleId = [];
    selectedRolName = [];
  }, []);
  if (user === null) {
    return "";
  } else
    return (
      <>
        <ToastContainer />
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(user.id, e, user.name)}
              name="chk2"
              value={user.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>
            <a href={user.document} target="_blank" rel="noreferrer">
              {/* <img
                height="40"
                width="40"
                style={{ objectFit: "cover" }}
                src={user.document ? user.document : uploadImg}
              /> */}
              {user.name}
            </a>
          </td>
          <td onClick={() => setIsOpenModals(true)}>{user.description}</td>
          <td onClick={() => setIsOpenModals(true)}>{user.updated_at}</td>
          <td>
            <button
              className="btn btn-danger"
              onClick={() => {
                setconfirm_alert(true);
              }}
            >
              Delete
            </button>
            <a
              href={user.document}
              className="btn btn-danger ms-4"
              target="_blank"
              rel="noreferrer"
              download
            >
              Download
            </a>
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteUser(user.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("File has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}
        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
        <Modal size="lg" isOpen={isOpenModals} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Edit
            </h5>
          </div>
          <div className="modal-body">
            <AvForm>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label>Description</label>
                    <AvField
                      name="description"
                      type="textarea"
                      className="form-control"
                      defaultValue={attachmentDesc}
                      onChange={(e) => setAttachmentDesc(e.target.value)}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <div className="modal-footer">
                <div className="text-end">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => updateTechnical()}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    type="button"
                    onClick={() => setIsOpenModals(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Modal>
      </>
    );
};
export default InvoiceAttachment;

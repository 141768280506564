import React, { useState } from "react";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { Modal, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

let totalpercentage;

function PayTermModal(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [err2, setErr2] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState("");
  const [paymentTerms, setPaymentTerms] = useState({
    fields: [{ percentage: "100", days: "0" }],
  });

  const addOtherFields = () => {
    setPaymentTerms({
      fields: [...paymentTerms.fields, { percentage: "0", days: "0" }],
    });
  };

  const removeOtherFields = (i) => {
    setLoader(<Loader />);
    let fields = paymentTerms.fields;
    setPaymentTerms({ fields: [] });
    fields.splice(i, 1);
    setTimeout(() => {
      setPaymentTerms({ fields });
      setLoader("");
    }, 500);
  };

  const handleOtherFields = (i, e) => {
    let fields = paymentTerms.fields;
    fields[i][e.target.name] = e.target.value;
    setPaymentTerms({ fields });
    totalpercentage = paymentTerms.fields.reduce(
      (totalpercentage, currentValue) =>
        (totalpercentage = totalpercentage + parseInt(currentValue.percentage)),
      0
    );
    if (totalpercentage != 100) {
      setErr(true);
      setErr2(false);
    } else {
      setErr(false);
    }
  };

  const submitPaymentTerm = (e, value) => {
    let valid = "1";
    paymentTerms.fields?.map((item) => {
      if (item.percentage == "0") {
        setErr2(true);
        valid = "0";
      }
    });
    if (valid == "0") {
    } else {
      setErr2(false);
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/payment-terms`,
          {
            name: value.name,
            description: value.description,
            terms: paymentTerms.fields,
          },
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoader("");
          if (res.data.status) {
            setError("");
            setLoading(false);
            if (props.setPayTerm !== undefined) {
              props.setPayTerm(res.data.data.id);
            }
            if (props.setPayTermModal !== undefined) {
              props.setPayTermModal(false);
            }
            if (props.getAllPayment !== undefined) {
              props.getAllPayment();
            }
          } else {
            setError(res.data.message);
            setLoading(false);
          }
        });
    }
  };

  return (
    <Modal size="lg" isOpen="true" centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          {props.t("New payment option")}
        </h5>
        <button
          type="button"
          className="cross__btn btn btn-primary waves-effect waves-light"
          onClick={() => props.setPayTermModal(false)}
          aria-hidden="true"
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            submitPaymentTerm(e, v);
          }}
        >
          {error ? (
            <div className="mb-3">
              <p className="alert alert-danger">{props.t(error)}</p>
            </div>
          ) : (
            ""
          )}
          {loader ? (
            loader
          ) : (
            <>
              <div className="mb-3 required">
                <AvField
                  label={props.t("Name")}
                  type="text"
                  name="name"
                  required="required"
                />
              </div>
              <div className="mb-3">
                <AvField
                  label={props.t("Description")}
                  type="textarea"
                  name="description"
                />
              </div>
              <div className="mb-3 mt-2">
                <div className="">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => addOtherFields()}
                  >
                    <i className="fa fa-plus me-2"></i>
                    {props.t("New Item")}
                  </button>
                </div>
                {err ? (
                  <span className="text-danger">
                    {props.t("The total percentage amount must add to 100%")}
                  </span>
                ) : (
                  ""
                )}{" "}
                <br />
                {err2 ? (
                  <span className="text-danger">
                    {props.t("You cannot insert a 0% percentage.")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="table-responsive mb-3">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th>{props.t("Percentage")}(%)</th>
                      <th>{props.t("Days")}</th>
                      <th className="text-end">{props.t("Delete")}</th>
                    </tr>
                    {paymentTerms.fields?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <AvField
                            name="percentage"
                            type="number"
                            onChange={(e) => handleOtherFields(index, e)}
                            value={item.percentage}
                          />
                        </td>
                        <td>
                          <AvField
                            name="days"
                            type="number"
                            onChange={(e) => handleOtherFields(index, e)}
                            value={item.days}
                          />
                        </td>
                        <td className="text-end">
                          <button
                            className="btn btn-danger"
                            onClick={() => removeOtherFields(index)}
                          >
                            &times;
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <div className="text-end">
            {loading ? (
              <button
                className="btn btn-primary  disabled"
                type="button"
                disabled
              >
                {props.t("Loading")}
              </button>
            ) : (
              <button className="btn btn-primary " type="submit">
                {props.t("Submit")}
              </button>
            )}
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => props.setPayTermModal(false)}
            >
              {props.t("Cancel")}
            </button>
          </div>
        </AvForm>
      </div>
    </Modal>
  );
}
export default withTranslation()(PayTermModal);
PayTermModal.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import {
    getCurrentUser,
    getPermission,
    getFilterDate,
    setFilterDate,
} from "../../helpers/Utils";
const axios = require("axios").default;
import AllInvoices from "../../components/AllInvoices/all-invoices";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import SendEmail from "../sendEmail/index";
import MultipleChoice from "../../components/filters/multiChoice";
import DateFilter from "../../components/filters/dateFilter";
import InputFilter from "../../components/filters/searchFilter";
import DownloadOption from "../../pages/Invoicing/download";
import fileSaver from "file-saver";
import PrintOption from "../MorePrintOption";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";
import Paginations from "../../components/pagination";

const RefundInvoices = (props) => {
    const history = useHistory();
    const [ref_filter, setRef_filter] = useState(false);
    const [cliName_filter, setCliName_filter] = useState(false);
    const [title_filter, setTitle_filter] = useState(false);
    const [createdby_filter, setCreatedBy_filter] = useState(false);
    const [amount_filter, setAmount_filter] = useState(false);
    const [statusINV_filter, setstatusINV_filter] = useState(false);
    const [statusVeronica_filter, setstatusVeronica_filter] = useState(false);
    const [date_filter, setdate_filter] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [selectedIds, setSelectedIds] = useState([]);
    const [timer, setTimer] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [link, setLink] = useState("");
    const [format, setFormat] = useState("");
    const [template1, setTemplate1] = useState("0");
    const [priceTax, setPriceTax] = useState("");
    const [mailingFormat, setMailingFormat] = useState("");
    const [productService, setProductService] = useState("");
    const [defaultConfig, setDefaultConfig] = useState("");
    const [success, setSuccess] = useState("");
    const [email, setEmail] = useState(getCurrentUser().email);
    const [email2, setEmail2] = useState("");
    const [selectedType, setSelectedType] = useState([]);
    const [selectedNum, setSelectedNum] = useState([]);
    const [isDownloadModal, setIsDownloadModal] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [link1, setLink1] = useState("");
    const [isPrintModal, setIsPrintModal] = useState(false);
    const [messageData, setMessageData] = useState("");
    const [signedBox, setSignedBox] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [paginationData, setPaginationData] = useState("");
    const [mailPDFFormat, setMailPDFFormat] = useState("");
    const [searchValue, setSearchValue] = useState('');

    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/refund-invoice-create");
        }
    };

    const getSearchData = (e) => {
        setSearchValue(e);
        localStorage.setItem('search', `refund-invoice, ${e}`)
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getInvoiceData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getInvoiceData = (e) => {
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoices?type=Refund Invoice&search=${e != undefined ? e : ""}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setInvoiceData(res.data.data.data);
                    setPaginationData(res.data.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const paginateData = (link) => {
        setPaginationData("");
        setInvoiceData([]);
        setLoader(<Loader />);
        let url = `${link}&type=Refund Invoice&reference_number=${localStorage.getItem("reference") !== null
            ? localStorage.getItem("reference")
            : ""
            }&status=${localStorage.getItem("statusINV") !== null
                ? localStorage.getItem("statusINV")
                : ""
            }&veronica_status=${localStorage.getItem("statusVeronica") !== null
                ? localStorage.getItem("statusVeronica")
                : ""
            }&title=${localStorage.getItem("title") !== null
                ? localStorage.getItem("title")
                : ""
            }&client_name=${localStorage.getItem("clientName") !== null
                ? localStorage.getItem("clientName")
                : ""
            }&created_by_name=${localStorage.getItem("createdBy") !== null
                ? localStorage.getItem("createdBy")
                : ""
            }&amount=${localStorage.getItem("amount") !== null
                ? localStorage.getItem("amount")
                : ""
            }&date_start_date=${getFilterDate() && getFilterDate().from !== null
                ? getFilterDate().from
                : ""
            }&date_end_date=${getFilterDate() && getFilterDate().to !== null
                ? getFilterDate().to
                : ""
            }&sortBy=${localStorage.getItem("leadSort") !== null
                ? localStorage.getItem("leadSort")
                : ""
            }`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setInvoiceData(res.data.data.data);
                    setPaginationData(res.data.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const authorizeVeronica = (id) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/create-and-authorize-credit-note/${id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess("Invoice Authorized Successfully!");
                    getInvoiceData()
                } else {
                    if (res.data.data !== undefined) {
                        setError(res.data.data?.detalleError);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const cancelVeronica = (id) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/cancel-invoice/${id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess("Invoice Canceled Successfully!");
                    getInvoiceData()
                } else {
                    if (res.data.data !== undefined) {
                        setError(res.data.data?.detalleError);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const notifyVeronica = (id) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/invoice-notify/${id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess("Notified Successfully!");
                    getInvoiceData()
                } else {
                    if (res.data.result !== undefined) {
                        setError(res.data.result.messages[0]);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const downloadVeronica = (id, type, redirect) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/generate-invoice-pdf/${id}?type=${type}&logo=1`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    // responseType: "blob",
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    var url = res.data.data?.urlReporte;
                    window.open(url, "_blank");
                    // var blob = new Blob([res.data], {
                    //   type: "application/pdf",
                    // });
                    // if (redirect){
                    //   var url = window.URL.createObjectURL(blob);
                    //   window.open(url, "_blank");
                    // } else {
                    //   fileSaver.saveAs(blob, `INVOICE-${id}.${type}`);
                    //   setSuccess("Downloaded Successfully!");
                    // }
                    // setError("");
                } else {
                    if (res.data.data !== undefined) {
                        setError(res.data.data?.detalleError);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "client_name") {
            localStorage.setItem("clientName", e);
        }
        if (type == "title") {
            localStorage.setItem("title", e);
        }
        if (type == "created_by_name") {
            localStorage.setItem("createdBy", e);
        }
        if (type == "statusINV") {
            localStorage.setItem("statusINV", e);
        }
        if (type == "statusVeronica") {
            localStorage.setItem("statusVeronica", e);
        }
        if (type == "date") {
            setFilterDate(e);
        }
        if (type == "amount") {
            localStorage.setItem("amount", e);
        }
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoices?type=Refund Invoice&status=${localStorage.getItem("statusINV") !== null
                    ? localStorage.getItem("statusINV")
                    : ""
                }&veronica_status=${localStorage.getItem("statusVeronica") !== null
                    ? localStorage.getItem("statusVeronica")
                    : ""
                }&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&title=${localStorage.getItem("title") !== null
                    ? localStorage.getItem("title")
                    : ""
                }&client_name=${localStorage.getItem("clientName") !== null
                    ? localStorage.getItem("clientName")
                    : ""
                }&created_by_name=${localStorage.getItem("createdBy") !== null
                    ? localStorage.getItem("createdBy")
                    : ""
                }&amount=${localStorage.getItem("amount") !== null
                    ? localStorage.getItem("amount")
                    : ""
                }&start_date=${getFilterDate() && getFilterDate().from !== null
                    ? getFilterDate().from
                    : ""
                }&end_date=${getFilterDate() && getFilterDate().to !== null
                    ? getFilterDate().to
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setInvoiceData(res.data.data.data);
                    setPaginationData(res.data.data);
                    setRef_filter(false);
                    setCliName_filter(false);
                    setTitle_filter(false);
                    setdate_filter(false);
                    setCreatedBy_filter(false);
                    setAmount_filter(false);
                    setstatusINV_filter(false);
                    setstatusVeronica_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/export-receipt`,
                {
                    type: "Refund Invoice",
                    status: localStorage.getItem("statusINV") !== null
                        ? localStorage.getItem("statusINV")
                        : "",
                    veronica_status: localStorage.getItem("statusVeronica") !== null
                        ? localStorage.getItem("statusVeronica")
                        : "",
                    reference_number: localStorage.getItem("reference") !== null
                        ? localStorage.getItem("reference")
                        : "",
                    title: localStorage.getItem("title") !== null
                        ? localStorage.getItem("title")
                        : "",
                    client_name: localStorage.getItem("clientName") !== null
                        ? localStorage.getItem("clientName")
                        : "",
                    created_by_name: localStorage.getItem("createdBy") !== null
                        ? localStorage.getItem("createdBy")
                        : "",
                    amount: localStorage.getItem("amount") !== null
                        ? localStorage.getItem("amount")
                        : "",
                    date_start_date: getFilterDate() && getFilterDate().from !== null
                        ? getFilterDate().from
                        : "",
                    date_end_date: getFilterDate() && getFilterDate().to !== null
                        ? getFilterDate().to
                        : ""
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };

    const getDuplicate = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }

        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoices/duplicate_invoice`,
                {
                    id: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/invoicing-service/${res.data.data.id}`);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    const getSendEmail = () => {
        setLink("");
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        let type = selectedType[0];
        setIsOpenModal(true);
        const formData = new FormData();
        formData.append("format", mailPDFFormat);
        formData.append("template_id", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        // 'content-type': 'application/json',
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email])
                }
            });
    };

    const getSendEmail2 = () => {
        // setLink("");
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        let type = selectedType[0];
        const formData = new FormData();
        formData.append("send_to", email);
        formData.append("cc", email2);
        formData.append("body", messageData);
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                    setIsOpenModal(false);
                }, 3000);
            });
    };

    const getDefaultPdf = () => {
        setFormat("");
        setMailingFormat("");
        setPriceTax("");
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-default-pdf?type=Refund Invoice`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    //   setPriceTax()
                    //   setDefaultSend(res.data.data);
                    setFormat(res.data.data.format);
                    setMailingFormat(res.data.data.mailing_format);
                    setPriceTax(res.data.data.price_after_tax);
                    setProductService(res.data.data.include_main_image);
                    getTemplate(res.data.data.type);
                } else {
                    //   setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        let savedSearch = localStorage.getItem('search');
        if (savedSearch && savedSearch.split(',')[0] === 'refund-invoice') {
            setSearchValue(savedSearch.split(',')[1])
            getInvoiceData(savedSearch.split(',')[1])
        } else {
            getInvoiceData();
        }
    }, [])

    useEffect(() => {
        // getInvoiceData();
        getDefaultPdf();
        getMessage();
    }, []);

    const getTemplate = (type) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates?type=${type}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setTemplateData(res.data.templates);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getDownloadPDF = () => {
        setLink1("");
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        let type = selectedType[0];
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink1(res.data.url);
                    setIsDownloadModal(true);
                }
            });
    };

    const getDownloadPDF2 = () => {
        setLink1("");
        let ids = selectedIds[0];
        let type = selectedType[0];
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);
        formData.append("download", "1");

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    responseType: "arraybuffer",
                }
            )
            .then(function (res) {
                if (res.data) {
                    var blob = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    fileSaver.saveAs(blob, `REFUNDINVOICE${selectedNum[0]}.pdf`);
                }
                setIsDownloadModal(false);
            });
    };

    const getprint = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        let type = selectedType[0];
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };

    const getPrintOption = () => {
        setLink1("");
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        let type = selectedType[0];
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink1(res.data.url);
                    setIsPrintModal(true);
                }
            });
    };

    const getPrintOption2 = () => {
        setLink1("");
        let ids = selectedIds[0];
        let type = selectedType[0];
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", ids);
        formData.append("type", type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };

    const getMessage = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-message`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    const originalString = res.data.settings.email_configuration_client_emails_message
                    setMessageData(originalString.replace(/\n/g, '<br />'));
                    // const html =
                    //   res.data.settings.email_configuration_client_emails_message;
                } else {
                    setError(res.data.message);
                }
            });
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <React.Fragment>
            {isDownloadModal ? (
                <DownloadOption
                    isDownloadModal={isDownloadModal}
                    setIsDownloadModal={setIsDownloadModal}
                    link={link1}
                    setLink={setLink1}
                    type={selectedType[0]}
                    //   type={saleData.reference_type}
                    id={selectedIds[0]}
                    refNum={selectedNum[0]}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setSignedBox={setSignedBox}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getDownloadPDF={getDownloadPDF}
                    getDownloadPDF2={getDownloadPDF2}
                    templateData={templateData}
                />
            ) : (
                ""
            )}
            {isOpenModal ? (
                <SendEmail
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    link={link}
                    // setLink={setLink}
                    //   id={ids}
                    setEmail2={setEmail2}
                    setEmail={setEmail}
                    email={email}
                    email2={email2}
                    setFormat={setMailPDFFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setSignedBox={setSignedBox}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getSendEmail={getSendEmail}
                    getSendEmail2={getSendEmail2}
                    success={success}
                    error={error}
                    // emailMessage={emailMessage}
                    type={selectedType[0]}
                    // setEmailMessage={setEmailMessage}
                    messageData={messageData}
                    setMessageData={setMessageData}
                    format={mailPDFFormat}
                    mailingFormat={mailingFormat}
                    priceTax={priceTax}
                    productService={productService}
                    id={selectedIds[0]}
                />
            ) : (
                ""
            )}
            {
                format &&
                <PrintOption
                    isPrintModal={isPrintModal}
                    setIsPrintModal={setIsPrintModal}
                    link={link1}
                    setLink={setLink1}
                    type={selectedType[0]}
                    //   type={saleData.reference_type}
                    id={selectedIds[0]}
                    refNum={selectedNum[0]}
                    pdfType={'Refund Invoice'}
                    format={format}
                    template1={template1}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setSignedBox={setSignedBox}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getPrintOption={getPrintOption}
                    getPrintOption2={getPrintOption2}
                    templateData={templateData}
                />
            }
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />

            <div className="page-content">
                <MetaTags>
                    <title>Refund Invoices | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">{props.t("Refund Invoices")}</h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                value={searchValue}
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                        {getPermission().invoices.invoices.create.is_checked ===
                                            1 ? (
                                            <>
                                                <div className="btn-group me-2">
                                                    <MDBContainer>
                                                        <MDBDropdown>
                                                            <MDBDropdownToggle className="btn btn-primary">
                                                                {props.t("More")}
                                                            </MDBDropdownToggle>
                                                            <MDBDropdownMenu>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDownloadPDF()}
                                                                    >
                                                                        {props.t("Download as pdf")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    {
                                                                        format &&
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => getPrintOption()}
                                                                        >
                                                                            {props.t("Print")}
                                                                        </span>
                                                                    }
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDuplicate()}
                                                                    >
                                                                        {props.t("Duplicate")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getExport()}
                                                                    >
                                                                        {props.t("Export")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getSendEmail()}
                                                                    >
                                                                        {props.t("Send")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                            </MDBDropdownMenu>
                                                        </MDBDropdown>
                                                    </MDBContainer>
                                                </div>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => createNew()}
                                                >
                                                    {props.t("Create New")}
                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                    />
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Client")}
                                                        {cliName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCliName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("clientName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCliName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCliName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            cliName_filter={cliName_filter}
                                                            setCliName_filter={setCliName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Veronica Status")}
                                                        {statusVeronica_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setstatusVeronica_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("statusVeronica") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setstatusVeronica_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setstatusVeronica_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <MultipleChoice
                                                            statusVeronica_filter={statusVeronica_filter}
                                                            setstatusVeronica_filter={setstatusVeronica_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Created by")}
                                                        {createdby_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCreatedBy_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("createdBy") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCreatedBy_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCreatedBy_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            createdby_filter={createdby_filter}
                                                            setCreatedBy_filter={setCreatedBy_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Status")}
                                                        {statusINV_filter ? (
                                                            <>
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setstatusINV_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("statusINV") == "" ? (
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setstatusINV_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setstatusINV_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <MultipleChoice
                                                            statusINV_filter={statusINV_filter}
                                                            setstatusINV_filter={setstatusINV_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Date")}
                                                        {date_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdate_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {getFilterDate() &&
                                                                    getFilterDate().from == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setdate_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setdate_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <DateFilter
                                                            date_filter={date_filter}
                                                            setdate_filter={setdate_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Amount")}
                                                        {amount_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setAmount_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("amount") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setAmount_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setAmount_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            amount_filter={amount_filter}
                                                            setAmount_filter={setAmount_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Activity")}</th>
                                                <th>{props.t("Margin")}</th>
                                                <th>{props.t("Unpaid")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceData &&
                                                invoiceData.map((item, i) => (
                                                    <AllInvoices
                                                        key={i}
                                                        data={item}
                                                        selectedIds={selectedIds}
                                                        setSelectedIds={setSelectedIds}
                                                        setSelectedType={setSelectedType}
                                                        selectedType={selectedType}
                                                        setSelectedNum={setSelectedNum}
                                                        selectedNum={selectedNum}
                                                        authorizeVeronica={authorizeVeronica}
                                                        cancelVeronica={cancelVeronica}
                                                        // notifyVeronica={notifyVeronica}
                                                        setError={setError}
                                                        setSuccess={setSuccess}
                                                        downloadVeronica={downloadVeronica}
                                                        getInvoiceData={getInvoiceData}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                            </CardBody>
                        </Card>
                        {paginationData ? (
                            <Paginations data={paginationData} paginate={paginateData} />
                        ) : (
                            ""
                        )}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(RefundInvoices);
RefundInvoices.propTypes = {
    t: PropTypes.any,
};

import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    Alert,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import classnames from "classnames";
import uploadImg from "../../assets/images/pdf-file.png";
import Select from "react-select";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
let headings = [];

const ImportExport = (props) => {
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [error, setError] = useState("");
    const [referenceType, setReferenceType] = useState("");
    const [success, setSuccess] = useState("");
    const [allRates, setAllRates] = useState([]);
    const [importForm, setImportForm] = useState({
        information_type: "",
        reference: "",
        header_line: "0",
        format: "excel",
        rates: "",
        payment_option: "",
        payment_term: "0",
        agents: "0",
        subject_to_maintenance: "1",
        managed_stock: "1",
        taxs: "0",
        inactive: "1",
        promotional: "1",
    });
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataTax, setDataTax] = useState([]);
    const [defaultTax, setDefaultTax] = useState("");
    const [exportType, setExportType] = useState("");
    const [importFormatType, setFormatImportType] = useState("");
    const [formatType, setFormatType] = useState("excel");
    const [selectedCate, setSelectedCate] = useState("");
    const [selectedSupplier, setSelectedSupplier] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [selectedPro, setSelectedPro] = useState("");
    const [allProCategories, SetAllProCategories] = useState([]);
    const [selectedType, SetSelectedType] = useState("");
    const [paymentData, setPaymentData] = useState("");
    const [paymentTerm, setPaymentTerm] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [image, setImage] = useState("");
    const [isCancel, setIsCancel] = useState(true);
    const [isCancel2, setIsCancel2] = useState(true);
    const [allData, setAllData] = useState({
        column: [],
    });
    const [selectData, setSelectData] = useState({
        columns: [],
    });
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );

    const exportFile = () => {
        selectData.columns?.map((item) => {
            headings.push(item.name);
        });
        setLoading(true);
        setSuccess("Exporting Data...")
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/export/${exportType}`,
                {
                    format_type: formatType,
                    headings: headings,
                },
                {
                    headers: {
                        // "Content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    headings = [];
                    setError("");
                    setSuccess(res.data.message);
                    window.location.href = res.data.url;
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const importFile = () => {
        setLoading(true)
        setSuccess("Importing Data...")
        const formData = new FormData();
        formData.append("file", image);
        formData.append("format_type", importFormatType);
        if (selectedType === "client" || selectedType === "potential_client") {
            formData.append("reference", "CLI");
            formData.append("client_category", selectedCate);
            formData.append("rate", importForm.rates);
            formData.append("payment_option_id", importForm.payment_option);
            formData.append("payment_terms_id", importForm.payment_term);
            formData.append("agent", importForm.agents);
        } else if (selectedType === "suppliers") {
            formData.append("reference", "SUP");
            formData.append("supplier_category", selectedSupplier);
            formData.append("payment_option_id", importForm.payment_option);
            formData.append("payment_terms_id", importForm.payment_term);
            formData.append("agent", importForm.agents);
        } else if (selectedType === "products") {
            formData.append("reference", "PRO");
            formData.append("product_category_id", selectedPro);
            formData.append("tax", importForm.taxs);
            formData.append("manage_stock", importForm.managed_stock);
            formData.append("is_active", importForm.inactive);
            formData.append("is_promotional", importForm.promotional);
            formData.append("user_id", getCurrentUser().id);
        } else if (selectedType === "service") {
            formData.append("reference", "SER");
            formData.append("is_promotional", importForm.promotional);
            formData.append("is_active", importForm.inactive);
            formData.append("tax", importForm.taxs);
        } else if (selectedType === "assets") {
            formData.append("reference", "AST");
            formData.append(
                "subject_to_maintenance",
                importForm.subject_to_maintenance
            );
        } else {
            formData.append("reference", "CLI");
        }
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/import/${selectedType}`,
                formData,
                {
                    headers: {
                        // "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false)
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getColumns = (e) => {
        setAllData({ column: [] });
        setSelectData({ columns: [] });
        setExportType(e);
        setIsCancel2(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-columns/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllData({
                    column: res.data.columns?.map((item) => ({
                        name: item,
                    })),
                });
            });
    };

    function handleSelectedType(e) {
        SetSelectedType(e);
        if (e == "client" || e == "potential_client") {
            //   setIsCancel(true);
            CategoriesClient();
            paymentOptions();
            getAllRates();
            getAllPayment();
        } else if (e == "suppliers") {
            CategoriesClient();
            paymentOptions();
            getAllPayment();
        } else if (e == "products") {
            productCategories();
            getTaxes();
        } else if (e == "service") {
            getTaxes();
        }
    }

    const CategoriesClient = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.client_categories);
            });
    };

    const strUpr = (str) => {
        var i,
            frags = str.split("_");
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
    };

    const getAllRates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAllRates(res.data.rates);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getType = (e) => {
        const { name, value } = e.target;
        setImportForm((prevData) => ({ ...prevData, [name]: value }));
        setReferenceType(e.target.value);
    };

    const handleForm = (e) => {
        const { name, value } = e.target;
        setImportForm((prevData) => ({ ...prevData, [name]: value }));
    };

    const setFile = (e) => {
        setIsCancel(true);
        setImage(e.target.files[0]);
        //   setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const getAllPayment = () => {
        setPaymentData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-terms`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setPaymentTerm(res.data.payment_terms);
                }
            });
    };

    const paymentOptions = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                SetAllProCategories(res.data.product_categories);
            });
    };

    const getTaxes = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataTax(res.data.taxes);
                }
            });
    };

    //   useEffect(() => {
    //     getSalesData();
    //   getAllRates();
    //   CategoriesClient();
    //   newpaymentShow();
    //   productCategories();
    //   getTaxes()
    //   }, []);

    function handleSelectCategory(selectedcate) {
        setSelectedCate(selectedcate.value);
    }

    function handleSupplierCategory(selectedSupplier) {
        setSelectedSupplier(selectedSupplier.value);
    }

    function handleProductCategory(selectedPro) {
        setSelectedPro(selectedPro.value);
    }

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const removeFields = (i) => {
        let column = allData.column;
        setLoader(true);
        setAllData({ column: [] });
        const removedColumn = column[i];
        const updatedColumns = [...selectData.columns, removedColumn];

        if (removedColumn.name === 'manage_stock') {
            updatedColumns.push({ name: 'Qty' });
        }

        setSelectData({ columns: updatedColumns });

        // setSelectData({ columns: [...selectData.columns, column[i]] });
        column.splice(i, 1);
        setTimeout(() => {
            setAllData({ column });
            setLoader(false);
        }, 200);
    };

    const removeFields2 = (i) => {
        let columns = selectData.columns;
        setLoader2(true);
        setSelectData({ column: [] });
        if (columns[i].name !== 'Qty') {
            setAllData({ column: [...allData.column, columns[i]] });
        }

        if (columns[i].name === 'manage_stock') {
            const filteredColumns = selectData.columns.filter(
                (col) => col.name !== 'manage_stock' && col.name !== 'Qty'
            );
            columns = filteredColumns;
        } else {
            columns.splice(i, 1);
        }

        setTimeout(() => {
            setSelectData({ columns });
            setLoader2(false);
        }, 200);
    };

    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }

    let productCategory = [];
    if (allProCategories !== undefined) {
        {
            allProCategories &&
                allProCategories.map((category) => {
                    productCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }
    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
            <MetaTags>
                <title>Import Export | Clouget</title>
            </MetaTags>
            {/* <Container fluid className=""> */}
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    // importFile(e, v);
                }}
            >
                <div className="page-title-box">
                    <Row className="align-items-center">
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="float-end d-flex">
                                {/* <Link to="/company" className="btn btn-primary float-end">
                      {props.t("Back")}
                    </Link> */}
                                {customActiveTab === "1" ? (
                                    <>
                                        {nonActive ? (
                                            <button
                                                type="button"
                                                className="btn btn-primary ms-2"
                                                onClick={() => importFile()}
                                                disabled
                                            >
                                                {props.t("Import")}
                                            </button>
                                        ) : (
                                            loading ?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary ms-2 "
                                                    onClick={() => importFile()}
                                                    disabled
                                                >
                                                    {" "}
                                                    {props.t("Importing")}
                                                </button>
                                                :
                                                <button
                                                    type="button"
                                                    className="btn btn-primary ms-2 "
                                                    onClick={() => importFile()}
                                                    disabled={isCancel ? false : true}
                                                >
                                                    {" "}
                                                    {props.t("Import")}
                                                </button>
                                        )}
                                        <button
                                            className="btn btn-primary ms-2 "
                                            onClick={() => {
                                                setIsCancel(false), setImage(""), SetSelectedType("");
                                            }}
                                            type="button"
                                            disabled={isCancel ? false : true}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {nonActive ? (
                                            <button
                                                className="btn btn-primary ms-2"
                                                onClick={() => exportFile()}
                                                type="button"
                                                disabled
                                            >
                                                {props.t("Export")}
                                            </button>
                                        ) : (
                                            loading ?
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    onClick={() => exportFile()}
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Exporting")}
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    onClick={() => exportFile()}
                                                    type="button"
                                                    disabled={isCancel2 ? false : true}
                                                >
                                                    {props.t("Export")}
                                                </button>
                                        )}
                                        <button
                                            className="btn btn-primary ms-2 "
                                            onClick={() => {
                                                setIsCancel2(false),
                                                    setAllData({ column: [] }),
                                                    setSelectData({ columns: [] }),
                                                    setExportType("");
                                            }}
                                            type="button"
                                            disabled={isCancel2 ? false : true}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
                {success ? <Alert color="success">{props.t(success)}</Alert> : null}
                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                <Card>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    <span className="d-none d-sm-block nav-item">
                                        {props.t("Import From File")}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    <span className="d-none d-sm-block nav-item">
                                        {props.t("Export To File")}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={customActiveTab} className="p-3">
                            <TabPane tabId="1">
                                <Row>
                                    <Col md={4} className="p-5">
                                        <div className="mb-3 required">
                                            <label>{props.t("Information type")}:</label>
                                            <AvField
                                                type="select"
                                                name="information_type"
                                                value={selectedType}
                                                onChange={(e) => handleSelectedType(e.target.value)}
                                            // required="required"
                                            >
                                                <option value="" hidden>
                                                    {props.t("Select")}
                                                </option>
                                                <option value="client">{props.t("Clients")}</option>
                                                <option value="potential_client">
                                                    {props.t("Potential Clients")}
                                                </option>
                                                <option value="suppliers">
                                                    {props.t("Suppliers")}
                                                </option>
                                                <option value="products">{props.t("Products")}</option>
                                                <option value="service">{props.t("Services")}</option>
                                                <option value="assets">{props.t("Assets")}</option>
                                            </AvField>
                                        </div>
                                        <div className="mb-3">
                                            <label>{props.t("Reference")}:</label>
                                            <AvField
                                                type="select"
                                                name="reference"
                                                //   value="se"
                                                onChange={(e) => getType(e)}
                                            >
                                                <option value="" hidden>
                                                    {props.t("Select")}
                                                </option>
                                                {selectedType == "client" ? (
                                                    <>
                                                        {" "}
                                                        <option value="CLI">CLI</option>
                                                        <option value="manual">MANUAL</option>
                                                    </>
                                                ) : selectedType == "suppliers" ? (
                                                    <>
                                                        <option value="SUP">SUP</option>
                                                        <option value="manual">MANUAL</option>
                                                    </>
                                                ) : selectedType == "potential_client" ? (
                                                    <option value="CLI">PCL</option>
                                                ) : selectedType == "products" ? (
                                                    <>
                                                        <option value="PRO">PRO/product</option>
                                                        <option value="manual">MANUAL/MANUAL</option>
                                                    </>
                                                ) : selectedType == "assets" ? (
                                                    <option value="AST">AST</option>
                                                ) : selectedType == "service" ? (
                                                    <>
                                                        <option value="SER">SER</option>
                                                        <option value="manual">MANUAL</option>
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="">--</option>
                                                    </>
                                                )}
                                            </AvField>
                                        </div>
                                        <div className="mb-3">
                                            <label>{props.t("Header Lines")}:</label>
                                            <AvField
                                                type="select"
                                                name="header_line"
                                                onChange={(e) => handleForm(e)}
                                            >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                            </AvField>
                                        </div>
                                        <div className="mb-3">
                                            <label>{props.t("Format")}:</label>
                                            <AvField
                                                type="select"
                                                name="format"
                                                onChange={(e) => {
                                                    setFormatImportType(e.target.value), handleForm(e);
                                                }}
                                            // value="se"
                                            >
                                                <option value="excel">Excel</option>
                                                <option value="csv">CSV</option>
                                            </AvField>
                                        </div>

                                        <div className="my-3">
                                            <span>{props.t("Additional Information")}:</span>
                                        </div>
                                        {selectedType == "client" ||
                                            selectedType == "potential_client" ||
                                            selectedType == "suppliers" ? (
                                            <>
                                                {selectedType == "suppliers" ? (
                                                    <>
                                                        <label>{props.t("Supplier Category")}</label>
                                                        <div className="w-100 mb-3">
                                                            <Select
                                                                options={optionCategory}
                                                                onChange={handleSupplierCategory}
                                                                name="supplier_category"
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label>{props.t("Client Category")}</label>
                                                        <div className="w-100 mb-3">
                                                            <Select
                                                                options={optionCategory}
                                                                onChange={handleSelectCategory}
                                                                name="client_category"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                label={props.t("Rate")}
                                                                type="select"
                                                                name="rates"
                                                                onChange={(e) => handleForm(e)}
                                                            >
                                                                <option value="" hidden>
                                                                    {props.t("Select")}
                                                                </option>
                                                                {allRates?.map((item, i) => (
                                                                    <option value={item.name} key={i}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </AvField>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="mb-3">
                                                    <label>{props.t("Payment Option")}</label>
                                                    <div>
                                                        {paymentLoader ? (
                                                            <div>
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                ></Spinner>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="w-100 mb-3">
                                                            <AvField
                                                                type="select"
                                                                name="payment_option"
                                                                onChange={(e) => handleForm(e)}
                                                            >
                                                                <option value="" hidden>
                                                                    {props.t("Select")}
                                                                </option>
                                                                {paymentData &&
                                                                    paymentData.map((item, i) => (
                                                                        <option
                                                                            value={item.id}
                                                                            key={i}
                                                                        // label={item.name}
                                                                        >
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Payment Term")}
                                                        type="select"
                                                        name="payment_term"
                                                        onChange={(e) => handleForm(e)}
                                                    >
                                                        <option value="0">
                                                            {props.t("Immediate Payment")}
                                                        </option>
                                                        {paymentTerm &&
                                                            paymentTerm.map((item) => (
                                                                <option value={item.id} key={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Agent")}
                                                        type="select"
                                                        name="agents"
                                                        onChange={(e) => handleForm(e)}
                                                    >
                                                        <option value="0">{props.t("Unassigned")}</option>
                                                        <option value="1">{getCurrentUser().email}</option>
                                                    </AvField>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {selectedType == "assets" ? (
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Subject to Maintenance")}
                                                            type="select"
                                                            name="subject_to_maintenance"
                                                            onChange={(e) => handleForm(e)}
                                                        >
                                                            <option value="1">{props.t("Yes")}</option>
                                                            <option value="0">{props.t("No")}</option>
                                                        </AvField>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {selectedType == "products" ? (
                                                            <>
                                                                <div className="mb-3">
                                                                    <label>{props.t("Product Category")}</label>
                                                                    <div className="w-100">
                                                                        <Select
                                                                            options={productCategory}
                                                                            onChange={handleProductCategory}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        label={props.t("Managed Stock")}
                                                                        type="select"
                                                                        name="managed_stock"
                                                                        onChange={(e) => handleForm(e)}
                                                                    >
                                                                        <option value="1">{props.t("Yes")}</option>
                                                                        <option value="0">{props.t("No")}</option>
                                                                    </AvField>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="form-control"
                                                                type="select"
                                                                name="taxs"
                                                                label={props.t("Tax")}
                                                                onChange={(e) => handleForm(e)}
                                                                value={defaultTax}
                                                            >
                                                                {dataTax.taxes?.map((item, i) => (
                                                                    <option value={item.tax} key={i}>
                                                                        {dataTax.primary_name} {item.tax}%
                                                                    </option>
                                                                ))}
                                                                <option value="0">
                                                                    {props.t("Do not apply")}
                                                                </option>
                                                            </AvField>
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                label={props.t("Inactive")}
                                                                type="select"
                                                                name="inactive"
                                                                onChange={(e) => handleForm(e)}
                                                            >
                                                                <option value="1">{props.t("Yes")}</option>
                                                                <option value="0">{props.t("No")}</option>
                                                            </AvField>
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                label={props.t("Promotional")}
                                                                type="select"
                                                                name="promotional"
                                                                onChange={(e) => handleForm(e)}
                                                            >
                                                                <option value="1">{props.t("Yes")}</option>
                                                                <option value="0">{props.t("No")}</option>
                                                            </AvField>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Col>
                                    <Col md={4}>
                                        <div className="company_img2 p-5 ">
                                            <div className="required">
                                                <label>
                                                    {image ? image.name : `${props.t("Select a File")}`}
                                                </label>
                                            </div>
                                            <input
                                                onChange={(e) => setFile(e)}
                                                type="file"
                                                accept=".xlsx, .xls, .csv"
                                                id="comImg"
                                                name="file"
                                            />
                                            {/* <label className="company_img-box2">
                        <img src={imgPreview} />
                      </label> */}
                                        </div>
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col md={4} className="p-5">
                                        <div className="mb-3 required">
                                            <label>{props.t("Information type")}</label>
                                            <AvField
                                                type="select"
                                                name="information_type"
                                                placeholder="select a type"
                                                value={exportType}
                                                onChange={(e) => getColumns(e.target.value)}
                                            // required="required"
                                            >
                                                <option value="" hidden>
                                                    {props.t("Select")}
                                                </option>
                                                <option value="client">{props.t("Clients")}</option>
                                                <option value="potential_client">
                                                    Potential Clients
                                                </option>
                                                <option value="suppliers">
                                                    {props.t("Suppliers")}
                                                </option>
                                                <option value="products">{props.t("Products")}</option>
                                                <option value="service">{props.t("Services")}</option>
                                                <option value="assets">{props.t("Assets")}</option>
                                            </AvField>
                                        </div>
                                        <div className="mb-3">
                                            <label>{props.t("Format")}</label>
                                            <AvField
                                                type="select"
                                                name="format"
                                                //   value="type"
                                                onChange={(e) => setFormatType(e.target.value)}
                                            >
                                                <option value="excel">Excel</option>
                                                <option value="csv">CSV</option>
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col md={4} className="pt-5">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <button
                                                                    className="border border-secondary btn-secondary mb-1 pe-none required"
                                                                    style={{ width: "200px" }}
                                                                >
                                                                    {props.t("Available Data")}
                                                                </button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loader ? (
                                                            <div>
                                                                <Spinner
                                                                    animation="grow"
                                                                    role="status"
                                                                    size="sm"
                                                                //   className="ms-7"
                                                                ></Spinner>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {allData.column?.map((item, i) =>
                                                                    item.name == "tin" ? (
                                                                        ""
                                                                    ) : (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-secondary mb-1 "
                                                                                    style={{
                                                                                        width: "200px",
                                                                                    }}
                                                                                    onClick={() => removeFields(i)}
                                                                                >
                                                                                    {strUpr(item.name)}
                                                                                    <i className="fa fa-caret-right float-end"></i>
                                                                                </button>{" "}
                                                                                <br />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Col>
                                            <Col md={4} className="pt-5">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <button
                                                                    className="border border-secondary btn-secondary mb-1 pe-none"
                                                                    style={{ width: "200px" }}
                                                                >
                                                                    {props.t("Selected Data")}
                                                                </button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loader2 ? (
                                                            <div>
                                                                <Spinner
                                                                    animation="grow"
                                                                    role="status"
                                                                    size="sm"
                                                                //   className="ms-7"
                                                                ></Spinner>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {selectData.columns?.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <button
                                                                                className="btn btn-secondary mb-1"
                                                                                style={{ width: "200px" }}
                                                                                onClick={() => removeFields2(i)}
                                                                            >
                                                                                <i className="fa fa-caret-left float-start"></i>
                                                                                {strUpr(item.name)}
                                                                            </button>{" "}
                                                                            <br />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </AvForm>
            {/* </Container>
      </div> */}
        </React.Fragment>
    );
};
export default withTranslation()(ImportExport);
ImportExport.propTypes = {
    t: PropTypes.any,
};

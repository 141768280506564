import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import { useParams, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CreateTrialModal from "../plans/trialModal";
let selectedRoleId = [];

const SingleRole = (props) => {
    const { id } = useParams();
    const [singleRoleId] = useState(parseInt(id, 10));
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(<Loader />);
    const [error, setError] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [success, setSuccess] = useState("");
    const [role, setRole] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [allPermissionId, setAllPermissionId] = useState(281);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const getSingleRole = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles/${singleRoleId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setRole(res.data.role);
                    setError("");
                    setPermissions(res.data.permissions);
                    res.data.selected_permissions &&
                        res.data.selected_permissions.map((item) => {
                            selectedRoleId.push(parseInt(item));
                        });
                } else {
                    setError(res.data.message);
                }
                setLoader("");
                setTimeout(() => { }, 5000);
            });
    };

    const getAllPermissions = () => {
        setLoader(<Loader />);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/permissions`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                getSingleRole();
                if (res.data.status) {
                    setError("");
                    setPermissions(res.data.permissions);
                    const id = findIdByName(res.data.permissions, "All permissions");
                    console.log(id, '====');

                    setAllPermissionId(id);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const findIdByName = (data, targetName) => {
        for (const item of data) {
            if (item.name === targetName) {
                return item.id;
            }
            if (item.children.length > 0) {
                const childResult = findIdByName(item.children, targetName);
                if (childResult) return childResult;
            }
        }
        return null;
    };

    const submitRole = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles/${singleRoleId}?_method=PUT`,
                {
                    name: value.name,
                    permissions: selectedRoleId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const getSelectedId = (e, check) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
            checkAllPermissionChecked();
        } else {
            selectedRoleId = selectedRoleId.filter(item => item != e);
            const staticCheckbox = document.querySelector(`input[type="checkbox"][value="${allPermissionId}"]`);
            if (staticCheckbox) {
                staticCheckbox.checked = false;
                selectedRoleId = selectedRoleId.filter(item => item != allPermissionId);
            }
        }
        // console.log(selectedRoleId, "sdssvd");
    };
    const selectAllCheck = (check) => {
        if (check.checked) {
            selectedRoleId = [];
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedRoleId.push(ele[i].value);
                    const uniqueID = selectedRoleId.filter((val, id, array) => {
                        return array.indexOf(val) == id;
                    });
                    selectedRoleId = uniqueID;
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedRoleId;
                    var index = array.indexOf(elem[j].value);
                    array.splice(index, 1);
                }
            }
            selectedRoleId = [];
        }
        console.log(selectedRoleId, "selectedAll");
    };

    const checkAllPermissionChecked = () => {
        var ele = document.getElementsByName("chk");
        var bAllPermission = true;
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox" && ele[i].value != allPermissionId && ele[i].value > 21 && ele[i].checked != true) {
                bAllPermission = false;
            }
        }
        if (bAllPermission) {
            const staticCheckbox = document.querySelector(`input[type="checkbox"][value="${allPermissionId}"]`);
            if (staticCheckbox) {
                staticCheckbox.checked = true;
                selectedRoleId.push(allPermissionId);
            }
        } else {
            selectedRoleId = selectedRoleId.filter(item => item != allPermissionId);
        }
    }

    const selectByBatch = (e) => {
        let batchIdMain = e.target.id;
        let batchIdValue = e.target.value;
        let batchIdFormated = batchIdMain.toLowerCase();
        let batchId = batchIdFormated.replace(/\s/g, "");

        if (e.target.checked) {
            var ele = document.getElementsByClassName(batchId);
            selectedRoleId.push(batchIdValue);
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedRoleId.push(ele[i].value);
                    const uniqueID = selectedRoleId.filter((val, id, array) => 
                        array.findIndex(v => v.toString() === val.toString()) === id
                    );
                    selectedRoleId = uniqueID;
                }
            }
            checkAllPermissionChecked();
        } else {
            selectedRoleId = selectedRoleId.filter(item => item != batchIdValue);
            var eleme = document.getElementsByClassName(batchId);
            for (var k = 0; k < eleme.length; k++) {
                if (eleme[k].type == "checkbox") {
                    eleme[k].checked = false;
                    var array = selectedRoleId;
                    var index = array.indexOf(eleme[k].value);
                    // if (index !== -1) {
                    array.splice(index, 1);
                    // }
                }
            }
            const staticCheckbox = document.querySelector(`input[type="checkbox"][value="${allPermissionId}"]`);
            if (staticCheckbox) {
                staticCheckbox.checked = false;
                selectedRoleId = selectedRoleId.filter(item => item != allPermissionId);
            }
        }
    };

    useEffect(() => {
        getAllPermissions();
    }, []);

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <div className="page-content">
                <MetaTags>
                    <title>New Role | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                submitRole(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{role && role.name}</h6>
                                    </Col>
                                    <Col md={6}>
                                        {/* <div className="float-end d-none d-md-block fixed-top"> */}
                                        <div className="float-end d-flex">
                                            <Link
                                                type="button"
                                                to="/company"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {getPermission().roles.roles.create.is_checked === 1 ? (
                                                isEditable ? (
                                                    <>
                                                        {loading ? (
                                                            <button
                                                                className="btn btn-primary ms-2 disabled"
                                                                type="button"
                                                                disabled
                                                            >
                                                                {props.t("Loading")}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary ms-2"
                                                                type="submit"
                                                            >
                                                                {props.t("Update")}
                                                            </button>
                                                        )}
                                                        <span
                                                            onClick={() => setIsEditable(false)}
                                                            className="btn btn-primary  ms-2"
                                                        >
                                                            {props.t("Cancel")}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span
                                                        className="btn btn-primary ms-2"
                                                        onClick={() => createNew()}
                                                    >
                                                        {props.t("Edit")}
                                                    </span>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    {loader !== "" ? (
                                        loader
                                    ) : (
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3 required">
                                                    <AvField
                                                        label={props.t("Role Name")}
                                                        type="text"
                                                        name="name"
                                                        value={role.name}
                                                        readOnly={isEditable ? "" : true}
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    {permissions &&
                                                        permissions.map((item, i) => (
                                                            <div className="row border-bottom py-4" key={i}>
                                                                <div className="col-md-3">
                                                                    <label>
                                                                        {item.name !== "Permissions" ? (
                                                                            item.name !== "Visible Roles" ? (
                                                                                <input
                                                                                    className={`all_permission p-0 d-inline-block`}
                                                                                    type="checkbox"
                                                                                    name="chk"
                                                                                    value={item.id}
                                                                                    id={item.name}
                                                                                    defaultChecked={
                                                                                        item.is_checked == "yes"
                                                                                    }
                                                                                    disabled={isEditable ? "" : true}
                                                                                    onClick={(e) => selectByBatch(e)}
                                                                                />
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                        {props.t(item.name)}
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="row">
                                                                        <div className="col-md-7"></div>
                                                                        <div className="col-md-5">
                                                                            {item.children[0] &&
                                                                                item.children[0].children.length !== 0 ? (
                                                                                <>
                                                                                    <table className="table fixed-width">
                                                                                        <tr>
                                                                                            <th>{props.t('View')}</th>
                                                                                            <th>{props.t('Edit')}</th>
                                                                                            <th>{props.t('Create')}</th>
                                                                                            <th>{props.t('Delete')}</th>
                                                                                            <th>
                                                                                                {item.name.indexOf(
                                                                                                    "Connect"
                                                                                                ) === 0
                                                                                                    ? "Send"
                                                                                                    : ""}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </table>
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {item.children &&
                                                                        item.children.map((child, i) => (
                                                                            <div className="row" key={i}>
                                                                                <div className="col-md-7">
                                                                                    {child.is_checkbox !== 0 ? (
                                                                                        <>
                                                                                            {child.name ===
                                                                                                "All permissions" ? (
                                                                                                <>
                                                                                                    <input
                                                                                                        className={`all_permission p-0 d-inline-block ${item.name
                                                                                                            .replace(/\s/g, "")
                                                                                                            .toLowerCase()}`}
                                                                                                        type="checkbox"
                                                                                                        defaultChecked={
                                                                                                            child.is_checked === "yes"
                                                                                                                ? "checked"
                                                                                                                : ""
                                                                                                        }
                                                                                                        onClick={(e) =>
                                                                                                            selectAllCheck(e.target)
                                                                                                        }
                                                                                                        disabled={
                                                                                                            isEditable ? "" : true
                                                                                                        }
                                                                                                        value={child.id}
                                                                                                        name="chk"
                                                                                                    />
                                                                                                    &nbsp; {props.t(child.name)}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {child.name === role.name ? (
                                                                                                        <>
                                                                                                            <input
                                                                                                                onChange={(e) =>
                                                                                                                    getSelectedId(
                                                                                                                        child.id,
                                                                                                                        e
                                                                                                                    )
                                                                                                                }
                                                                                                                className={`${item.name ===
                                                                                                                    "Visible Roles"
                                                                                                                    ? "visible_role"
                                                                                                                    : ""
                                                                                                                    } p-0 d-inline-block ${item.name
                                                                                                                        .replace(/\s/g, "")
                                                                                                                        .toLowerCase()}`}
                                                                                                                name={
                                                                                                                    item.name ===
                                                                                                                        "Visible Roles"
                                                                                                                        ? "visible_role"
                                                                                                                        : "chk"
                                                                                                                }
                                                                                                                value={child.id}
                                                                                                                type="checkbox"
                                                                                                                defaultChecked={
                                                                                                                    child.is_checked ===
                                                                                                                        "yes"
                                                                                                                        ? "checked"
                                                                                                                        : ""
                                                                                                                }
                                                                                                                disabled={
                                                                                                                    isEditable ? "" : true
                                                                                                                }
                                                                                                            />
                                                                                                            &nbsp; {props.t(child.name)}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <input
                                                                                                                onChange={(e) =>
                                                                                                                    getSelectedId(
                                                                                                                        child.id,
                                                                                                                        e
                                                                                                                    )
                                                                                                                }
                                                                                                                className={`
                                                         p-0 d-inline-block ${item.name
                                                                                                                        .replace(/\s/g, "")
                                                                                                                        .toLowerCase()}`}
                                                                                                                name={
                                                                                                                    item.name ===
                                                                                                                        "Visible Roles"
                                                                                                                        ? "visible_role"
                                                                                                                        : "chk"
                                                                                                                }
                                                                                                                value={child.id}
                                                                                                                type="checkbox"
                                                                                                                defaultChecked={
                                                                                                                    child.is_checked ===
                                                                                                                        "yes"
                                                                                                                        ? "checked"
                                                                                                                        : ""
                                                                                                                }
                                                                                                                disabled={
                                                                                                                    isEditable
                                                                                                                        ? item.name !==
                                                                                                                            "Visible Roles"
                                                                                                                            ? ""
                                                                                                                            : true
                                                                                                                        : true
                                                                                                                }
                                                                                                            />
                                                                                                            &nbsp; {props.t(child.name)}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <label>{props.t(child.name)}</label>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <table className="table p-0 m-0 fixed-width">
                                                                                        <tr>
                                                                                            <td className="text-center p-0">
                                                                                                {child.children &&
                                                                                                    child.children.map(
                                                                                                        (option) => (
                                                                                                            <>
                                                                                                                {option.name.indexOf(
                                                                                                                    "view"
                                                                                                                ) === 0 ? (
                                                                                                                    <input
                                                                                                                        onChange={(e) =>
                                                                                                                            getSelectedId(
                                                                                                                                option.id,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        className={`p-0 d-inline-block ${item.name
                                                                                                                            .replace(
                                                                                                                                /\s/g,
                                                                                                                                ""
                                                                                                                            )
                                                                                                                            .toLowerCase()}`}
                                                                                                                        name="chk"
                                                                                                                        value={option.id}
                                                                                                                        type="checkbox"
                                                                                                                        defaultChecked={
                                                                                                                            option.is_checked ==
                                                                                                                            "yes"
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            isEditable
                                                                                                                                ? ""
                                                                                                                                : true
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                            </td>
                                                                                            <td className="text-center p-0">
                                                                                                {child.children &&
                                                                                                    child.children.map(
                                                                                                        (option) => (
                                                                                                            <>
                                                                                                                {option.name.indexOf(
                                                                                                                    "edit"
                                                                                                                ) === 0 ? (
                                                                                                                    <input
                                                                                                                        onChange={(e) =>
                                                                                                                            getSelectedId(
                                                                                                                                option.id,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        className={`p-0 d-inline-block ${item.name
                                                                                                                            .replace(
                                                                                                                                /\s/g,
                                                                                                                                ""
                                                                                                                            )
                                                                                                                            .toLowerCase()}`}
                                                                                                                        name="chk"
                                                                                                                        value={option.id}
                                                                                                                        type="checkbox"
                                                                                                                        defaultChecked={
                                                                                                                            option.is_checked ==
                                                                                                                            "yes"
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            isEditable
                                                                                                                                ? ""
                                                                                                                                : true
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                            </td>
                                                                                            <td className="text-center p-0">
                                                                                                {child.children &&
                                                                                                    child.children.map(
                                                                                                        (option) => (
                                                                                                            <>
                                                                                                                {option.name.indexOf(
                                                                                                                    "create"
                                                                                                                ) === 0 ? (
                                                                                                                    <input
                                                                                                                        onChange={(e) =>
                                                                                                                            getSelectedId(
                                                                                                                                option.id,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        className={`p-0 d-inline-block ${item.name
                                                                                                                            .replace(
                                                                                                                                /\s/g,
                                                                                                                                ""
                                                                                                                            )
                                                                                                                            .toLowerCase()}`}
                                                                                                                        name="chk"
                                                                                                                        value={option.id}
                                                                                                                        type="checkbox"
                                                                                                                        defaultChecked={
                                                                                                                            option.is_checked ==
                                                                                                                            "yes"
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            isEditable
                                                                                                                                ? ""
                                                                                                                                : true
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                            </td>
                                                                                            <td className="text-center p-0">
                                                                                                {child.children &&
                                                                                                    child.children.map(
                                                                                                        (option) => (
                                                                                                            <>
                                                                                                                {option.name.indexOf(
                                                                                                                    "delete"
                                                                                                                ) === 0 ? (
                                                                                                                    <input
                                                                                                                        onChange={(e) =>
                                                                                                                            getSelectedId(
                                                                                                                                option.id,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        className={`p-0 d-inline-block ${item.name
                                                                                                                            .replace(
                                                                                                                                /\s/g,
                                                                                                                                ""
                                                                                                                            )
                                                                                                                            .toLowerCase()}`}
                                                                                                                        name="chk"
                                                                                                                        value={option.id}
                                                                                                                        type="checkbox"
                                                                                                                        defaultChecked={
                                                                                                                            option.is_checked ==
                                                                                                                            "yes"
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            isEditable
                                                                                                                                ? ""
                                                                                                                                : true
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                            </td>
                                                                                            <td className="text-center p-0">
                                                                                                {child.children &&
                                                                                                    child.children.map(
                                                                                                        (option) => (
                                                                                                            <>
                                                                                                                {option.name.indexOf(
                                                                                                                    "send"
                                                                                                                ) === 0 ? (
                                                                                                                    <input
                                                                                                                        onChange={(e) =>
                                                                                                                            getSelectedId(
                                                                                                                                option.id,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        className={`p-0 d-inline-block ${item.name
                                                                                                                            .replace(
                                                                                                                                /\s/g,
                                                                                                                                ""
                                                                                                                            )
                                                                                                                            .toLowerCase()}`}
                                                                                                                        name="chk"
                                                                                                                        value={option.id}
                                                                                                                        type="checkbox"
                                                                                                                        defaultChecked={
                                                                                                                            option.is_checked ==
                                                                                                                            "yes"
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            isEditable
                                                                                                                                ? ""
                                                                                                                                : true
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(SingleRole);
SingleRole.propTypes = {
    t: PropTypes.any,
};

import React, { useState, useEffect } from "react";
import { Container, Alert, CardBody, Card, Spinner } from "reactstrap";
import MetaTags from "react-meta-tags";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
const myMembership = (props) => {
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirm_alert2, setconfirm_alert2] = useState(false);
  const [subs, setSubs] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [status, setStatus] = useState(getCurrentUser().subscription_status);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const getInvoices = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/get-purchased-subscriptions`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setSubs(res.data.data);
        }
      });
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/get-invoices`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setInvoices(res.data.data.data);
        }
      });
  };
  const refresh = () => window.location.reload(true);
  const getCancel = (status) => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/cancel-subscription?status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError(res.data.message);
          setCurrentUser(res.data.data);
          localStorage.setItem("plan_data", JSON.stringify(res.data.data));
          setLoader(false);
          window.location.reload();
        }
      });
  };
  useEffect(() => {
    getInvoices();
  }, []);
  return (
    <>
      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText="Yes, cancel it!"
          warning
          showCancel
          confirmButtonText="Yes, cancel it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            getCancel("cancelled"), setconfirm_alert(false);
          }}
          onCancel={() => setconfirm_alert(false)}
        ></SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText="Yes, confirm it!"
          warning
          showCancel
          confirmButtonText="Yes, confirm it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            getCancel("active"), setconfirm_alert2(false);
          }}
          onCancel={() => setconfirm_alert2(false)}
        ></SweetAlert>
      ) : null}
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>My Membership | Clouget</title>
          </MetaTags>
          <Container className="mt-4">
            <Card>
              <CardBody>
                {error ? <Alert color="danger">{error}</Alert> : null}
                <h5 className="page-title">{props.t("My Membership")}</h5>
                {loader ? (
                  <Spinner />
                ) : (
                  <div className="table-responsive">
                    <table className="table mt-2">
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th>{props.t("Membership")}</th>
                          <th>{props.t("Status")}</th>
                          <th>{props.t("Billing")}</th>
                          <th>{props.t("Expiration  date")}</th>
                          <td>
                            <Link
                              to="/plans"
                              type="button"
                              className="btn btn-primary float-end"
                            >
                              {props.t("Extend Membership")}
                            </Link>
                          </td>
                          <td>
                            {status === "active" ? (
                              <span
                                className="btn btn-danger"
                                onClick={() => {
                                  setconfirm_alert(true);
                                }}
                              >
                                {props.t("Cancel Subscription")}
                              </span>
                            ) : getCurrentUser().subscription_status ===
                                "cancelled" &&
                              getCurrentUser().is_subscription_active === 1 ? (
                              <span
                                className="btn btn-success"
                                onClick={() => {
                                  setconfirm_alert2(true);
                                }}
                              >
                                {props.t("Resume Subscription")}
                              </span>
                            ) : (
                              <Link
                                to="/plans"
                                type="button"
                                className="btn btn-primary"
                              >
                                {props.t("Active Membership")}
                              </Link>
                            )}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {JSON.parse(localStorage.getItem("plan_data"))
                          ?.stripe_subscription_id == null ? (
                          <tr>
                            <td>{props.t("Trial session")}</td>
                            <td>
                              {status == "active" ? (
                                <span className="badge bg-success">
                                  {props.t("Active")}
                                </span>
                              ) : status == "cancelled" ? (
                                <span className="badge bg-danger">
                                  {props.t("Cancelled")}
                                </span>
                              ) : (
                                getCurrentUser()?.expiry_date_extended ?
                                <span className="badge bg-success">
                                  {props.t("Extended")}
                                </span>
                                :
                                <span className="badge bg-warning">
                                  {props.t("Trial")}
                                </span>
                              )}
                            </td>
                            <td>-</td>
                            <td>
                              {
                                JSON.parse(localStorage.getItem("plan_data"))
                                  ?.plan_expiry_date
                              }
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        ) : (
                          subs?.map((item, i) => (
                            <tr key={i}>
                              <td>{item.membership_name}</td>
                              <td>
                                {item.status === "active" ? (
                                  <span className="badge bg-success">
                                    {props.t("Active")}
                                  </span>
                                ) : item.status === "expired" ? (
                                  <span className="badge bg-danger">
                                    {props.t("Expired")}
                                  </span>
                                ) : (
                                  <span className="badge bg-warning">
                                    {props.t("Trial")}
                                  </span>
                                )}
                              </td>
                              <td>${item.amount}</td>
                              <td>{item.expiry_date}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </CardBody>
            </Card>
          </Container>
          <Container>
            <Card>
              <CardBody>
                <h5 className="page-title">{props.t("Invoices")}</h5>
                <div className="table-responsive">
                  <table className="table mt-2">
                    <thead>
                      <tr>
                        <th>{props.t("Invoice")} #</th>
                        <th>{props.t("Date")}</th>
                        <th>{props.t("Amount")}</th>
                        <th>{props.t("Membership")}</th>
                        <th>{props.t("Download")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices &&
                        invoices.map((item, i) => (
                          <>
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.metadata?.date}</td>
                              <td>${item.amount / 100}</td>
                              <td>{item?.lines?.data[0]?.description}</td>
                              <td>
                                <a
                                  href="https://pay.stripe.com/invoice/acct_1LiXLcIm8kOXMYRy/test_YWNjdF8xTGlYTGNJbThrT1hNWVJ5LF9OZU5iM1hNSVFhYmlyelJKVEpLbWNOYUlnVEJENFo4LDcxMjEyNDgw0200htPtuTja/pdf?s=ap"
                                  style={{ color: "inherit" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-down-circle ms-4"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                                    />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default withTranslation()(myMembership);
myMembership.propTypes = {
  t: PropTypes.any,
};

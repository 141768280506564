import React, { useState, useMemo, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import countryList from "react-select-country-list";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import {
  getCurrentUser,
  setCurrentCompany,
  getCurrentCompany,
  setCompanyLogo,
  getPermission,
  setCurrentUser,
} from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let allCC = require("currency-codes");
import CreateTrialModal from "../plans/trialModal";
import { Link } from "react-router-dom";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBContainer,
} from "mdb-react-ui-kit";
import ReferencesConfig from "../BasicConfiguration/References/index";
import PaymentTerms from "../BasicConfiguration/PaymentTerms/index";
import PriceRateContainer from "../../components/PriceRates/index";
import ConsumptionTax from "../AdvanceConfiguration/Taxes/consumption/index";
import IncomeTax from "../AdvanceConfiguration/Taxes/Income/index";
import AllClientCategories from "../Management-listings/Client-supplier-catagory/all";
import AllPaymentOptions from "../Management-listings/payment-options/index";
import AllDeliveryOptions from "../Management-listings/delivery-options/index";
import AllExpenseCategory from "../Management-listings/Expense-Categories/index";
import Templates from "../Templates/all";
import AllRoles from "../Role/index";
import Users from "../Users/all";
import AllBankAccounts from "../BankAccounts/index";
import CustomStates from "../States/all";
import EmailConfigurationPage from "../EmailConfiguration/index";
import ImportExport from "../ImportExport/index";
import ElectronicModal from "./electronicModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";

const SingleCompany = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const main = searchParams.get("main");
  const sub = searchParams.get("sub");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [allAT, setAllAT] = useState([]);
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [companyImage, setCompanyImage] = useState([]);
  const [electronicImage, setElectronicImage] = useState([]);
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [electrnicImgPreview, setElectrnicImgPreview] = useState(uploadImg);
  const [simple_color, setsimple_color] = useState(0);
  const [color, setcolor] = useState("");
  const [phone, setPhone] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [electronicModal, setElectronicModal] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [sinCom, setSinCom] = useState({});
  const [env, setEnv] = useState("");
  const [testAuthSeq, setTestAuthSeq] = useState("");
  const [liveAuthSeq, setLiveAuthSeq] = useState("");
  const [registerType, setRegisterType] = useState("");
  const [regimeType, setRegimeType] = useState("");
  const [ecuador, setEcuador] = useState(false);
  const [country, setCountry] = useState("");
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
      1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
      null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const [customActiveTabOne, setCustomActiveTabOne] = useState("1");
  const [disableAuthAsri, setDisableAuthAsri] = useState(false);

  useEffect(() => {
    if (main) {
      setCustomActiveTab(main);
      setCustomActiveTabOne(sub);
    }
  }, []);

  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setIsEditable(true);
    }
  };

  const getSingleCompany = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/companies/${localStorage.getItem(
          "companyId"
        )}?company_id=${localStorage.getItem("companyId")}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader(false);
        setSinCom(res.data.company);
        setTestAuthSeq(res.data.company.testing_authorized_sequence);
        setLiveAuthSeq(res.data.company.authorized_sequence);
        setRegisterType(res.data.company?.register_as);
        setRegimeType(res.data.company?.rimpe_regime_type);
        setEnv(res.data.company.veronica_environment);
        setImgPreview(res.data.company.logo);
        setElectrnicImgPreview(
          res.data.company?.electronic_logo
            ? res.data.company?.electronic_logo
            : uploadImg
        );
        setcolor(res.data.company.corporate_color);
        setPhone(res.data.company.phone);
        let str2 = res.data.company.phone;
        const before = str2.slice(0, str2.indexOf(" "));
        if (before === "+593") {
          setEcuador(true);
        }
        if (res.data.company?.is_sri_enabled) {
          setDisableAuthAsri(true);
        }
        // if (res.data.status) {
        //   setError('')
        // } else {
        //   setError(res.data.message)
        // }
      });
  };

  const onDrag = (c1) => {
    setcolor(c1);
  };

  const submitForm = () => { };

  const currentUserUpdate = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/get-current-user`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCurrentUser(res.data.data);
        } else {
        }
      });
  };

  const companyUpdateSubmit = (e, value) => {
    let estab = "";
    let emiss = "";
    if (ecuador) {
      estab = value.establishments;
      emiss = value.emission_point;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append(
      "commercial_name",
      value.commercial_name !== null ? value.commercial_name : ""
    );
    formData.append("country", value.country !== null ? value.country : "");
    formData.append("state", value.state !== null ? value.state : "");
    formData.append("city", value.city !== null ? value.city : "");
    formData.append("address", value.address !== null ? value.address : "");
    formData.append("pincode", value.pincode !== null ? value.pincode : "");
    formData.append("phone", phone !== null ? phone : "");
    formData.append(
      "activity_type",
      value.activity_type !== null ? value.activity_type : ""
    );
    formData.append("email", value.email !== null ? value.email : "");
    // formData.append(
    //   "legal_registration",
    //   value.legal_registration !== null ? value.legal_registration : ""
    // );
    formData.append("corporate_color", color !== null ? color : "");
    formData.append(
      "register_as",
      value.register_as !== null ? value.register_as : ""
    );
    formData.append("tin", value.tin !== null ? value.tin : "");
    // formData.append("fax", value.fax !== null ? value.fax : "");
    formData.append("commercial_activity", value.commercial_activity !== null ? value.commercial_activity : "");
    formData.append(
      "number_of_employees",
      value.number_of_employees !== null ? value.number_of_employees : ""
    );
    formData.append("website", value.website !== null ? value.website : "");
    formData.append("language", value.language !== null ? value.language : "");
    formData.append(
      "time_zone",
      value.time_zone !== null ? value.time_zone : ""
    );
    formData.append(
      "fiscal_start_date",
      value.fiscal_start_date !== null ? value.fiscal_start_date : ""
    );
    formData.append(
      "fiscal_start_month",
      value.fiscal_start_month !== null ? value.fiscal_start_month : ""
    );
    formData.append("number_of_decimal", value.number_of_decimal);
    formData.append("decimal_separator", value.decimal_separator);
    formData.append(
      "pdf_file_download_date_format",
      value.pdf_file_download_date_format
    );
    formData.append("currency", value.currency);
    formData.append("currency_representation", value.currency_representation);
    formData.append("taxpayer_identification", value.taxpayer_identification);
    formData.append("logo", companyImage);
    formData.append("electronic_logo", electronicImage);
    formData.append("number_of_establishment", value.number_of_establishment);
    formData.append("emission_point", value.emission_point);
    formData.append("sequence_start", "");
    formData.append("sequence_end", "");
    formData.append("is_sri_enabled", disableAuthAsri ? 1 : 0);
    if (disableAuthAsri) {
      formData.append("auth_asri", value.auth_asri);
    }

    if (value.authorized_sequence) {
      formData.append("authorized_sequence", value.authorized_sequence);
    } else {
      formData.append("authorized_sequence", liveAuthSeq);
    }
    if (value.testing_authorized_sequence) {
      formData.append(
        "testing_authorized_sequence",
        value.testing_authorized_sequence
      );
    } else {
      formData.append("testing_authorized_sequence", testAuthSeq);
    }
    formData.append(
      "keep_accounting",
      value?.keep_accounting ? value?.keep_accounting : ""
    );
    formData.append(
      "veronica_environment",
      value?.veronica_environment ? value.veronica_environment : ""
    );
    // formData.append("obligated_accounting", value.obligated_accounting);
    formData.append(
      "rimpe_regime_type",
      registerType === "rimpe" ? value.rimpe_regime_type : ""
    );
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/companies/${localStorage.getItem(
          "companyId"
        )}?_method=PUT&company_id=${localStorage.getItem("companyId")}`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          setSinCom(res.data.company);
          setTestAuthSeq(res.data.company.testing_authorized_sequence);
          setLiveAuthSeq(res.data.company.authorized_sequence);
          setEnv(res.data.company.veronica_environment);
          setRegisterType(res.data.company?.register_as);
          setRegimeType(res.data.company?.rimpe_regime_type);
          setCompanyLogo(res.data.company.logo);
          setIsEditable(false);
          currentUserUpdate();
          if (getCurrentCompany.id === res.data.company.id) {
            setCurrentCompany(res.data.company);
          }
        } else {
          setError(res.data.message);
          setSuccess("");
        }
      });
  };

  const getAT = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY
        }/activity-types?company_id=${localStorage.getItem("companyId")}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllAT(res.data.activity_types);
        } else {
          setAllAT([]);
        }
      });
  };

  const setComImg = (e) => {
    setCompanyImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const setComImg2 = (e) => {
    setElectronicImage(e.target.files[0]);
    setElectrnicImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const switchVersion = () => {
    setdynamic_title("");
    setdynamic_description("");
    const formData = new FormData();
    formData.append("company_id", localStorage.getItem("companyId"));
    formData.append("veronica_environment", "production");
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/switch-veronica-environment`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setdynamic_title("Done");
          setdynamic_description("Switched to Production Version of Veronica.");
          getSingleCompany();
        } else {
          setdynamic_title("Failed");
          setdynamic_description(res.data.message);
        }
        setsuccess_dlg(true);
      });
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const toggleCustomInner = (tab) => {
    if (customActiveTabOne !== tab) {
      setCustomActiveTabOne(tab);
    }
  };
  // Country/Set Country
  const optionCountries = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    getSingleCompany();
    getAT();
  }, []);
  const getCountry = (a, b, c, d) => {
    if (b?.dialCode == "593") {
      setEcuador(true);
      setCountry(b.name);
    } else {
      setEcuador(false);
      setCountry(b.name);
    }
    let str = d;
    const after = str.slice(str.indexOf(" ") + 1);
    setPhone(d);
  };

  return (
    <React.Fragment>
      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText="Yes, do it!"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            switchVersion();
            setconfirm_alert(false);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          {" "}
          {props.t(
            "You can not switch back to testing version after switching to production."
          )}
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success={dynamic_title == "Done" ? true : false}
          error={dynamic_title == "Failed" ? true : false}
          title={props.t(dynamic_title)}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {props.t(dynamic_description)}
        </SweetAlert>
      ) : null}
      {electronicModal ? (
        <ElectronicModal
          electronicModal={electronicModal}
          setElectronicModal={setElectronicModal}
          setSinCom={setSinCom}
          sinCom={sinCom}
        />
      ) : null}
      <CreateTrialModal
        isTrialModal={isTrialModal}
        setIsTrialModal={setIsTrialModal}
        nonActive={nonActive}
      />
      <div className="page-content">
        <MetaTags>
          <title>{sinCom && sinCom.name} | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="create_company">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={6}>
                  <h6 className="page-title">{sinCom && sinCom.name}</h6>
                </Col>
                <Col md={6}>
                  <div className="float-end d-flex">
                    {/* {sinCom?.electronic_sig_added === 1 ?
                        <>
                          {sinCom?.veronica_environment === "testing" ?
                            < span
                              onClick={() => setconfirm_alert(true)}
                              className="btn btn-primary me-2"
                            >
                              {props.t("Switch to Veronica Production version")}
                            </span>
                            :
                            < span
                              onClick={() => setconfirm_alert(true)}
                              className="btn btn-primary me-2"
                              style={{ pointerEvents: "none", opacity: "0.5" }}
                            >
                              {props.t("Switch to Veronica Production version")}
                            </span>
                          }
                        </>
                        :
                        ""
                      } */}
                    {/* < span
                        onClick={() => setElectronicModal(true)}
                        className="btn btn-primary me-2"
                      >
                        {props.t("Electronic Signature")}
                      </span> */}
                    {isEditable ? (
                      <>
                        {loading ? (
                          <button
                            className="btn btn-primary  disabled"
                            type="button"
                            disabled
                          >
                            {props.t("Loading")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary "
                            type="submit"
                            form="companyForm"
                          >
                            {props.t("Update")}
                          </button>
                        )}
                        <span
                          onClick={() => setIsEditable(false)}
                          className="btn btn-primary  ms-2"
                        >
                          {props.t("Cancel")}
                        </span>
                      </>
                    ) : (
                      <span
                        onClick={() => {
                          createNew();
                        }}
                        className="btn btn-primary "
                      >
                        {props.t("Edit")}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {success ? <Alert color="success">{success}</Alert> : null}

            {error ? <Alert color="danger">{error}</Alert> : null}
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("General")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("Location Settings")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "11",
                  })}
                  onClick={() => {
                    toggleCustom("11");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("Electronic billing")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span className="d-none d-sm-block">{props.t("Logo")}</span>
                </NavLink>
              </NavItem>
              {getPermission()?.users.users.view.is_checked === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "10",
                    })}
                    onClick={() => {
                      toggleCustom("10");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      {" "}
                      {props.t("Users")}
                    </span>
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {getPermission()?.references?.references?.view.is_checked === 0 &&
                getPermission()?.payment_terms?.payment_terms?.view.is_checked ===
                0 &&
                getPermission()?.price_rates?.price_rates?.view.is_checked ===
                0 ? (
                ""
              ) : (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleCustom("4");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      {props.t("Basic Configuration")}
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {getPermission()?.taxes.taxes.view.is_checked === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleCustom("5");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      {props.t("Taxes")}
                    </span>
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {getPermission()?.expense_categories.expense_categories.view
                .is_checked === 0 &&
                getPermission()?.delivery_options.delivery_options.view
                  .is_checked === 0 &&
                getPermission()?.payment_options.payment_options.view
                  .is_checked === 0 &&
                getPermission()?.client_and_supplier_categories
                  .client_and_supplier_categories.view.is_checked === 0 ? (
                ""
              ) : (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleCustom("6");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      {props.t("Management Listings")}
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {getPermission()?.my_templates.my_templates.view.is_checked ===
                1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleCustom("7");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      {props.t("My Templates")}
                    </span>
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {getPermission()?.roles.roles.view.is_checked === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleCustom("8");
                    }}
                  >
                    <span className="d-none d-sm-block">{props.t("Role")}</span>
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "9",
                  })}
                  onClick={() => {
                    toggleCustom("9");
                  }}
                >
                  <span className="d-none d-sm-block">{props.t("Other")}</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab} className="pt-3">
              <AvForm
                // className="form-horizontal"
                onValidSubmit={(e, v) => {
                  companyUpdateSubmit(e, v);
                }}
                id="companyForm"
              >
                <div className={`${customActiveTab === "1" ? "" : "d-none"}`}>
                  <Row>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3 required">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Company Name")}
                              type="text"
                              name="name"
                              value={sinCom?.name !== null ? sinCom?.name : ""}
                              required="required"
                            />
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Commercial Name")}
                              type="text"
                              name="commercial_name"
                              value={
                                sinCom?.commercial_name !== null
                                  ? sinCom?.commercial_name
                                  : ""
                              }
                              required="required"
                            />
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Address")}
                              type="text"
                              name="address"
                              value={
                                sinCom?.address !== null ? sinCom?.address : ""
                              }
                              required="required"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("State/Province")}
                              type="text"
                              name="state"
                              value={
                                sinCom?.state !== null ? sinCom?.state : ""
                              }
                            />
                          </div>
                          <div className="mb-3 required">
                            {/* <label>{props.t("Phone")}</label> */}
                            {/* <PhoneInput
                              inputStyle={{ width: "100%" }}
                              value={
                                sinCom?.phone !== null ? sinCom?.phone : ""
                              }
                              disableDropdown={true}
                              disableCountryCode={true}
                              name="phone"
                              placeholder={props.t("Enter phone number")}
                              onChange={(s, d, f, g) => getCountry(s, d, f, g)}
                              disabled={isEditable ? false : true}
                            /> */}
                            <label>{props.t("Phone")}</label>
                            <input
                              name="phone"
                              type="tel"
                              readOnly={isEditable ? false : true}
                              placeholder="Enter phone number"
                              defaultValue={phone !== null ? phone : ""}
                              onChange={(e) => setPhone(e.target.value)}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/\D/g, "")
                                  .substring(0, 12);
                              }}
                              className="form-control"
                            />
                            {error === "The phone field is required." ? (
                              <span className="text-danger">{error}</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="activity_type"
                              label={props.t("Activity Type")}
                              value={
                                sinCom?.activity_type !== null
                                  ? sinCom?.activity_type
                                  : ""
                              }
                              disabled={isEditable ? false : true}
                            >
                              {allAT &&
                                allAT.map((item, i) => (
                                  <option key={i} value={item.activity_type}>
                                    {item.activity_type}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={true}
                              label={props.t("Email")}
                              type="text"
                              name="email"
                              value={
                                sinCom?.email !== null ? sinCom?.email : ""
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="number_of_employees"
                              value={
                                sinCom.number_of_employees !== null
                                  ? sinCom.number_of_employees
                                  : ""
                              }
                              label={props.t("Number of Employees")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="not_defined">
                                {props.t("Not defined")}
                              </option>
                              <option value="less_then_10">
                                {props.t("Less then 10")}
                              </option>
                              <option value="between_11_and_50">
                                {props.t("Between 11 and 50")}
                              </option>
                              <option value="between_50_and_250">
                                {props.t("Between 50 and 250")}
                              </option>
                              <option value="between_250_and_1000">
                                {props.t("Between 250 and 1000")}
                              </option>
                              <option value="more_then_1000">
                                {props.t("More then 1000")}
                              </option>
                            </AvField>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3 required">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("RUC")}
                              type="number"
                              onKeyPress={(e) => {
                                if (e.target.value.length == 13)
                                  e.preventDefault();
                              }}
                              name="tin"
                              value={sinCom?.tin !== null ? sinCom?.tin : ""}
                              required="required"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Zip/Postal Code")}
                              type="text"
                              name="pincode"
                              value={
                                sinCom.pincode !== null ? sinCom.pincode : ""
                              }
                            />
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("City/Town")}
                              type="text"
                              name="city"
                              value={sinCom?.city !== null ? sinCom?.city : ""}
                              required="required"
                            />
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Legal Registration")}
                              type="text"
                              name="legal_registration"
                              value={
                                sinCom?.legal_registration !== null
                                  ? sinCom?.legal_registration
                                  : ""
                              }
                            />
                          </div> */}
                          <div className="mb-3">
                            <label>{props.t("Corporate Color")}</label>
                            <Input
                              type="text"
                              name="corporate_color"
                              className="colorpicker-default"
                              value={color !== null ? color : ""}
                              onClick={() => {
                                setsimple_color(!simple_color);
                              }}
                              readOnly
                            />
                            {simple_color ? (
                              <ColorPicker
                                saturationHeight={100}
                                saturationWidth={100}
                                value={color !== null ? color : ""}
                                onDrag={onDrag}
                              />
                            ) : null}
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Fax")}
                              type="text"
                              name="fax"
                              value={sinCom?.fax !== null ? sinCom?.fax : ""}
                            />
                          </div> */}
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Website")}
                              type="text"
                              name="website"
                              placeholder={props.t("web format")}
                              //   required
                              value={
                                sinCom?.website !== null ? sinCom?.website : ""
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              value={
                                sinCom?.commercial_activity !== null
                                  ? sinCom?.commercial_activity
                                  : ""
                              }
                              label={props.t("Commercial Activity")}
                              type="textarea"
                              name="commercial_activity"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className={`${customActiveTab === "2" ? "" : "d-none"}`}>
                  <Row>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="language"
                              value={
                                sinCom.language !== null
                                  ? sinCom.language
                                  : "latam_spanish"
                              }
                              label={props.t("Language")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="english">English</option>
                              <option value="spanish">Spanish</option>
                              <option value="latam_spanish">
                                Latam Spanish
                              </option>
                              <option value="chilean_spanish">
                                Chilean Spanish
                              </option>
                              <option value="french">French</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Time Zone")}
                              type="text"
                              name="time_zone"
                              // value={sinCom && sinCom.time_zone}
                              value={
                                sinCom?.time_zone !== null
                                  ? sinCom.time_zone
                                  : "America/Guayaquil"
                              }
                              disabled
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="country"
                              value={
                                sinCom.country !== null ? sinCom.country : "EC"
                              }
                              label={props.t("Country")}
                              disabled={isEditable ? false : true}
                            >
                              {optionCountries &&
                                optionCountries.map((item, i) => (
                                  <option value={item.value} key={i}>
                                    {item.label}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="fiscal_start_date"
                              value={
                                sinCom.fiscal_start_date !== null
                                  ? sinCom.fiscal_start_date
                                  : ""
                              }
                              label={props.t("Start date for your fiscal year")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="number_of_decimal"
                              value={
                                sinCom.number_of_decimal !== null
                                  ? sinCom.number_of_decimal
                                  : ""
                              }
                              label={props.t("Number of decimals")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="pdf_file_download_date_format"
                              value={
                                sinCom.pdf_file_download_date_format !== null
                                  ? sinCom.pdf_file_download_date_format
                                  : ""
                              }
                              label={props.t("Format of the dates")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="dd/mm/yyyy">dd/mm/yyyy</option>
                              <option value="mm/dd/yyyy">mm/dd/yyyy</option>
                              <option value="yyyy/mm/dd">yyyy/mm/dd</option>
                            </AvField>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="currency"
                              value={
                                sinCom.currency !== null
                                  ? sinCom.currency
                                  : "USD"
                              }
                              label={props.t("Currency Representation")}
                              disabled={isEditable ? false : true}
                            >
                              {allCC.codes().map((item, i) => (
                                <option value={item} key={i}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="currency_representation"
                              value={
                                sinCom.currency_representation !== null
                                  ? sinCom.currency_representation
                                  : ""
                              }
                              label={props.t("Currency Representation")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="Symbol">
                                {props.t("Symbol")}
                              </option>
                              <option value="iso_Code">
                                {props.t("ISO Code")}
                              </option>
                              <option value="iso_symbol">
                                {props.t("ISO Code and Symbol (only for $)")}
                              </option>
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label="Ruc"
                              type="text"
                              name="taxpayer_identification"
                              defaultValue="TIN"
                            />
                          </div> */}
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="fiscal_start_month"
                              value={
                                sinCom.fiscal_start_month !== null
                                  ? sinCom.fiscal_start_month
                                  : ""
                              }
                              label={props.t("Fiscal year start month")}
                              disabled={isEditable ? false : true}
                            >
                              <option value="1">{props.t("January")}</option>
                              <option value="2">{props.t("February")}</option>
                              <option value="3">{props.t("March")}</option>
                              <option value="4">{props.t("April")}</option>
                              <option value="5">{props.t("May")}</option>
                              <option value="6">{props.t("June")}</option>
                              <option value="7">{props.t("July")}</option>
                              <option value="8">{props.t("August")}</option>
                              <option value="9">{props.t("September")}</option>
                              <option value="10">{props.t("October")}</option>
                              <option value="11">{props.t("November")}</option>
                              <option value="12">{props.t("December")}</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="decimal_separator"
                              value={
                                sinCom.decimal_separator !== null
                                  ? sinCom.decimal_separator
                                  : ","
                              }
                              label={props.t("Decimal separator")}
                              disabled={isEditable ? false : true}
                            >
                              <option value=".">.</option>
                              <option value=",">,</option>
                            </AvField>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className={`${customActiveTab === "11" ? "" : "d-none"}`}>
                  <Row>
                    <Col lg={6}>
                      <Card>
                        <CardBody>
                          <div className="d-flex">
                            <div className="mb-3">
                              <label>{props.t("I am")}</label>
                              <AvRadioGroup
                                name="register_as"
                                className="radiobutton"
                                value={
                                  sinCom?.register_as !== null
                                    ? sinCom?.register_as
                                    : ""
                                }
                                onChange={(e) =>
                                  setRegisterType(e.target.value)
                                }
                                disabled={isEditable ? false : true}
                              >
                                <AvRadio
                                  label={props.t("Regimen General")}
                                  value="company"
                                />
                                <AvRadio label={"Rimpe"} value={"rimpe"} />
                              </AvRadioGroup>
                            </div>

                            {/* {ecuador ? (
                              
                            ) : (
                              ""
                            )} */}
                          </div>
                          {registerType === "rimpe" ? (
                            <div className="mb-3">
                              <AvField
                                type="select"
                                readOnly={isEditable ? false : true}
                                value={sinCom?.rimpe_regime_type}
                                onChange={(e) => setRegimeType(e.target.value)}
                                name="rimpe_regime_type"
                                label={props.t("Rimpe Regime Type")}
                              >
                                <option value="">Select</option>
                                <option value="emprendedor">
                                  RIMPE Emprendedor
                                </option>
                                <option value="popular">
                                  RIMPE Negocios Populares
                                </option>
                              </AvField>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="d-flex ms-3">
                            <div className="mb-3">
                              <AvField
                                type="number"
                                readOnly={isEditable ? false : true}
                                value={sinCom?.number_of_establishment}
                                // onChange={(e) => setEstablishment(e.target.value)}
                                name="number_of_establishment"
                                label={props.t("No. of Establishments")}
                                max={999}
                                validate={{
                                  maxLength: {
                                    value: 3,
                                    errorMessage:
                                      "Please enter value between 000-999",
                                  },
                                }}
                              >
                                {/* <option value="001">001</option>
                                <option value="002">002</option>
                                <option value="003">003</option>
                                <option value="004">004</option>
                                <option value="005">005</option>
                                <option value="006">006</option>
                                <option value="007">007</option>
                                <option value="008">008</option>
                                <option value="009">009</option>
                                <option value="010">010</option> */}
                              </AvField>
                            </div>
                            <div className="mb-3 ms-4">
                              <AvField
                                type="number"
                                readOnly={isEditable ? false : true}
                                value={sinCom?.emission_point}
                                // onChange={(e) => setEmission(e.target.value)}
                                name="emission_point"
                                label={props.t("Emission Point")}
                                max={999}
                                validate={{
                                  maxLength: {
                                    value: 3,
                                    errorMessage:
                                      "Please enter value between 000-999",
                                  },
                                }}
                              >
                                {/* <option value="001">001</option>
                                <option value="002">002</option>
                                <option value="003">003</option>
                                <option value="004">004</option>
                                <option value="005">005</option>
                                <option value="006">006</option>
                                <option value="007">007</option>
                                <option value="008">008</option>
                                <option value="009">009</option>
                                <option value="010">010</option> */}
                              </AvField>
                            </div>
                          </div>
                          {/* {regimeType === "popular" ? (
                            <div className="d-flex ms-3">
                              <div className="mb-3">
                                <AvField
                                  type="number"
                                  readOnly={isEditable ? false : true}
                                  value={sinCom?.sequence_start}
                                  // onChange={(e) => setEstablishment(e.target.value)}
                                  name="sequence_start"
                                  label={props.t("Sequence Start")}
                                  max={999}
                                  validate={{
                                    maxLength: {
                                      value: 3,
                                      errorMessage:
                                        "Please enter value between 000-999",
                                    },
                                  }}
                                ></AvField>
                              </div>
                              <div className="mb-3 ms-4">
                                <AvField
                                  type="number"
                                  readOnly={isEditable ? false : true}
                                  value={sinCom?.sequence_end}
                                  // onChange={(e) => setEmission(e.target.value)}
                                  name="sequence_end"
                                  label={props.t("Sequence End")}
                                  max={999}
                                  validate={{
                                    maxLength: {
                                      value: 3,
                                      errorMessage:
                                        "Please enter value between 000-999",
                                    },
                                  }}
                                ></AvField>
                              </div>
                            </div>
                          ) : null}{" "} */}
                          <div className="d-flex w-100">
                            <div className="w-50" style={{ marginTop: "31px" }}>
                              <AvField
                                type="number"
                                readOnly={isEditable ? false : true}
                                value={
                                  env == "testing" ? testAuthSeq : liveAuthSeq
                                }
                                // onChange={(e) => setEmission(e.target.value)}
                                name={
                                  env == "testing"
                                    ? "testing_authorized_sequence"
                                    : "authorized_sequence"
                                }
                                label={props.t("Sequential")}
                              >
                                {/* <option value="">Select</option>
                              <option value="emprendedor">RIMPE Emprendedor</option>
                              <option value="popular">RIMPE Negocios Populares</option> */}
                              </AvField>
                            </div>
                            {regimeType === "popular" ? (
                              <div className="mb-3 ms-4 w-50">
                                <div className="d-flex p-0">
                                  <AvField
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={disableAuthAsri}
                                    readOnly={isEditable ? false : true}
                                    // value={sinCom?.is_sri_enabled}
                                    name="is_sri_enabled"
                                    onChange={(e) =>
                                      setDisableAuthAsri(e.target.checked)
                                    }
                                  />
                                  <label>Enable Auth. SRI</label>
                                </div>
                                <AvField
                                  type="number"
                                  readOnly={
                                    isEditable ? !disableAuthAsri : true
                                  }
                                  value={sinCom?.auth_asri}
                                  // onChange={(e) => setEmission(e.target.value)}
                                  name="auth_asri"
                                  label={props.t("Auth. SRI")}
                                  max={9999999999}
                                  validate={{
                                    maxLength: {
                                      value: 10,
                                      errorMessage:
                                        "Please enter 10 digits number",
                                    },
                                  }}
                                >
                                  {/* <option value="">Select</option>
                              <option value="emprendedor">RIMPE Emprendedor</option>
                              <option value="popular">RIMPE Negocios Populares</option> */}
                                </AvField>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <span
                              onClick={() => setElectronicModal(true)}
                              className="btn btn-primary me-2"
                            >
                              {props.t("Electronic Signature")}
                            </span>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              readOnly={isEditable ? false : true}
                              value={sinCom?.keep_accounting}
                              // onChange={(e) => setEmission(e.target.value)}
                              name="keep_accounting"
                              label={props.t(
                                "You are required to keep accounting?"
                              )}
                            >
                              <option value={0}>{props.t("No")}</option>
                              <option value={1}>{props.t("Yes")}</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              readOnly={isEditable ? false : true}
                              value={sinCom?.veronica_environment}
                              onChange={(e) => setEnv(e.target.value)}
                              name="veronica_environment"
                              label={props.t("Select environment")}
                            >
                              <option value="testing">
                                {props.t("Testing")}
                              </option>
                              <option value="production">
                                {props.t("Production")}
                              </option>
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              type="select"
                              readOnly={isEditable ? false : true}
                              value={sinCom?.obligated_accounting}
                              // onChange={(e) => setEmission(e.target.value)}
                              name="obligated_accounting"
                              label={props.t("Obligated to keep accounting?")}
                            >
                              <option value={0}>{props.t("No")}</option>
                              <option value={1}>{props.t("Yes")}</option>
                            </AvField>
                          </div> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className={`${customActiveTab === "3" ? "" : "d-none"}`}>
                  <Row>
                    <Col sm="6">
                      <div className="company_img">
                        <label>{props.t("Company logo")}</label>
                        <input
                          className="hidden d-none"
                          onChange={(e) => setComImg(e)}
                          type="file"
                          id="comImg"
                        />
                        <label
                          className="company_img-box"
                          htmlFor={isEditable ? "comImg" : ""}
                        >
                          <img src={imgPreview} />
                        </label>
                        <span className="text-muted mt-3 d-md-block">
                          {props.t(
                            "We recommend using high quality images for a better visual performance."
                          )}
                        </span>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="company_img">
                        <label>{props.t("Electronic billing logo")}</label>
                        <input
                          className="hidden d-none"
                          onChange={(e) => setComImg2(e)}
                          type="file"
                          id="comImg2"
                        />
                        <label
                          className="company_img-box"
                          htmlFor={isEditable ? "comImg2" : ""}
                        >
                          <img src={electrnicImgPreview} />
                        </label>
                        <span className="text-muted mt-3 d-md-block">
                          {props.t(
                            "We recommend using high quality images for a better visual performance."
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </AvForm>
              <TabPane tabId="4">
                <Nav tabs>
                  {getPermission()?.references.references.view.is_checked ===
                    1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "1",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("1");
                        }}
                      >
                        {props.t("Reference")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.payment_terms.payment_terms.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "2",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("2");
                        }}
                      >
                        {props.t("Payment Terms")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.price_rates.price_rates.view.is_checked ===
                    1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "3",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("3");
                        }}
                      >
                        {props.t("Price Rate")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  <TabContent
                    activeTab={customActiveTabOne}
                    className="pt-3"
                    style={{ width: "100%" }}
                  >
                    <TabPane tabId="1">
                      <ReferencesConfig />
                    </TabPane>
                    <TabPane tabId="2">
                      <PaymentTerms />
                    </TabPane>
                    <TabPane tabId="3">
                      <PriceRateContainer />
                    </TabPane>
                  </TabContent>
                </Nav>
              </TabPane>
              <TabPane tabId="5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                      }}
                      className={`${classnames({
                        active: customActiveTabOne === "1",
                      })}`}
                      onClick={() => {
                        toggleCustomInner("1");
                      }}
                    >
                      {props.t("Consumption")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                      }}
                      className={`${classnames({
                        active: customActiveTabOne === "2",
                      })}`}
                      onClick={() => {
                        toggleCustomInner("2");
                      }}
                    >
                      {props.t("Income")}
                    </NavLink>
                  </NavItem>

                  <TabContent
                    activeTab={customActiveTabOne}
                    className="pt-3"
                    style={{ width: "100%" }}
                  >
                    <TabPane tabId="2">
                      <IncomeTax />
                    </TabPane>
                    <TabPane tabId="1">
                      <ConsumptionTax />
                    </TabPane>
                  </TabContent>
                </Nav>
              </TabPane>
              <TabPane tabId="6">
                <Nav tabs>
                  {getPermission()?.client_and_supplier_categories
                    .client_and_supplier_categories.view.is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "1",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("1");
                        }}
                      >
                        {props.t("Client and Supplier Categories")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.payment_options.payment_options.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "2",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("2");
                        }}
                      >
                        {props.t("All Payment Options")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.delivery_options.delivery_options.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "3",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("3");
                        }}
                      >
                        {props.t("Delivery Options")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.expense_categories.expense_categories.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "4",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("4");
                        }}
                      >
                        {props.t("Expense Categories")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  <TabContent
                    activeTab={customActiveTabOne}
                    className="pt-3"
                    style={{ width: "100%" }}
                  >
                    <TabPane tabId="1">
                      <AllClientCategories />
                    </TabPane>
                    <TabPane tabId="2">
                      <AllPaymentOptions />
                    </TabPane>
                    <TabPane tabId="3">
                      <AllDeliveryOptions />
                    </TabPane>
                    <TabPane tabId="4">
                      <AllExpenseCategory />
                    </TabPane>
                  </TabContent>
                </Nav>
              </TabPane>
              <TabPane tabId="7">
                <Templates />
              </TabPane>
              <TabPane tabId="8">
                <AllRoles />
              </TabPane>
              <TabPane tabId="9">
                <Nav tabs>
                  {getPermission()?.bank_accounts.bank_accounts.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "1",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("1");
                        }}
                      >
                        {props.t("Bank Account")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.custom_states.custom_states.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "2",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("2");
                        }}
                      >
                        {props.t("Custom State")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {getPermission()?.email_configuration.email_configuration.view
                    .is_checked === 1 ? (
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTabOne === "3",
                        })}`}
                        onClick={() => {
                          toggleCustomInner("3");
                        }}
                      >
                        {props.t("Email Configuration")}
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                      }}
                      className={`${classnames({
                        active: customActiveTabOne === "4",
                      })}`}
                      onClick={() => {
                        toggleCustomInner("4");
                      }}
                    >
                      {props.t("Import/Export")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={customActiveTabOne}
                  className="pt-3"
                  style={{ width: "100%" }}
                >
                  <TabPane tabId="1">
                    <AllBankAccounts />
                  </TabPane>
                  <TabPane tabId="2">
                    <CustomStates />
                  </TabPane>
                  <TabPane tabId="3">
                    <EmailConfigurationPage />
                  </TabPane>
                  <TabPane tabId="4">
                    <ImportExport />
                  </TabPane>
                </TabContent>
              </TabPane>
              <TabPane tabId="10">
                <Users />
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(SingleCompany);
SingleCompany.propTypes = {
  t: PropTypes.any,
};

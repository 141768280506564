/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    AvForm,
    AvField,
    AvCheckboxGroup,
    AvCheckbox,
} from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
    Table,
    Input,
} from "reactstrap";
import uploadImg from "../../../assets/images/def-img.svg";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AssetsNew from "../../../components/Assests/assests-new";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import {
    getCurrentUser,
    getCurrentCompany,
    getPermission,
} from "../../../helpers/Utils";
const axios = require("axios").default;
import TechnicalAttachment from "../../../components/Attachments/technical-attachment";
import ClientAddress from "../../../components/ClientAddresses";
import ClientCustomDropdown from "../../../components/ClientDropdown";
import AssignUserList from "../../../components/AssignUserList";
import moment from "moment";
import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import SweetAlert from "react-bootstrap-sweetalert";
import SendAttachment from "../../SendAttachments/index";
import ClientList from "../../../components/ClientList";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import CreateTrialModal from "../../plans/trialModal";
let clientName = "";
let clientAssets = "";

let searchedClient;

const EditServiceNew = (props) => {
    const { id } = useParams();
    const [userId] = useState(parseInt(id, 10));
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenModals, setIsOpenModals] = useState(false);
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [isOpenClient, setIsOpenClient] = useState(false);
    const [isOpenAsset, setIsOpenAsset] = useState(false);
    const [newDisable, setNewDisable] = useState(false);
    const [clientAddress, setClientAddress] = useState("");
    const [clientFull, setClientFull] = useState("");
    const [timer, setTimer] = useState(null);
    const [referenceType, setReferenceType] = useState("pro");
    const [priorityName, setPriorityName] = useState("verylow");
    const [startDate, setStartDate] = useState("");
    const [assignDate, setAssignDate] = useState("");
    const [closingDate, setClosingDate] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState([]);
    const [clientId, setClientId] = useState("");
    const [assetId, setAssetId] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [assetIdString, setAssetIdString] = useState('');
    const [showAssets, setShowAssets] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [attachActive, setAttachActive] = useState(false);
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [clientNameData, setClientNameData] = useState("");
    const [clientNameData2, setClientNameData2] = useState("");
    const [assetNameData, setAssetNameData] = useState("");
    const [assetID, setAssetID] = useState("");
    const [techAttach, setTechAttach] = useState("");
    const [assetAll, setAssetAll] = useState("");
    const [clientLoader, setClientLoader] = useState(false);
    const [mainFormDisabled, setMainFormDisabled] = useState(false);
    const [technicalData, setTechnicalData] = useState("");
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [selectClientModal2, setSelectClientModal2] = useState(false);
    const [allData, setAllData] = useState([]);
    const [selectClientId, setSelectClientId] = useState("");
    const [selectClientId2, setSelectClientId2] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState("");
    const [assignedUserName, setAssignedUserName] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [attachDocument, setAttachDocument] = useState([]);
    const [modalsAttach, setModalsAttach] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [modelClient, setModelClient] = useState(false);
    const history = useHistory();
    const [clientSelect, setClientSelect] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [attach, setAttach] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );

    const [paginationData, setPaginationData] = useState("");
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    let params = new URLSearchParams(window.location.search);
    const [generate] = useState(params.get("generate"));

    const getClients = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };
    const getTechnicalSingleServices = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setTechnicalData(res.data.technical_incident);
                    setRefNum(res.data.technical_incident.reference_number);
                    setClientSelect(res.data.technical_incident.client_id);
                    setClientNameData(res.data.technical_incident.client_name);
                    setClientAddress(res.data.technical_incident.address);
                    setAssignedUserName(res.data.technical_incident.assigned_to_name);
                    const momentDate = moment(
                        res.data.technical_incident.date,
                        "DD-MM-YYYY"
                    );
                    getAssests(res.data.technical_incident.asset_id);
                    setAssetIdString(res.data.technical_incident.asset_id)
                    setStartDate(momentDate.toDate());
                    if (res.data.technical_incident?.assigned_date !== null) {
                        const momentDate2 = moment(
                            res.data.technical_incident?.assigned_date,
                            "DD-MM-YYYY"
                        );
                        setAssignDate(momentDate2.toDate());
                    }
                }
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Incident`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getTechnicalSingleServices();
        getReferences();
    }, []);

    const editTechnicalServices = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("reference", value.reference);
        formData.append("reference_number", e.target.reference_number.value);
        formData.append("client_id", clientSelect);

        const arrValues = assetId.map(item => item.value);
        const valuesString = arrValues.join(',');
        formData.append("asset_id", valuesString);

        formData.append("assigned_to", assignedUserId);
        formData.append("priority", value.priority);
        formData.append("status", value.status !== null ? value.status : "");
        formData.append("date", e.target.date.value);
        formData.append("notifications", value.notifications);
        formData.append(
            "description",
            value.description !== null ? value.description : ""
        );
        formData.append("assigned_date", e.target.assigned_date.value);
        formData.append("closing_date", closingDate);
        formData.append("address", value.address !== null ? value.address : "");
        formData.append(
            "invoice_to",
            value.invoice_to !== null ? value.invoice_to : ""
        );
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents/${userId}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLoading(false);
                    setSuccess(res.data.message);
                    setError("");
                    setAssetID(res.data.technical_incident.id);
                    // history.push('/technical-services')
                } else {
                    setSuccess("");
                    setError(res.data.message);
                    setLoading(false);
                }
            });
    };

    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };
    const getPriorityType = (e) => {
        setPriorityName(e.target.value);
    };

    const getClientData = (e) => {
        setClientLoader(true);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/get-all-clients`;
        if (e !== undefined) {
            searchedClient = e;
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/get-all-clients?search=${searchedClient}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setData([]);
                }
                setClientLoader(false);
            });
    };

    const paginateData = (link) => {
        setPaginationData("");
        setData([]);
        setClientLoader(true);
        let url = `${link}&search=${searchedClient ? searchedClient : ""}`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setClientLoader(false);
                if (res.data.status) {
                    setError("");
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const clientDetail = (e) => {
        setClientSelect(e.id);
        setClientAddress(e.address);
        setClientFull(e);
        setClientNameData(e.name);
        setNewDisable(true);
        setModelClient(false);
    };
    const clientDetail2 = (e) => {
        setSelectClientId2(e);
        let index = data.findIndex((x) => x.id == e);
        if (index != -1) {
            setClientNameData2(data[index].name);
        }
    };
    const assestDetail = (e) => {
        let index = assetAll.findIndex((x) => x.id == e.target.value);
        if (index != -1) {
            setAssetNameData(assetAll[index].name);
        }
        setNewDisable(true);
    };
    const getTemplate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                }
            });
    };

    const getAssests = (assetString = '') => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    let optionAssets = [];
                    if (res.data !== undefined) {
                        {
                            res.data.client_assets &&
                                res.data.client_assets.map((asset) => {
                                    optionAssets.push({
                                        value: asset.id,
                                        label: asset.name,
                                    });
                                });
                        }
                    }
                    if (assetString == '' || !assetString) {
                        assetString = assetIdString;
                    }
                    assetString = !assetString ? '' : assetString;
                    let assetIdList = assetString.split(',');
                    const filterAssets = optionAssets.filter(item => assetIdList.includes(String(item.value)));

                    setAssetId(filterAssets);
                    setAssetData(optionAssets);
                    setAssetAll(res.data.client_assets);
                }
            });
    };

    function handleAssets(selectedcate) {
        if (!selectedcate) {
            selectedcate = [];
        }
        setAssetId(selectedcate)
    }

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };

    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };

    useEffect(() => {
        getAssignUser();
        getTemplate();
        getClientData();
        getAssests();
    }, []);

    const selectClient = (e, item) => {
        console.log("assestClient", item);
        clientName = item;
        setClientId(item);
        setIsOpenClient(false);
    };

    const createAttachment = (e, value) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("document", document);
        formData.append("description", e.target.description.value);
        formData.append("technical_incident_id", userId);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incident-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLoading(false);
                    setError("");
                    setSuccess(res.data.message);
                    AllAttach();
                    setModalsAttach(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const AllAssests = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setShowAssets(res.data.client_assets);
            });
    };

    useEffect(() => {
        AllAssests();
    }, []);

    const selectAssets = (e, item) => {
        console.log("assestClient", item);
        clientAssets = item;
        setAssetId(item);
        setIsOpenAsset(false);
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };

    const AllAttach = () => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incident-attachments?technical_incident_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                setAttachDocument(res.data.technical_incident_attachments);
            });
    };
    const deleteTechnicalIncident = (e) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_description(res.data.message);
                    history.push("/technical-services");
                }
            });
    };
    const getDuplicate = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents/duplicate_incident`,
                {
                    id: userId,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/technical-services/${res.data.data.id}`);
                }
            });
    };
    const getGenerate = (type) => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/generates`,
                {
                    id: userId,
                    from_type: technicalData.reference_type,
                    to_type: type,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    if (type == "Normal Invoice") {
                        history.push(
                            `/invoicing-service/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                    } else {
                        history.push(
                            `/technical-service/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };
    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };
    useEffect(() => {
        AllAttach();
        getAllClientData();
    }, []);

    const getAllClientData = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAllData(res.data.clients.data);
                } else {
                    setAllData([]);
                }
            });
    };
    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("name", selectedName);
        formData.append("type", "technical_incident_attachments");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };
    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                document={document}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
                selectedName={selectedName}
            />
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Yes, delete it!")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        deleteTechnicalIncident(userId);
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title("Deleted");
                        setdynamic_description("El Documento fue eliminado.");
                    }}
                    onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
            ) : null}
            <div className="page-content">
                <MetaTags>
                    <title>Edit Service | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="tech_service">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                editTechnicalServices(e, v);
                            }}
                            disabled={mainFormDisabled ? true : false}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">
                                            {generate ? generate : `${props.t("Edit incident")}`}
                                        </h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <Link
                                                type="button"
                                                to="/technical-services"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            <div className="btn-group me-2">
                                                <MDBContainer>
                                                    <MDBDropdown>
                                                        <MDBDropdownToggle
                                                            className="btn btn-primary"
                                                            type="button"
                                                        >
                                                            {props.t("More")}
                                                        </MDBDropdownToggle>
                                                        <MDBDropdownMenu>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getDuplicate()}
                                                                >
                                                                    {props.t("Duplicate")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setconfirm_alert(true);
                                                                    }}
                                                                >
                                                                    {props.t("Delete")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {props.t("Generate")}
                                                                </span>
                                                                <ul className="dropdown-menu dropdown-submenu">
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() =>
                                                                                getGenerate("Work Estimate")
                                                                            }
                                                                        >
                                                                            {props.t("Estimate")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => getGenerate("Work Order")}
                                                                        >
                                                                            {props.t("Work Order")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() =>
                                                                                getGenerate("Work Delivery Note")
                                                                            }
                                                                        >
                                                                            {props.t("Delivery Notes")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() =>
                                                                                getGenerate("Normal Invoice")
                                                                            }
                                                                        >
                                                                            {props.t("Invoice")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                </ul>
                                                            </MDBDropdownItem>
                                                        </MDBDropdownMenu>
                                                    </MDBDropdown>
                                                </MDBContainer>
                                            </div>
                                            {isEditable ? (
                                                <>
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-primary  disabled"
                                                            type="button"
                                                            disabled
                                                        >
                                                            {props.t("Loading")}
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-primary " type="submit">
                                                            {props.t("Update")}
                                                        </button>
                                                    )}
                                                    <span
                                                        onClick={() => setIsEditable(false)}
                                                        className="btn btn-primary  ms-2"
                                                    >
                                                        {props.t("Cancel")}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {getPermission().incidents.incidents.edit
                                                        .is_checked === 1 ? (
                                                        <span
                                                            onClick={() => createNew()}
                                                            className="btn btn-primary "
                                                        >
                                                            {props.t("Edit")}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}
                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label> {props.t("Reference")}</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        name="reference"
                                                        value={
                                                            technicalData.reference !== null
                                                                ? technicalData.reference
                                                                : ""
                                                        }
                                                        onChange={(e) => getType(e)}
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <Input
                                                            className="w-100 quantity"
                                                            type="number"
                                                            step="1"
                                                            min="1"
                                                            name="reference_number"
                                                            value={refNum !== null ? refNum : ""}
                                                            //   value={technicalData.reference_number}
                                                            disabled={isEditable ? false : true}
                                                        //   placeholder={
                                                        //     referenceType === "pro" ? "Automatic" : ""
                                                        //   }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <label> {props.t("Client")}</label>
                                                <div className="d-flex">
                                                    <div className="pe-2 w-100 client_custom-dropdown">
                                                        <ClientCustomDropdown
                                                            data={data}
                                                            getClients={getClients}
                                                            getClientData={getClientData}
                                                            clientLoader={clientLoader}
                                                            setMainFormDisabled={setMainFormDisabled}
                                                            setClientSelect={setClientSelect}
                                                            setClientAddress={setClientAddress}
                                                            setClientFull={setClientFull}
                                                            clientNameData={clientNameData}
                                                            setClientNameData={setClientNameData}
                                                            clientDetail={clientDetail}
                                                            getAssests={getAssests}
                                                            isEditable={isEditable}
                                                            paginationData={paginationData}
                                                            paginateData={paginateData}
                                                        />
                                                    </div>
                                                    <div className="pe-2">
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => setSelectClientModal(true)}
                                                            type="button"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </div>
                                                    <Link
                                                        className={`btn btn-light ${selectClientId == "" ? "disabled" : ""
                                                            }`}
                                                        to={`/client/${selectClientId}`}
                                                        target="_blank"
                                                    >
                                                        <i className="fas fa-eye" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label> {props.t("Assigned to")}</label>
                                                <span
                                                    onClick={() => setAssignedModal(true)}
                                                    readOnly={isEditable ? false : true}
                                                    className="form-control"
                                                    style={{
                                                        minHeight: "35px",
                                                    }}
                                                >
                                                    {assignedUserName}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Priority")}
                                                    type="select"
                                                    name="priority"
                                                    value={
                                                        technicalData.priority !== null
                                                            ? technicalData.priority
                                                            : ""
                                                    }
                                                    onChange={(e) => getPriorityType(e)}
                                                    disabled={isEditable ? false : true}
                                                >
                                                    <option value="verylow">
                                                        {" "}
                                                        {props.t("Very Low")}
                                                    </option>
                                                    <option value="low"> {props.t("Low")}</option>
                                                    <option value="normal"> {props.t("Normal")}</option>
                                                    <option value="high"> {props.t("High")}</option>
                                                    <option value="veryhigh">
                                                        {" "}
                                                        {props.t("Very High")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Status")}
                                                    type="select"
                                                    name="status"
                                                    value={
                                                        technicalData.status !== null
                                                            ? technicalData.status
                                                            : ""
                                                    }
                                                    disabled={isEditable ? false : true}
                                                >
                                                    {JSON.parse(localStorage.getItem("CustomStates"))?.map((item) => item.name === 'Incident' ?
                                                        <>
                                                            {item.states?.map((option) =>
                                                                <option value={option.name} key={option.id}>{props.t(option.name)}</option>
                                                            )}
                                                        </>
                                                        :
                                                        ""
                                                    )}
                                                    {/* <option value="Pending"> {props.t("Pending")}</option>
                          <option value="Refused"> {props.t("Refused")}</option>
                          <option value="Resolved">
                            {" "}
                            {props.t("Resolved")}
                          </option>
                          <option value="Closed"> {props.t("Closed")}</option> */}
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Status Changed by")}
                                                    type="text"
                                                    name="name"
                                                    value={getCurrentUser().name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Created by")}
                                                    type="text"
                                                    name="created_by"
                                                    value={getCurrentUser().name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label> {props.t("Date")}</label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(e) => setStartDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    //   minDate={new Date()}
                                                    name="date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    disabled={isEditable ? false : true}
                                                // value={technicalData && technicalData.date}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Invoice to")}
                                                    type="text"
                                                    name="invoice_to"
                                                    value={
                                                        technicalData.invoice_to_name !== null
                                                            ? technicalData.invoice_to_name
                                                            : ""
                                                    }
                                                    //   onClick={() => setIsOpenClient(true)}
                                                    onClick={() => setSelectClientModal2(true)}
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Created from")}
                                                    type="text"
                                                    name="name"
                                                    value={props.t("Web")}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label> {props.t("Assets")}</label>
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100 me-2">
                                                        <Select
                                                            options={assetData}
                                                            value={assetId}
                                                            onChange={handleAssets}
                                                            name="asset_id"
                                                            isMulti
                                                            isDisabled={isEditable ? false : true}
                                                        />
                                                        {/* <AvField
                                                            type="select"
                                                            name="asset_id"
                                                            onChange={(e) => assestDetail(e)}
                                                            value={
                                                                technicalData.asset_id !== null
                                                                    ? technicalData.asset_id
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            <option value="" hidden>
                                                                {props.t("Select")}
                                                            </option>
                                                            {assetAll &&
                                                                assetAll.map((asset) => (
                                                                    <option value={asset.id} key={asset.id}>
                                                                        {asset.name}
                                                                    </option>
                                                                ))}
                                                        </AvField> */}
                                                    </div>
                                                    <button
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => setIsOpenModal(true)}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        type="button"
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        + {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Notifications")}
                                                    type="select"
                                                    name="notifications"
                                                    value={
                                                        technicalData.notifications !== null
                                                            ? technicalData.notifications
                                                            : ""
                                                    }
                                                    disabled={isEditable ? false : true}
                                                >
                                                    <option value="" hidden>
                                                        {props.t("Options")}
                                                    </option>
                                                    <option value="notify_creation">
                                                        {props.t("Notify creation")}
                                                    </option>
                                                    <option value="notify_closing">
                                                        {" "}
                                                        {props.t("Notify closing")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    type="text"
                                                    name="address"
                                                    label={props.t("Address")}
                                                    value={
                                                        technicalData.address !== null
                                                            ? technicalData.address
                                                            : ""
                                                    }
                                                    //   onClick={() => setIsOpenAddress(true)}
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Description")}
                                                    type="textarea"
                                                    name="description"
                                                    value={
                                                        technicalData.description !== null
                                                            ? technicalData.description
                                                            : ""
                                                    }
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label> {props.t("Assigned Date")}</label>
                                                <DatePicker
                                                    selected={assignDate}
                                                    onChange={(e) => setAssignDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    type="date"
                                                    name="assigned_date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    // value={
                                                    //   technicalData.assigned_date !== null
                                                    //     ? technicalData.assigned_date
                                                    //     : ""
                                                    // }
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label> {props.t("Closing Date")}</label>
                                                <DatePicker
                                                    selected={closingDate}
                                                    onChange={(e) => setClosingDate(e)}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    //   minDate={new Date()}
                                                    name="closing_date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    value={
                                                        technicalData.closing_date !== null
                                                            ? technicalData.closing_date
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={`${classnames({
                                                    active: customActiveTab === "1",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                                disabled
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "2",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                                disabled
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Related")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="attach_btn">
                                                        <div
                                                            className="float-end d-flex btn btn-primary ms-2"
                                                            onClick={() => setModalsAttach(true)}
                                                        >
                                                            {props.t("New")}
                                                        </div>
                                                        <div
                                                            className="float-end d-flex btn btn-primary"
                                                            onClick={() => getSendAttachment()}
                                                        >
                                                            {props.t("Send via email")}
                                                        </div>{" "}
                                                        {error2 ? (
                                                            <Alert color="danger">{props.t(error2)}</Alert>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <Table striped className="align-middle mt-5">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th> {props.t("Name")}</th>
                                                                    <th> {props.t("Description")}</th>
                                                                    <th> {props.t("Upload Date")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {attachDocument &&
                                                                    attachDocument.map((item, i) => (
                                                                        <TechnicalAttachment
                                                                            data={item}
                                                                            key={i}
                                                                            selectedIds={selectedIds}
                                                                            setSelectedIds={setSelectedIds}
                                                                            selectedName={selectedName}
                                                                            setSelectedName={setSelectedName}
                                                                        />
                                                                    ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2"></TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                    <Modal size="lg" isOpen={isOpenModal} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Assets")}
                            </h5>
                            <button
                                type="button"
                                className="cross__btn btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenModal(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <AssetsNew
                                clientData={clientFull}
                                modalData={isOpenModal}
                                setIsOpenModal={setIsOpenModal}
                            />
                        </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalsAttach} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Document")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => createAttachment(e)}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label> {props.t("Document")}</label>
                                            <input
                                                type="file"
                                                className="ms-3"
                                                name="document"
                                                onChange={filePreview}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label> {props.t("Description")}</label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        <button className="btn btn-primary" type="submit">
                                            {props.t("Submit")}
                                        </button>

                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setModalsAttach(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </Container>
            </div>
            {selectClientModal2 ? (
                <ClientList
                    setSelectClientModal={setSelectClientModal2}
                    setSelectClientId={setSelectClientId2}
                    clients={allData}
                    clientDetail={clientDetail2}
                />
            ) : (
                ""
            )}
            {assignedModal ? (
                <AssignUserList
                    setAssignedModal={setAssignedModal}
                    getAssignedUser={getAssignedUser}
                    data={assignUsers}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );
};
export default withTranslation()(EditServiceNew);
EditServiceNew.propTypes = {
    t: PropTypes.any,
};

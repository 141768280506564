import React, { useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Alert, Modal } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser } from "../../../helpers/Utils";
// import SingleStockData from "../../../components/Product/Stock/stockAll";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const UpdateStockSingle = (props) => {
  const [proData, setProData] = useState(props.proName.name);
  //   const [proID, setProID] = useState(props.data.id);
  const [allData, setAllData] = useState(props.data);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");

  const submitPrice = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("stock", value.stock);
    formData.append("virtual_stock", value.virtual_stock);
    formData.append("minimum_stock", value.minimum_stock);
    formData.append("location", value.location);
    formData.append("warehouse", value.warehouse);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product-stock/${props.data.id}?_method=PUT`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          props.setUser(res.data.client);
          //   props.stockAll();
          //   props.getAllRates();
          props.setIsOpenModal(false);
          props.setMainFormDisabled(false);
          props.getSingleProduct();
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  return (
    <>
      <Modal size="lg" isOpen={props.isOpenModal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {props.t("Edit warehouse")}
          </h5>
          <button
            type="button"
            className="btn btn-primary text-md btn-sm"
            onClick={() => {
              props.setIsOpenModal(false), props.setMainFormDisabled(false);
            }}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          {success ? (
            <div className="mb-3">
              <Alert color="success">{props.t(success)}</Alert>
            </div>
          ) : null}

          {error ? (
            <div className="mb-3">
              <Alert color="danger">{props.t(error)}</Alert>
            </div>
          ) : null}
          <AvForm onValidSubmit={(e, v) => submitPrice(e, v)}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Warehouse")}
                    type="text"
                    value={props.t(allData.warehouse)}
                    name="warehouse"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: props.t("This field is required"),
                      },
                    }}
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Stock")}
                    type="number"
                    name="stock"
                    // onChange={(e) => setWharehouseStock(e.target.value)}
                    value={allData.stock}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: props.t("This field is required"),
                      },
                    }}
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Minimum Stock")}
                    type="number"
                    name="minimum_stock"
                    // onChange={(e) => setWharehouseminStock(e.target.value)}
                    value={allData.minimum_stock}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: props.t("This field is required"),
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <AvField
                    label={props.t("Product")}
                    type="text"
                    readOnly
                    value={proData}
                    name="name"
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Virtual Stock")}
                    type="number"
                    readOnly
                    name="virtual_stock"
                    // onChange={(e) => setWharehouseStock(e.target.value)}
                    value={allData.virtual_stock}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Location")}
                    type="text"
                    name="location"
                    // onChange={(e) => setWharehouseLocation(e.target.value)}
                    value={allData.location}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    {props.t("Loading")}
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit">
                    {/* {props.data.id ? "Update" : "Submit"} */}
                    {props.t("Submit")}
                  </button>
                )}
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  onClick={() => {
                    props.setIsOpenModal(false),
                      props.setMainFormDisabled(false);
                  }}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Modal>
    </>
  );
};
export default withTranslation()(UpdateStockSingle);
UpdateStockSingle.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    AvForm,
    AvField,
    AvCheckboxGroup,
    AvCheckbox,
} from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let varBasePrice;
let varPurchasePrice;
let varSalesMargin;
let varPurchaseMargin;

const CreateService = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [activeMargin, setActiveMargin] = useState(true);
    const [basePrice, setBasePrice] = useState(0.0);
    const [referenceType, setReferenceType] = useState("");
    const [purchasePrice, setPurchasePrice] = useState(0.0);
    const [purchaseMargin, setPurchaseMargin] = useState(0.0);
    const [salesMargin, setSalesMargin] = useState(0.0);
    const [images, setImages] = useState("");
    const [imgPreviews, setImgPreviews] = useState(uploadImg);
    const [dataTax, setDataTax] = useState([]);
    const [defaultTax, setDefaultTax] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const getType = (e) => {
        setReferenceType(e.target.value);
    };

    const marginCalc = (e) => {
        if (e.name === "price") {
            setBasePrice(e.value);
            varBasePrice = e.value;
        } else if (e.name === "purchase_price") {
            setPurchasePrice(e.value);
            varPurchasePrice = e.value;
        } else if (e.name === "sales_margin") {
            setSalesMargin(e.value);
            varSalesMargin = e.value;
        } else if (e.name === "purchase_margin") {
            setPurchaseMargin(e.value);
            varPurchaseMargin = e.value;
        }
        let pMargin = ((varBasePrice - varPurchasePrice) / varPurchasePrice) * 100;
        let sMargin = ((varBasePrice - varPurchasePrice) / varBasePrice) * 100;

        if (
            !isNaN(pMargin) ||
            pMargin == Number.POSITIVE_INFINITY ||
            pMargin == Number.NEGATIVE_INFINITY
        ) {
            setPurchaseMargin(pMargin.toFixed(2));
        }
        if (!isNaN(sMargin)) {
            setSalesMargin(sMargin.toFixed(2));
        }
    };

    const getSalesMargin = (e) => {
        let SM = e;
        let SP = purchasePrice / (1 - SM / 100);
        let NP = SP - purchasePrice;
        let PM = (NP / purchasePrice) * 100;
        setPurchaseMargin(PM.toFixed(2));
        setSalesMargin(SM);
        setBasePrice(SP.toFixed(2));
    };

    const getPurchaseMargin = (e) => {
        let PM = e;
        let NP = (PM * purchasePrice) / 100;
        let SP = parseInt(NP) + parseInt(purchasePrice);
        let SM = (NP / SP) * 100;
        setPurchaseMargin(PM);
        setSalesMargin(SM.toFixed(2));
        setBasePrice(SP.toFixed(2));
    };

    const productCreateSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("price", value.price);
        formData.append("reference", value.reference);
        formData.append("reference_number", value.reference_number);
        formData.append("purchase_price", value.purchase_price);
        formData.append("image", image);
        // formData.append("is_active", value.is_active);
        formData.append("description", value.description);
        formData.append("private_comments", e.target.private_comments.value);
        formData.append("created_from", value.created_from);
        formData.append("active_margin", value.active_margin);
        formData.append("purchase_margin", value.purchase_margin);
        formData.append("sales_margin", value.sales_margin);
        formData.append("discount", value.discount);
        formData.append("minimum_price", value.minimum_price);
        formData.append("tax", e.target.tax.value);
        formData.append("is_promotional", value.is_promotional);
        formData.append("manage_stock", value.manage_stock);
        formData.append("images", images);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/services`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    history.push(`/service/${res.data.service.id}`);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getIdMargin = (e) => {
        if (e.target.checked) {
            setActiveMargin(false);
        } else {
            setActiveMargin(true);
        }
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Service`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    const productCategories = () => {
        axios.get(
            `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/product_categories`,
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        );
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const setComImgs = (e) => {
        setImages(e.target.files[0]);
        setImgPreviews(URL.createObjectURL(e.target.files[0]));
    };

    const getTaxes = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataTax(res.data.taxes);
                    res.data.taxes.taxes?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultTax(item.tax);
                        }
                    });
                }
            });
    };

    useEffect(() => {
        getReferences();
        productCategories();
        getTaxes();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>New Service | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                productCreateSubmit(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Service")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        name="reference"
                                                        value={defaultRef}
                                                        onChange={(e) => getType(e)}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                        <option value="manual">{props.t("MANUAL/MANUAL")}</option>
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <AvField
                                                            className="w-100"
                                                            type="number"
                                                            name="reference_number"
                                                            placeholder={props.t("Automatic")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="name"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Base Sales Price ($)")}
                                                    type="text"
                                                    name="price"
                                                    value={basePrice}
                                                    onChange={(e) => marginCalc(e.target)}
                                                    required="required"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="company_img">
                                                <label>{props.t("Main image")}</label>
                                                <input
                                                    className="hidden d-none"
                                                    onChange={(e) => setComImg(e)}
                                                    type="file"
                                                    id="comImg"
                                                />
                                                <label className="company_img-box" htmlFor="comImg">
                                                    <img src={imgPreview} />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("General")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Commercial")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "3",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Rates")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Images")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "5",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("5");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "6",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("6");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("History")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col lg="12">
                                                    {/* <div className="mb-1">
                                                        <AvCheckboxGroup name="is_active">
                                                            <AvCheckbox
                                                                label={props.t("Inactive")}
                                                                value="1"
                                                            />
                                                        </AvCheckboxGroup>
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Description")}
                                                            type="textarea"
                                                            name="description"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Private Comments")}
                                                            type="textarea"
                                                            name="private_comments"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Created from")}
                                                            type="text"
                                                            name="created_from"
                                                            value="Web"
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg="6">
                                                    {
                                                        getPermission().other_config.show_purchase_pricing.is_checked === 0 ? ("") : 
                                                        (
                                                            <div className="mb-3">
                                                            <AvField
                                                                label={props.t("Purchase Price ($)")}
                                                                type="number"
                                                                name="purchase_price"
                                                                value={purchasePrice}
                                                                onChange={(e) => marginCalc(e.target)}
                                                            />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        getPermission().other_config.show_margin.is_checked === 0 ? ("") : 
                                                        (
                                                            <>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        label={props.t("Active Margin")}
                                                                        type="checkbox"
                                                                        name="active_margin"
                                                                        onChange={(e) => getIdMargin(e)}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        label={props.t("Purchase Margin (%)")}
                                                                        type="text"
                                                                        name="purchase_margin"
                                                                        value={purchaseMargin}
                                                                        onChange={(e) =>
                                                                            getPurchaseMargin(e.target.value)
                                                                        }
                                                                        disabled={activeMargin ? true : false}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Minimum Price ($)")}
                                                            type="text"
                                                            name="minimum_price"
                                                            value="0.00"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="form-control"
                                                            type="select"
                                                            name="tax"
                                                            label={props.t("Tax")}
                                                            value={defaultTax}
                                                        >
                                                            {dataTax?.map((item, i) => (
                                                                <option value={item.tax} key={i}>
                                                                    {dataTax.primary_name} {item.tax}
                                                                </option>
                                                            ))}
                                                            <option value="0">
                                                                {props.t("Do not apply")}
                                                            </option>
                                                        </AvField>
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvCheckboxGroup name="is_promotional">
                                                            <AvCheckbox
                                                                label={props.t("Promotional")}
                                                                value="1"
                                                            />
                                                        </AvCheckboxGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Base Sales Price ($)")}
                                                            type="text"
                                                            name="price"
                                                            value={basePrice}
                                                            onChange={(e) => marginCalc(e.target)}
                                                            disabled={activeMargin ? false : true}
                                                        />
                                                    </div>
                                                    {
                                                        getPermission().other_config.show_margin.is_checked === 0 ? ("") :
                                                        (
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Sales Margin (%)")}
                                                                    type="text"
                                                                    name="sales_margin"
                                                                    value={salesMargin}
                                                                    onChange={(e) => getSalesMargin(e.target.value)}
                                                                    disabled={activeMargin ? true : false}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Discount (%)")}
                                                            type="text"
                                                            value="0.00"
                                                            name="discount"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col lg="3">
                                                    <div className="form-inline company_img img-flex">
                                                        <input
                                                            className="hidden d-none"
                                                            onChange={(e) => setComImgs(e)}
                                                            type="file"
                                                            id="comImgs"
                                                            name="image"
                                                        />
                                                        <label
                                                            className="company_img-box"
                                                            htmlFor="comImgs"
                                                        >
                                                            <img src={imgPreviews} />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateService);
CreateService.propTypes = {
    t: PropTypes.any,
};

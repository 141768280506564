import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function MultiType(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (!props.data || !Array.isArray(props.data)) {
      console.error("Invalid data:", props.data);
      return;
    }

    if (typeof props.t !== "function") {
      console.error("props.t is not a function");
      return;
    }

    const newData = props.data.map((e) => {
      const labelSplit = e.label.split('(');
      const prefix = props.t(labelSplit[0].trim()); 
      const suffix = labelSplit[1] ? `(${labelSplit[1]}` : ""; 
      return {
        ...e, 
        label: `${prefix}${suffix}`, 
      };
    });

    // const labels = newData.map((e) => e.label);
    const labels = [''];
    setData({
      labels,
      datasets: newData,
    });
  }, [props.data, props.t]);

  return (
    <>
      <Bar
        data={data}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </>
  );
}

export default withTranslation()(MultiType);
MultiType.propTypes = {
  t: PropTypes.any,
};


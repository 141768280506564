/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Table,
  Col,
  Row,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import { getCurrentUser } from "../../../helpers/Utils";
import classnames from "classnames";
import Switch from "react-switch";
import CategoryModal from "../../../pages/Category/category-modal";
import uploadImg from "../../../assets/images/def-img.svg";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const axios = require("axios").default;

let varBasePrice;
let varPurchasePrice;

const CreateProductModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [image, setImage] = useState("");
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [selectedCate, setSelectedCate] = useState("");
  const [activeMargin, setActiveMargin] = useState(true);
  const [switch1, setSwitch1] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [referenceType, setReferenceType] = useState("PRO");
  const [wharehouseStock, setWharehouseStock] = useState("");
  const [wharehouseminStock, setWharehouseminStock] = useState("");
  const [wharehouseLocation, setWharehouseLocation] = useState("");
  const [basePrice, setBasePrice] = useState(0.0);
  const [purchasePrice, setPurchasePrice] = useState(0.0);
  const [purchaseMargin, setPurchaseMargin] = useState(0.0);
  const [salesMargin, setSalesMargin] = useState(0.0);
  const [images, setImages] = useState("");
  const [imgPreviews, setImgPreviews] = useState(uploadImg);
  const [dataTax, setDataTax] = useState([]);
  const [defaultTax, setDefaultTax] = useState("");
  const [isOpenModalStock, setIsOpenModalStock] = useState(false);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const createTechnical = (e, value) => {
    setLoading(false);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("price", value.price);
    formData.append("reference", value.reference);
    formData.append("reference_number", value.reference_number);
    formData.append("purchase_price", value.purchase_price);
    formData.append("barcode", value.barcode);
    formData.append("image", images);
    formData.append("product_category_id", selectedCate);
    formData.append("is_active", value.is_active);
    formData.append("description", value.description);
    formData.append("private_comments", e.target.private_comments.value);
    formData.append("created_from", value.created_from);
    formData.append("active_margin", value.active_margin);
    formData.append("purchase_margin", value.purchase_margin);
    formData.append("sales_margin", value.sales_margin);
    formData.append("discount", value.discount);
    formData.append("minimum_price", value.minimum_price);
    formData.append("tax", defaultTax);
    formData.append("is_promotional", value.is_promotional);
    formData.append("manage_stock", switch1 ? "1" : "0");
    formData.append("main_image", images);
    formData.append("stock", wharehouseStock);
    formData.append("minimum_stock", wharehouseminStock);
    formData.append("virtual_stock", wharehouseStock);
    formData.append("location", wharehouseLocation);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/products`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          if (props.selectOption) {
            props.selectOption(res.data.product);
          }
          setSuccess(res.data.message);
          props.getProduct();
          setTimeout(() => {
            props.setProductModal(false);
            props.setMainFormDisabled(false);
          }, 1000);
        } else {
          setError(res.data.message);
          setLoading(false);
          setSuccess("");
        }
      });
  };

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    );
  };

  const getIdMargin = (e) => {
    if (e.target.checked) {
      setActiveMargin(false);
    } else {
      setActiveMargin(true);
    }
  };

  const productCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product_categories`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllCategories(res.data.product_categories);
      });
  };

  let optionCategory = [];
  if (allCategories !== undefined) {
    {
      allCategories &&
        allCategories.map((category) => {
          optionCategory.push({
            value: category.id,
            label: category.name,
          });
        });
    }
  }
  function handleSelectCategory(selectedcate) {
    setSelectedCate(selectedcate.value);
  }

  const setComImg = (e) => {
    setImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const getType = (e) => {
    setReferenceType(e.target.value);
  };

  const marginCalc = (e) => {
    if (e.name === "price") {
      setBasePrice(e.value);
      varBasePrice = e.value;
    } else if (e.name === "purchase_price") {
      setPurchasePrice(e.value);
      varPurchasePrice = e.value;
    } else if (e.name === "sales_margin") {
      setSalesMargin(e.value);
      varSalesMargin = e.value;
    } else if (e.name === "purchase_margin") {
      setPurchaseMargin(e.value);
      varPurchaseMargin = e.value;
    }
    let pMargin = ((varBasePrice - varPurchasePrice) / varPurchasePrice) * 100;
    let sMargin = ((varBasePrice - varPurchasePrice) / varBasePrice) * 100;

    if (
      !isNaN(pMargin) ||
      pMargin == Number.POSITIVE_INFINITY ||
      pMargin == Number.NEGATIVE_INFINITY
    ) {
      setPurchaseMargin(pMargin.toFixed(2));
    }
    if (!isNaN(sMargin)) {
      setSalesMargin(sMargin.toFixed(2));
    }
  };

  const getSalesMargin = (e) => {
    let SM = e;
    let SP = purchasePrice / (1 - SM / 100);
    let NP = SP - purchasePrice;
    let PM = (NP / purchasePrice) * 100;
    setPurchaseMargin(PM.toFixed(2));
    setSalesMargin(SM);
    setBasePrice(SP.toFixed(2));
  };

  const getPurchaseMargin = (e) => {
    let PM = e;
    let NP = (PM * purchasePrice) / 100;
    let SP = parseInt(NP) + parseInt(purchasePrice);
    let SM = (NP / SP) * 100;
    setPurchaseMargin(PM);
    setSalesMargin(SM.toFixed(2));
    setBasePrice(SP.toFixed(2));
  };

  const getTaxes = () => {
    setDataTax([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/cosumption-taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDataTax(res.data.taxes);
          res.data.taxes?.map((item) => {
            if (item.by_default === "1") {
              setDefaultTax(item.tax);
            }
          });
        }
      });
  };

  useEffect(() => {
    productCategories();
    getTaxes();
  }, []);

  const setComImgs = (e) => {
    setImages(e.target.files[0]);
    setImgPreviews(URL.createObjectURL(e.target.files[0]));
  };

  const closeModal = () => {
    props.setProductModal(false);
    props.setMainFormDisabled(false);
  };
  return (
    <>
      <CategoryModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        getAllCategories={productCategories}
      />
      <Modal size="lg" isOpen={isOpenModalStock} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {props.t("Add warehouse")}
          </h5>
          <button
            type="button"
            className="btn btn-primary text-md btn-sm"
            onClick={() => setIsOpenModalStock(false)}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="form-horizontal">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Stock")}
                    type="number"
                    name="stock"
                    onChange={(e) => setWharehouseStock(e.target.value)}
                    value={wharehouseStock}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Minimum Stock")}
                    type="number"
                    name="minimum_stock"
                    onChange={(e) => setWharehouseminStock(e.target.value)}
                    value={wharehouseminStock}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <AvField
                    label={props.t("Virtual Stock")}
                    type="number"
                    readOnly
                    name="virtual_stock"
                    onChange={(e) => setWharehouseStock(e.target.value)}
                    value={wharehouseStock}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Location")}
                    type="text"
                    name="location"
                    onChange={(e) => setWharehouseLocation(e.target.value)}
                    value={wharehouseLocation}
                  />
                </div>
              </div>
            </div>
          </AvForm>
        </div>
        <div className="modal-footer">
          <div className="text-end">
            {loading ? (
              <button className="btn btn-primary" type="button">
                {props.t("Loading")}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setIsOpenModalStock(false)}
              >
                {props.t("Submit")}
              </button>
            )}
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => cencelStock()}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal size="lg" isOpen={props.productModal} centered={true}>
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            createTechnical(e, v);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.t("New Product")}
            </h5>
            <div>
              {loading ? (
                <button
                  className="btn btn-primary me-2 py-2 disabled"
                  type="button"
                  disabled
                >
                  {props.t("Loading")}
                </button>
              ) : (
                <button className="btn btn-primary me-2 py-2" type="submit">
                  {props.t("Save")}
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary text-md btn-sm py-2"
                onClick={() => closeModal()}
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
          </div>
          <div className="modal-body">
            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
            {success ? <Alert color="success">{props.t(success)}</Alert> : null}
            <Row>
              <Col md={5}>
                <div className="mb-3">
                  <label>{props.t("Reference")}</label>
                  <div className="d-flex">
                    <AvField
                      style={{
                        width: "100px",
                      }}
                      type="select"
                      name="reference"
                      value="PRO"
                      onChange={(e) => getType(e)}
                    >
                      {/* <option value="PRO">PRO/Product</option> */}
                      <option value="manual">{props.t("MANUAL/MANUAL")}</option>
                    </AvField>
                    <div className="w-100 ps-3">
                      <AvField
                        className="w-100"
                        type="number"
                        name="reference_number"
                        placeholder={props.t("Automatic")}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Name")}
                    type="text"
                    name="name"
                    required="required"
                    value={props?.name}
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Base Sales Price ($)")}
                    type="text"
                    name="price"
                    value={basePrice}
                    onChange={(e) => marginCalc(e.target)}
                    required="required"
                  />
                </div>
                <div className="mb-3">
                  <AvField label="Barcode" type="text" name="barcode" />
                </div>
                <div className="mb-3">
                  <label>{props.t("Product Category")}</label>
                  <div className="d-flex">
                    <div className="w-100 pe-2">
                      <Select
                        options={optionCategory}
                        onChange={handleSelectCategory}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setIsOpenModal(true);
                      }}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target=".bs-example-modal-lg"
                    >
                      {props.t("New")}
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    label={props.t("Stock")}
                    type="number"
                    name="barcode"
                    value="0.00"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Virtual Stock")}
                    type="number"
                    name="barcode"
                    value="0.00"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Minimum Stock")}
                    type="number"
                    name="barcode"
                    value="0.00"
                    disabled
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="company_img">
                  <label>{props.t("Main image")}</label>
                  <input
                    className="hidden d-none"
                    onChange={(e) => setComImg(e)}
                    type="file"
                    id="comImg"
                  />
                  <label className="company_img-box" htmlFor="comImg">
                    <img src={imgPreview} />
                  </label>
                </div>
              </Col>
            </Row>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("General")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("Commercial")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={`${classnames({
                    active: customActiveTab === "3",
                  })} disabled`}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span className="d-none d-sm-block">{props.t("Rates")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={`${classnames({
                    active: customActiveTab === "4",
                  })}`}
                  onClick={() => {
                    toggleCustom("4");
                  }}
                >
                  <span className="d-none d-sm-block">{props.t("Stock")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={`${classnames({
                    active: customActiveTab === "5",
                  })}`}
                  onClick={() => {
                    toggleCustom("5");
                  }}
                >
                  <span className="d-none d-sm-block">{props.t("Images")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={`${classnames({
                    active: customActiveTab === "6",
                  })} disabled`}
                  onClick={() => {
                    toggleCustom("6");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("Attachments")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={`${classnames({
                    active: customActiveTab === "7",
                  })} disabled`}
                  onClick={() => {
                    toggleCustom("7");
                  }}
                >
                  <span className="d-none d-sm-block">
                    {props.t("History")}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab} className="p-3">
              <TabPane tabId="1">
                <Row>
                  <Col lg="12">
                    <div className="mb-1">
                      <AvCheckboxGroup name="is_active">
                        <AvCheckbox label={props.t("Inactive")} value="1" />
                      </AvCheckboxGroup>
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Description")}
                        type="textarea"
                        name="description"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Private Comments")}
                        type="textarea"
                        name="private_comments"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Created from")}
                        type="text"
                        name="created_from"
                        value={props.t("Web")}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <AvField
                        label={props.t("Purchase Price ($)")}
                        type="text"
                        name="purchase_price"
                        value={purchasePrice}
                        onChange={(e) => marginCalc(e.target)}
                        defaultValue="0.00"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Active Margin")}
                        type="checkbox"
                        name="active_margin"
                        onChange={(e) => getIdMargin(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Purchase Margin (%)")}
                        type="text"
                        name="purchase_margin"
                        value={purchaseMargin}
                        onChange={(e) => getPurchaseMargin(e.target.value)}
                        disabled={activeMargin ? true : false}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Minimum Price ($)")}
                        type="text"
                        name="minimum_price"
                        value="0.00"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        className="form-control"
                        type="select"
                        name="tax"
                        label={props.t("Tax")}
                        value={defaultTax}
                        onChange={(e) => setDefaultTax(e.target.value)}
                      >
                        {dataTax?.map((item, i) => (
                          <option value={item.tax} key={i}>
                            {dataTax.primary_name} {item.tax}%
                          </option>
                        ))}
                        <option value="0">{props.t("Do not apply")}</option>
                      </AvField>
                    </div>
                    <div className="mb-3">
                      <AvCheckboxGroup name="is_promotional">
                        <AvCheckbox label={props.t("Promotional")} value="1" />
                      </AvCheckboxGroup>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <AvField
                        label={props.t("Base Sales Price ($)")}
                        type="text"
                        name="price"
                        value={basePrice}
                        onChange={(e) => marginCalc(e.target)}
                        disabled={activeMargin ? false : true}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Sales Margin (%)")}
                        type="text"
                        name="sales_margin"
                        value={salesMargin}
                        onChange={(e) => getSalesMargin(e.target.value)}
                        disabled={activeMargin ? true : false}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Discount (%)")}
                        type="text"
                        value="0.00"
                        name="discount"
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <label>{props.t("Manage stock")}</label>
                <div className="mb-1">
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                      setSwitch1(!switch1);
                    }}
                    checked={switch1}
                  />
                  {switch1 && (
                    <>
                      {wharehouseStock === "" ? (
                        <button
                          className="btn btn-primary float-end"
                          onClick={() => setIsOpenModalStock(true)}
                          type="button"
                        >
                          {props.t("Add New")}
                        </button>
                      ) : (
                        ""
                      )}
                      <div className="mdb-datatable mt-3">
                        <Table striped className="align-middle">
                          <thead className="table-light">
                            <tr>
                              <th>{props.t("Wharehouse")}</th>
                              <th>{props.t("Stock")}</th>
                              <th>{props.t("Virtual Stock")}</th>
                              <th>{props.t("Minimum Stock")}</th>
                              <th>{props.t("Location")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                role="button"
                                onClick={() => setIsOpenModalStock(true)}
                              >
                                {props.t("Main Warehouse")}
                              </td>
                              <td
                                role="button"
                                onClick={() => setIsOpenModalStock(true)}
                              >
                                {wharehouseStock}
                              </td>
                              <td
                                role="button"
                                onClick={() => setIsOpenModalStock(true)}
                              >
                                {wharehouseminStock}
                              </td>
                              <td
                                role="button"
                                onClick={() => setIsOpenModalStock(true)}
                              >
                                {wharehouseStock}
                              </td>
                              <td
                                role="button"
                                onClick={() => setIsOpenModalStock(true)}
                              >
                                {wharehouseLocation}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {error ? (
                          <Alert color="danger">{props.t(error)}</Alert>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col lg="3">
                    <div className="form-inline company_img img-flex">
                      <input
                        className="hidden d-none"
                        onChange={(e) => setComImgs(e)}
                        type="file"
                        id="comImgs"
                        name="image"
                      />
                      <label className="company_img-box" htmlFor="comImgs">
                        <img src={imgPreviews} />
                      </label>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <div className="text-end">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => closeModal()}
              >
                {props.t("Cancel")}
              </button>
              {loading ? (
                <button
                  className="btn btn-primary ms-2 disabled"
                  type="button"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <button className="btn btn-primary ms-2" type="submit">
                  {props.t("Save")}
                </button>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  );
};

export default withTranslation()(CreateProductModal);
CreateProductModal.propTypes = {
  t: PropTypes.any,
};

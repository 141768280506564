import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import Select from "react-select";
import countryList from "react-select-country-list";
import { getCurrentUser } from "../../helpers/Utils";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const axios = require("axios").default;

let cc = require("currency-codes");

const CreateCompany = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [companyImage, setCompanyImage] = useState([]);
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [allAT, setAllAT] = useState([]);
  const [selectedLang, setselectedLang] = useState("");
  const [selectedNOE, setselectedNOE] = useState("");
  const [selectedFiscalYear, setselectedFiscalYear] = useState("1");
  const [selectedDecimal, setselectedDecimal] = useState("2");
  const [selectedDateFormat, setselectedDateFormat] = useState("dd/mm/yyyy");
  const [selectedCurrency, setselectedCurrency] = useState("EUR");
  const [selectedCR, setselectedCR] = useState("symbol");
  const [selectedMonth, setselectedMonth] = useState("1");
  const [selectedDS, setselectedDS] = useState(".");
  const [simple_color, setsimple_color] = useState(0);
  const [color, setcolor] = useState("");
  const [phone, setPhone] = useState("");

  const onDrag = (c1) => {
    setcolor(c1);
  };

  const companyCreateSubmit = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("commercial_name", value.commercial_name);
    formData.append("country", value.country);
    formData.append("state", value.state);
    formData.append("city", value.city);
    formData.append("address", value.address);
    formData.append("pincode", value.pincode);
    formData.append("phone", phone);
    formData.append("activity_type", value.activity_type);
    formData.append("email", value.email);
    formData.append("legal_registration", value.legal_registration);
    formData.append("corporate_color", color);
    formData.append("register_as", value.register_as);
    formData.append("tin", value.tin);
    formData.append("fax", value.fax);
    formData.append("number_of_employees", selectedNOE);
    formData.append("website", value.website);
    formData.append("language", selectedLang);
    formData.append("time_zone", value.time_zone);
    formData.append("fiscal_start_date", selectedFiscalYear);
    formData.append("fiscal_start_month", selectedMonth);
    formData.append("number_of_decimal", selectedDecimal);
    formData.append("decimal_separator", selectedDS);
    formData.append("pdf_file_download_date_format", selectedDateFormat);
    formData.append("currency", selectedCurrency);
    formData.append("currency_representation", selectedCR);
    formData.append("taxpayer_identification", value.taxpayer_identification);
    formData.append("logo", companyImage);
    axios
      .post(`${process.env.REACT_APP_APIKEY}/companies`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  const getAT = () => {
    axios
      .get(`${process.env.REACT_APP_APIKEY}/activity-types`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setAllAT(res.data.activity_types);
        } else {
          setAllAT([]);
        }
      });
  };

  const setComImg = (e) => {
    setCompanyImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  // NumberOfEmployee/Set NumberOfEmployee
  const optionNOE = [
    { label: "Not defined", value: "not_defined" },
    { label: "Less then 10", value: "less_then_10" },
    { label: "Between 11 and 50", value: "between_11_and_50" },
    { label: "Between 50 and 250", value: "between_50_and_250" },
    { label: "Between 250 and 1000", value: "between_250_and_1000" },
    { label: "More then 1000", value: "more_then_1000" },
  ];
  function handleSelectNOE(selectedNOE) {
    setselectedNOE(selectedNOE.value);
  }
  // Language/Set Language
  const optionLanguage = [
    { label: "English", value: "english" },
    { label: "Spanish", value: "spanish" },
    { label: "Latam Spanish", value: "latam_spanish" },
    { label: "Chilean Spanish", value: "chilean_spanish" },
    { label: "French", value: "french" },
  ];
  function handleSelectLang(selectedLang) {
    setselectedLang(selectedLang.value);
  }

  // FiscalYear/Set FiscalYear
  const optionFiscalYear = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];
  function setFiscalYear(selectedFiscalYear) {
    setselectedFiscalYear(selectedFiscalYear.value);
  }
  // Decimal/Set Decimal
  const optionDecimal = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
  ];
  function setDecimal(selectedDecimal) {
    setselectedDecimal(selectedDecimal.value);
  }
  // DateFormat/Set DateFormat
  const optionDateFormat = [
    { label: "dd/mm/yyyy", value: "dd/mm/yyyy" },
    { label: "mm/dd/yyyy", value: "mm/dd/yyyy" },
    { label: "yyyy/mm/dd", value: "yyyy/mm/dd" },
  ];
  function setDateFormat(selectedDateFormat) {
    setselectedDateFormat(selectedDateFormat.value);
  }
  // Currency/Set Currency
  let optionCurrency = [];
  if (cc.codes() !== undefined) {
    {
      cc.codes().map((currency) => {
        optionCurrency.push({ value: currency, label: currency });
      });
    }
  }
  function setCurrency(selectedCurrency) {
    setselectedCurrency(selectedCurrency.value);
  }
  // CR/Set CR
  const optionCR = [
    { label: "Symbol", value: "Symbol" },
    { label: "ISO Code", value: "iso_Code" },
    { label: "ISO Code and Symbol (only for $)", value: "iso_symbol" },
  ];
  function setCR(selectedCR) {
    setselectedCR(selectedCR.value);
  }
  // Month/Set Month
  const optionMonth = [
    { label: "January", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];
  function setMonth(selectedMonth) {
    setselectedMonth(selectedMonth.value);
  }
  // DS/Set DS
  const optionDS = [
    { label: ".", value: "." },
    { label: ",", value: "," },
  ];
  function setDS(selectedDS) {
    setselectedDS(selectedDS.value);
  }
  const getCountry = (e) => {
    setPhone(e);
  };

  useEffect(() => {
    getAT();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Company | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="create_company">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                companyCreateSubmit(e, v);
              }}
            >
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h6 className="page-title">Create A Company</h6>
                  </Col>
                  <Col md={6}>
                    <div className="float-end d-none d-md-block">
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          Loading
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              {success ? <Alert color="success">{success}</Alert> : null}

              {error ? <Alert color="danger">{error}</Alert> : null}
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-none d-sm-block">General</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-none d-sm-block">Location Settings</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-none d-sm-block">Logo</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={customActiveTab} className="pt-3">
                <TabPane tabId="1">
                  <Row>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <AvField label="Name" type="text" name="name" />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="Commercial Name"
                              type="text"
                              name="commercial_name"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="Address"
                              type="text"
                              name="address"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="State/Province"
                              type="text"
                              name="state"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Phone</label>
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={phone}
                              name="phone"
                              onChange={(e) => getCountry(e)}
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length == 14)
                                  e.preventDefault();
                              }}
                              //   rules={{ required: true }}
                            />
                            {/* <AvField label="Phone" type="text" name="phone" /> */}
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="activity_type"
                              label="Activity Type"
                            >
                              {allAT &&
                                allAT.map((item, i) => (
                                  <option key={i} value={item.activity_type}>
                                    {item.activity_type}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              label="Email"
                              type="text"
                              name="email"
                              required="required"
                            />
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              label="Legal Registration"
                              type="text"
                              name="legal_registration"
                            />
                          </div> */}
                          <div className="mb-3">
                            <label>Corporate Color</label>
                            <Input
                              type="text"
                              name="corporate_color"
                              className="colorpicker-default"
                              value={color}
                              onClick={() => {
                                setsimple_color(!simple_color);
                              }}
                              readOnly
                            />
                            {simple_color ? (
                              <ColorPicker
                                saturationHeight={100}
                                saturationWidth={100}
                                value={color}
                                onDrag={onDrag}
                              />
                            ) : null}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <label>I am</label>
                            <AvRadioGroup
                              name="register_as"
                              className="radiobutton"
                            >
                              <AvRadio label="a Company" value="company" />
                              <AvRadio label="Freelancer" value="freelancer" />
                            </AvRadioGroup>
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="RUC"
                              type="number"
                              name="tin"
                              onKeyPress={(e) => {
                                if (e.target.value.length == 13)
                                  e.preventDefault();
                              }}
                              required="required"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="Zip/Postal Code"
                              type="text"
                              name="pincode"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="City/Town"
                              type="text"
                              name="city"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField label="Fax" type="text" name="fax" />
                          </div>
                          <div className="mb-3">
                            <label>Number of Employees</label>
                            <Select
                              options={optionNOE}
                              onChange={handleSelectNOE}
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="Website"
                              type="email"
                              name="website"
                              required
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <label>Language</label>
                            <Select
                              onChange={(e) => handleSelectLang(e)}
                              options={optionLanguage}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label="Time Zone"
                              type="text"
                              name="time_zone"
                              defaultValue="America/Guayaquil"
                              disabled
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="country"
                              label="Country"
                              value={getCurrentUser().company_country}
                            >
                              {countryList().data.map((item, i) => (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <label>Start date for your fiscal year</label>
                            <Select
                              options={optionFiscalYear}
                              onChange={setFiscalYear}
                            />
                          </div>
                          <div className="mb-3">
                            <label>Number of decimals</label>
                            <Select
                              options={optionDecimal}
                              onChange={setDecimal}
                            />
                          </div>
                          <div className="mb-3">
                            <label>Format of the dates</label>
                            <Select
                              options={optionDateFormat}
                              onChange={setDateFormat}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <label>Currency</label>
                            <Select
                              options={optionCurrency}
                              onChange={setCurrency}
                            />
                          </div>
                          <div className="mb-3">
                            <label>Currency Representation</label>
                            <Select options={optionCR} onChange={setCR} />
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              label="Ruc"
                              type="text"
                              name="taxpayer_identification"
                              value="TIN"
                            />
                          </div> */}
                          <div className="mb-3">
                            <label>Fiscal year start month</label>
                            <Select options={optionMonth} onChange={setMonth} />
                          </div>
                          <div className="mb-3">
                            <label>Decimal separator</label>
                            <Select options={optionDS} onChange={setDS} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="company_img">
                        <input
                          className="hidden d-none"
                          onChange={(e) => setComImg(e)}
                          type="file"
                          id="comImg"
                        />
                        <label className="company_img-box" htmlFor="comImg">
                          <img src={imgPreview} />
                        </label>
                        <span className="text-muted mt-3 d-md-block">
                          We recommend using high quality images for a better
                          visual performance.
                        </span>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </AvForm>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCompany;

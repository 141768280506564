/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let selectedRoleId = [];
let selectedRoleType = [];
let selectedRoleNum = [];
const ListPurchaseOrder = (props) => {
  // const [displayInvoices, setDisplayInvoices] = useState(props.data);
  const [displayPurchaseOrder, setDisplayPurchaseOrder] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const delUser = (id) => {
    setdynamic_title("");
    setdynamic_description("");
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/purchase-tables/${id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setdynamic_title("Deleted");
          setdynamic_description("The purchase order has been deleted.");
          setDisplayPurchaseOrder(null);
        } else {
          setdynamic_title("Failed");
          setdynamic_description(res.data.message);
        }
        setsuccess_dlg(true);
      });
    // .then(function (res) {
    //   // setDisplayInvoices(null);
    // setDisplayPurchaseOrder(null)
    //   setdynamic_description(res.data.message)
    // })
  };

  useEffect(() => {
    selectedRoleId = [];
    selectedRoleType = [];
    selectedRoleNum = [];
  }, []);

  const getSelectedId = (e, check, type, num) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      selectedRoleType.push(type);
      selectedRoleNum.push(num);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueType = selectedRoleType.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueNum = selectedRoleNum.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      selectedRoleType = uniqueType;
      selectedRoleNum = uniqueNum;
      props.setSelectedIds(selectedRoleId);
      props.setSelectedType(selectedRoleType);
      props.setSelectedNum(selectedRoleNum);
    } else {
      selectedRoleId = props.selectedIds;
      selectedRoleType = props.selectedType;
      selectedRoleNum = props.selectedNum;
      var array = selectedRoleId;
      var array2 = selectedRoleType;
      var array3 = selectedRoleNum;
      var index = array.indexOf(e);
      var index2 = array2.indexOf(type);
      var index3 = array3.indexOf(num);
      if (index !== -1) {
        array.splice(index, 1);
      }
      if (index2 !== -1) {
        array2.splice(index2, 1);
      }
      if (index2 !== -1) {
        array3.splice(index3, 1);
      }
      props.setSelectedIds(selectedRoleId);
      props.setSelectedType(selectedRoleType);
      props.setSelectedNum(selectedRoleNum);
    }
  };

  if (displayPurchaseOrder === null) {
    return "";
  } else
    return (
      <>
        {displayPurchaseOrder !== null ? (
          <tr>
            <td>
              <input
                onChange={(e) =>
                  getSelectedId(
                    displayPurchaseOrder.id,
                    e,
                    displayPurchaseOrder.reference_type,
                    displayPurchaseOrder.reference_number
                  )
                }
                name="chk"
                value={displayPurchaseOrder.id}
                className="p-0 d-inline-block"
                type="checkbox"
              />
            </td>
            <td role="button" className="py-1">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.reference}
                {displayPurchaseOrder.reference_number}
              </Link>
            </td>
            <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.supplier_name}
              </Link>
            </td>
            <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.purchase_document_ref}
              </Link>
            </td>
            {/* <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.title}
              </Link>
            </td> */}
            <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.created_by_name}
              </Link>
            </td>
            <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {props.t(
                  displayPurchaseOrder.status == "pending"
                    ? "Pending"
                    : displayPurchaseOrder.status == "refused"
                    ? "Refused"
                    : displayPurchaseOrder.status == "received"
                    ? "Received"
                    : displayPurchaseOrder.status
                )}
              </Link>
            </td>
            <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.date}
              </Link>
            </td>
            <td role="button">
              <Link to={`/purchase-order/${displayPurchaseOrder.id}`}>
                {displayPurchaseOrder.amount == "0.00"
                  ? "0.00"
                  : displayPurchaseOrder.amount}
              </Link>
            </td>
            <td>
              {getPermission().purchase_orders.purchase_orders.delete
                .is_checked === 1 ? (
                <button
                  type="button-input"
                  className="btn btn-danger"
                  onClick={() => setconfirm_alert(true)}
                >
                  {props.t("Delete")}
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
        ) : (
          ""
        )}

        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              delUser(displayPurchaseOrder.id);
              setconfirm_alert(false);
              // setsuccess_dlg(true)
              // setdynamic_title('Deleted')
              // setdynamic_description('File has been deleted.')
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}
        {success_dlg ? (
          <SweetAlert
            success={dynamic_title == "Deleted" ? true : false}
            error={dynamic_title == "Failed" ? true : false}
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
};
export default withTranslation()(ListPurchaseOrder);
ListPurchaseOrder.propTypes = {
  t: PropTypes.any,
};

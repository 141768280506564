import React, { useState, useEffect } from "react";
// import RateModal from "../../../pages/PriceRate/rate-modal";
import RateModal from "./rates-modal";
import { Alert, Col, Row, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import SingleList from "../../../pages/PriceRate/rateSingleList";
import Loader from "../../Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function ProductRates(props) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allRates, setAllRates] = useState([]);
  const [loader, setLoader] = useState(<Loader />);

  const getAllRates = () => {
    setAllRates();
    setLoading(true);
    if (props.type == "service") {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/service-rates?service_id=${props.product.id}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoading(false);
          setLoader("");
          if (res.data.status) {
            setAllRates(res.data.rates);
            setError("");
          } else {
            setError(res.data.message);
          }
        });
    } else {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/product-rates?product_id=${props.product.id}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoading(false);
          setLoader("");
          if (res.data.status) {
            setAllRates(res.data.rates);
            setError("");
          } else {
            setError(res.data.message);
          }
        });
    }
  };
  //   const getAllRates = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //           "companyId"
  //         )}/rates`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getCurrentUser().token}`,
  //           },
  //         }
  //       )
  //       .then(function (res) {
  //         setLoader("");
  //         if (res.data.status) {
  //           setAllRates(res.data.rates);
  //           setError("");
  //         } else {
  //           setError(res.data.message);
  //         }
  //       });
  //   };

  useEffect(() => {
    getAllRates();
  }, []);

  return (
    <>
      {isOpenModal ? (
        <RateModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          getAllRates={getAllRates}
          product={props.product}
          type={props.type}
        />
      ) : null}
      <div className="page-title-box pb-0">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">{props.t("Rates")}</h6>
          </Col>
          <Col md={6}>
            <div className="float-end d-flex">
              <button
                type="button"
                onClick={() => {
                  setIsOpenModal(true);
                }}
                className="btn btn-primary"
                data-toggle="modal"
                data-target=".bs-example-modal-lg"
              >
                {props.t("New")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mdb-datatable">
        <div className="table-responsive">
          <Table striped className="align-middle">
            <thead>
              <tr>
                <th>{props.t("Rate")}</th>
                <th>{props.t("Price Rate")}</th>
                <th>{props.t("Discount (%)")}</th>
                {
                  getPermission().other_config.show_margin.is_checked === 0 ? ("") : 
                  (
                    <>
                      <th>{props.t("Purchase Margin (%)")}</th>
                      <th>{props.t("Sales Margin (%)")}</th>
                    </>
                  )                                              
                }
              </tr>
            </thead>
            <tbody>
              {allRates &&
                allRates.map((item, i) => (
                  <SingleList
                    data={item}
                    key={i}
                    product={props.product}
                    productRates={props.productRates}
                    type={props.type}
                  />
                ))}
            </tbody>
          </Table>
        </div>
        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
        {loader}
      </div>
    </>
  );
}
export default withTranslation()(ProductRates);
ProductRates.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useEffect, useRef } from "react";
import { Modal, Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const DownloadOption = (props) => {
  const [change, setChange] = useState(false);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      props.getDownloadPDF();
    }
  }, [change]);
  return (
    <div>
      <Modal size="xl" isOpen={props.isDownloadModal} centered={true}>
        <AvForm className="form-horizontal">
          <div>
            <Row>
              <Col md={6} className="p-5 pt-3">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  Download PDF
                </h5>
                <div className="mb-3 mt-3">
                  <label>Reference:{props.refNum}</label>
                </div>
                <div>
                  <h5>PDF Options</h5>
                  <div className="mb-3">
                    <AvField
                      label="Format"
                      type="select"
                      name="format"
                      onChange={(e) => {
                        props.setFormat(e.target.value), setChange(!change);
                      }}
                    >
                      {props.type == "Normal Invoice" ||
                      props.type == "Refund Invoice" ||
                      props.type == "Purchase Invoice" ? (
                        <>
                          <option value="" hidden>
                            Select
                          </option>
                          <option value="valued">Valued</option>
                          <option value="ticket">Ticket</option>
                        </>
                      ) : (
                        <>
                          <option value="" hidden>
                            Select
                          </option>
                          <option value="valued">Valued</option>
                          <option value="Without_values">Without values</option>
                          <option value="before_tax">Before tax</option>
                          <option value="Pro_forma">Pro Forma</option>
                          <option value="without_totals">Without totals</option>
                          <option value="ticket">Ticket</option>
                        </>
                      )}
                    </AvField>
                  </div>

                  <div className="mb-3">
                    {" "}
                    <AvField
                      label="Template"
                      type="select"
                      name="template_id"
                      onClick={(e) => {
                        props.setTemplate1(e.target.value), setChange(!change);
                      }}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {props.templateData?.map((item, i) => (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      ))}
                    </AvField>
                  </div>

                  <AvField
                    label="Prices after tax"
                    name="prices_tax"
                    type="checkbox"
                    onChange={(e) => {
                      props.setPriceTax(e.target.checked), setChange(!change);
                    }}
                  />

                  <AvField
                    label="Mailing format"
                    name="mailing_format"
                    type="checkbox"
                    onChange={(e) => {
                      props.setMailingFormat(e.target.checked),
                        setChange(!change);
                    }}
                  />

                  <AvField
                    label="Include the main image of the products/services"
                    name="product_service"
                    type="checkbox"
                    onChange={(e) => {
                      props.setProductService(e.target.checked),
                        setChange(!change);
                    }}
                  />

                  <AvField
                    label="Save this as the default configuration"
                    name="default_config"
                    type="checkbox"
                    onChange={(e) => {
                      props.setDefaultConfig(e.target.checked),
                        setChange(!change);
                    }}
                  />
                  <AvField
                    label="Disable signed box"
                    name="disable_signed"
                    type="checkbox"
                    onChange={(e) => {
                      props.setSignedBox(e.target.checked), setChange(!change);
                    }}
                  />
                </div>
                <div className="float-end d-flex">
                  <button
                    className="btn btn-primary me-2"
                    type="button"
                    onClick={() => props.setIsDownloadModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => props.getDownloadPDF2()}
                  >
                    {props.t("Download")}
                  </button>
                </div>
              </Col>
              <Col md={6}>
                <iframe
                  src={props.link}
                  style={{ width: "100%", height: "100%" }}
                ></iframe>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
    </div>
  );
};

export default withTranslation()(DownloadOption);
DownloadOption.propTypes = {
  t: PropTypes.any,
};

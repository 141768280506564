import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import printImg from "../../assets/images/print.png";

function DefaultPrintButtons(props) {
    return (
        <>
            <div
                className="d-flex align-items-center ms-2"
                style={{ cursor: "pointer" }}
                title={props.t('Quick print')}
                onClick={() => props.getprint()}
            >
                <img
                    src={printImg}
                    width='30px'
                    height='30px'
                />
            </div>
        </>
    );
}
export default withTranslation()(DefaultPrintButtons);
DefaultPrintButtons.propTypes = {
    t: PropTypes.any,
};

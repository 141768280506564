import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
const axios = require("axios").default;
import AllInvoices from "../../components/AllInvoices/all-invoices";
import Loader from "../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
    getCurrentUser,
    getPermission,
    getFilterDate,
    setFilterDate,
} from "../../helpers/Utils";

import MultipleChoice from "../../components/filters/multiChoice";
import DateFilter from "../../components/filters/dateFilter";
import InputFilter from "../../components/filters/searchFilter";
import AmountFilter from "../../components/filters/amountFilter";

const PendingInvoiceSummary = (props) => {
    const [invoiceData, setInvoiceData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [timer, setTimer] = useState(null);

    const [sort, setSort] = useState(false);
    const [sort2, setSort2] = useState(false);
    const [sort3, setSort3] = useState(false);
    const [sort4, setSort4] = useState(false);
    const [sort5, setSort5] = useState(false);
    const [sort6, setSort6] = useState(false);
    const [sort7, setSort7] = useState(false);
    const [sort8, setSort8] = useState(false);
    const [sort9, setSort9] = useState(false);
    const [sort10, setSort10] = useState(false);
    const [ref_filter, setRef_filter] = useState(false);
    const [cliName_filter, setCliName_filter] = useState(false);
    const [title_filter, setTitle_filter] = useState(false);
    const [createdby_filter, setCreatedBy_filter] = useState(false);
    const [amount_filter, setAmount_filter] = useState(false);
    const [statusINV_filter, setstatusINV_filter] = useState(false);
    const [statusVeronica_filter, setstatusVeronica_filter] = useState(false);
    const [date_filter, setdate_filter] = useState(false);

    const [searchValue, setSearchValue] = useState('');

    const getSearchData = (e) => {
        setSearchValue(e);
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getInvoiceData(e);
        }, 1000);
        setTimer(newTimer);
    };


    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "client_name") {
            localStorage.setItem("clientName", e);
        }
        if (type == "title") {
            localStorage.setItem("title", e);
        }
        if (type == "created_by_name") {
            localStorage.setItem("createdBy", e);
        }
        if (type == "statusINV") {
            localStorage.setItem("statusINV", e);
        }
        if (type == "statusVeronica") {
            localStorage.setItem("statusVeronica", e);
        }
        if (type == "date") {
            setFilterDate(e);
        }
        if (type == "amount") {
            localStorage.setItem("amount", e);
        }
        if (type == "sort") {
            localStorage.setItem("leadSort", e);
        }
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoices?type=Normal Invoice&status=${localStorage.getItem("statusINV") !== null
                    ? localStorage.getItem("statusINV")
                    : ""
                }&veronica_status=${localStorage.getItem("statusVeronica") !== null
                    ? localStorage.getItem("statusVeronica")
                    : ""
                }&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&title=${localStorage.getItem("title") !== null
                    ? localStorage.getItem("title")
                    : ""
                }&client_name=${localStorage.getItem("clientName") !== null
                    ? localStorage.getItem("clientName")
                    : ""
                }&created_by_name=${localStorage.getItem("createdBy") !== null
                    ? localStorage.getItem("createdBy")
                    : ""
                }&amount=${localStorage.getItem("amount") !== null
                    ? localStorage.getItem("amount")
                    : ""
                }&date_start_date=${getFilterDate() && getFilterDate().from !== null
                    ? getFilterDate().from
                    : ""
                }&date_end_date=${getFilterDate() && getFilterDate().to !== null
                    ? getFilterDate().to
                    : ""
                }&sortBy=${localStorage.getItem("leadSort") !== null
                    ? localStorage.getItem("leadSort")
                    : ""
                }&search=${searchValue}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setInvoiceData(res.data.data.data);
                    setRef_filter(false);
                    setCliName_filter(false);
                    setTitle_filter(false);
                    setdate_filter(false);
                    setCreatedBy_filter(false);
                    setAmount_filter(false);
                    setstatusINV_filter(false);
                    setstatusVeronica_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    const getInvoiceData = (e) => {
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/sent-invoice-summary?search=${e !== undefined ? e : ""}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setInvoiceData(res.data.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getInvoiceData()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Invoice-summary | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">
                                        {props.t("Pending Invoice Summary")}
                                    </h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                value={searchValue}
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                    />
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                        {sort ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort2(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "reference_number,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "619%"
                                                                                : "806%"
                                                                                }, 1px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort2(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "reference_number,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "693%"
                                                                                : "879%"
                                                                                }, 0px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort(true),
                                                                            setSort2(false),
                                                                            setSort3(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "reference_number,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "619%"
                                                                                : "806%"
                                                                                }, 1px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort2(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "reference_number,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "693%"
                                                                                : "879%"
                                                                                }, 0px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        )}
                                                        {sort2 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort2(false),
                                                                            setSort(true),
                                                                            setSort3(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "reference_number,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "693%"
                                                                                : "879%"
                                                                                }, 0px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Client")}
                                                        {cliName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCliName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("clientName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCliName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCliName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            cliName_filter={cliName_filter}
                                                            setCliName_filter={setCliName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                        {sort5 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort6(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort4(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "client_name,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "611%"
                                                                                : "535%"
                                                                                }, 1px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort6(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort4(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "client_name,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "685%"
                                                                                : "607%"
                                                                                }, 0px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort5(true),
                                                                            setSort2(false),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort3(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "client_name,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "611%"
                                                                                : "535%"
                                                                                }, 1px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort6(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort4(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "client_name,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "685%"
                                                                                : "607%"
                                                                                }, 0px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        )}
                                                        {sort6 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort2(false),
                                                                            setSort5(true),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort3(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "client_name,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: `translate(${localStorage.getItem("i18nextLng") ===
                                                                                "sp"
                                                                                ? "685%"
                                                                                : "607%"
                                                                                }, 0px)`,
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("E-billing status")}
                                                        {statusVeronica_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() =>
                                                                        setstatusVeronica_filter(false)
                                                                    }
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("statusVeronica") ==
                                                                    "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() =>
                                                                            setstatusVeronica_filter(true)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() =>
                                                                            setstatusVeronica_filter(true)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <MultipleChoice
                                                            statusVeronica_filter={statusVeronica_filter}
                                                            setstatusVeronica_filter={
                                                                setstatusVeronica_filter
                                                            }
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Created by")}
                                                        {createdby_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCreatedBy_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("createdBy") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCreatedBy_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCreatedBy_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            createdby_filter={createdby_filter}
                                                            setCreatedBy_filter={setCreatedBy_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                        {sort3 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort4(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "created_by_name,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: "translate(840%, 1px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort4(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "created_by_name,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(914%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort3(true),
                                                                            setSort2(false),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "created_by_name,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: "translate(840%, 1px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort4(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "created_by_name,ASC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(914%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        )}
                                                        {sort4 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort2(false),
                                                                            setSort3(true),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList(
                                                                                "created_by_name,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(914%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Status")}
                                                        {statusINV_filter ? (
                                                            <>
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setstatusINV_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("statusINV") == "" ? (
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setstatusINV_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setstatusINV_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <MultipleChoice
                                                            statusINV_filter={statusINV_filter}
                                                            setstatusINV_filter={setstatusINV_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Date")}
                                                        {date_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdate_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {getFilterDate() &&
                                                                    getFilterDate().from == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setdate_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setdate_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <DateFilter
                                                            date_filter={date_filter}
                                                            setdate_filter={setdate_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                        {sort9 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort10(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort4(false),
                                                                            getSearchedList("date,ASC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: "translate(475%, 1px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort10(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort4(false),
                                                                            getSearchedList("date,ASC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(548%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort9(true),
                                                                            setSort2(false),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort3(false),
                                                                            setSort10(false),
                                                                            getSearchedList("date,DESC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: "translate(475%, 1px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort10(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort9(false),
                                                                            setSort4(false),
                                                                            getSearchedList("date,ASC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(548%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        )}
                                                        {sort10 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort2(false),
                                                                            setSort9(true),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort5(false),
                                                                            setSort6(false),
                                                                            setSort7(false),
                                                                            setSort8(false),
                                                                            setSort3(false),
                                                                            setSort10(false),
                                                                            getSearchedList("date,DESC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(548%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Amount")}
                                                        {amount_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setAmount_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("amount") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setAmount_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setAmount_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <AmountFilter
                                                            amount_filter={amount_filter}
                                                            setAmount_filter={setAmount_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Activity")}</th>
                                                <th>{props.t("Margin")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Unpaid")}
                                                        {sort7 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort8(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort4(false),
                                                                            setSort7(false),
                                                                            setSort6(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList("amount_due,ASC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: "translate(396%, 5px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort8(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort4(false),
                                                                            setSort7(false),
                                                                            setSort6(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList("amount_due,ASC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(470%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort7(true),
                                                                            setSort2(false),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort3(false),
                                                                            setSort6(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            setSort5(false),
                                                                            setSort8(false),
                                                                            getSearchedList(
                                                                                "amount_due,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-up ms-2"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            top: "0",
                                                                            transform: "translate(396%, 5px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSort8(true),
                                                                            setSort(false),
                                                                            setSort3(false),
                                                                            setSort2(false),
                                                                            setSort5(false),
                                                                            setSort4(false),
                                                                            setSort7(false),
                                                                            setSort6(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            getSearchedList("amount_due,ASC", "sort");
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(470%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        )}
                                                        {sort8 ? (
                                                            <>
                                                                <span
                                                                    className="fs-5"
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => {
                                                                        setSort2(false),
                                                                            setSort7(true),
                                                                            setSort(false),
                                                                            setSort4(false),
                                                                            setSort3(false),
                                                                            setSort6(false),
                                                                            setSort9(false),
                                                                            setSort10(false),
                                                                            setSort5(false),
                                                                            setSort8(false),
                                                                            getSearchedList(
                                                                                "amount_due,DESC",
                                                                                "sort"
                                                                            );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-sort-down"
                                                                        style={{
                                                                            position: "absolute",
                                                                            left: "0",
                                                                            bottom: "0",
                                                                            transform: "translate(470%, 0px)",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceData &&
                                                invoiceData.map((item, i) => (
                                                    item.amount_due > 0 &&
                                                    <AllInvoices
                                                        key={i}
                                                        data={item}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(PendingInvoiceSummary);
PendingInvoiceSummary.propTypes = {
    t: PropTypes.any,
};

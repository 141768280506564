import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let selectedRoleId = [];

function SingleList(props) {
    const [user, setUser] = useState(props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, setError_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const deleteUser = (Id) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/${Id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_description(res.data.message);
                    setconfirm_alert(false);
                    setsuccess_dlg(true);
                    setdynamic_title("Deleted");
                    setUser(null);
                } else {
                    setdynamic_description(res.data.message);
                    setconfirm_alert(false);
                    setError_dlg(true);
                    setdynamic_title("Can't be deleted");
                }
            });
    };
    useEffect(() => {
        selectedRoleId = [];
    }, []);
    const getSelectedId = (e, check) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
            props.setSelectedIds(selectedRoleId);
        } else {
            selectedRoleId = props.selectedIds;
            var array = selectedRoleId;
            var index = array.indexOf(e);
            if (index !== -1) {
                array.splice(index, 1);
            }
            props.setSelectedIds(selectedRoleId);
        }
    };
    if (user === null) {
        return "";
    } else
        return (
            <>
                <tr>
                    <td>
                        <input
                            onChange={(e) => getSelectedId(user.id, e)}
                            name="chk"
                            value={user.id}
                            className="p-0 d-inline-block"
                            type="checkbox"
                        />
                    </td>
                    <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>
                            {user.reference}
                            {user.reference_number}
                        </Link>
                    </td>
                    <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>{user.legal_name}</Link>
                    </td>
                    {/* <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>{user.name}</Link>
                    </td> */}
                    <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>{user.tin}</Link>
                    </td>
                    <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>{user.phone_1}</Link>
                    </td>
                    <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>{user.email}</Link>
                    </td>
                    <td role="button" className="py-1">
                        <Link to={`/client/${user.id}`}>{getCurrentUser().name}</Link>

                        {/* <Link to={`/client/${user.id}`}>
              <span className="badge rounded-pill bg-success">Yes</span>
            </Link> */}
                    </td>
                    <td className="text-end">
                        {getPermission().clients.clients.delete.is_checked === 1 ? (
                            <Button
                                color="danger"
                                onClick={() => {
                                    setconfirm_alert(true);
                                }}
                            >
                                {props.t("Delete")}
                            </Button>
                        ) : (
                            ""
                        )}
                    </td>
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel

                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            deleteUser(user.id);
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
                {error_dlg ? (
                    <SweetAlert
                        error
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setError_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
            </>
        );
}
export default withTranslation()(SingleList);
SingleList.propTypes = {
    t: PropTypes.any,
};

import React, { useState } from "react";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { Modal, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function NewPayment(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const CreateNewPayment = (e, value) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    let by_default = "";
    let link_bank_account = "";
    formData.append("name", value.name);
    formData.append("description", value.description);
    if (e.target.by_default.value == "on") {
      by_default = 1;
    }
    formData.append("by_default", by_default);

    if (e.target.link_bank_account.value == "on") {
      link_bank_account = 1;
    }
    formData.append("link_bank_account", link_bank_account);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          if (props.setPaymentOption !== undefined) {
            props.setPaymentOption(res.data.payment_option.id);
          }
          if (props.CategoriesClient !== undefined) {
            props.CategoriesClient();
          }
          if (props.setIsOpenModal !== undefined) {
            props.setIsOpenModal(false);
          }
          if (props.NewpaymentShow !== undefined) {
            props.NewpaymentShow();
          }
        } else {
          setError(res.data.message);
        }
      });
  };

  return (
    <Modal size="lg" isOpen="true" centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          {props.t("New payment option")}
        </h5>
        <button
          type="button"
          className="cross__btn btn btn-primary waves-effect waves-light"
          onClick={() => props.setIsOpenModal(false)}
          aria-hidden="true"
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            CreateNewPayment(e, v);
          }}
        >
          {error ? (
            <div className="mb-3">
              <p className="alert alert-danger">{props.t(error)}</p>
            </div>
          ) : (
            ""
          )}
          <div className="mb-3 required">
            <AvField label={props.t("Name")} type="text" name="name" />
          </div>
          <div className="mb-3">
            <label>{props.t("By default")}:</label>
            <Input type="checkbox" className="ms-2" name="by_default" />
          </div>
          <div className="mb-3">
            <label>{props.t("Linked to bank account")}:</label>
            <Input type="checkbox" className="ms-2" name="link_bank_account" />
          </div>
          <div className="mb-3">
            <AvField
              label={props.t("Description")}
              type="textarea"
              name="description"
            />
          </div>
          <div className="text-end">
            {loading ? (
              <button
                className="btn btn-primary  disabled"
                type="button"
                disabled
              >
                {props.t("Loading")}
              </button>
            ) : (
              <button className="btn btn-primary " type="submit">
                {props.t("Submit")}
              </button>
            )}
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => props.setIsOpenModal(false)}
            >
              {props.t("Cancel")}
            </button>
          </div>
        </AvForm>
      </div>
    </Modal>
  );
}
export default withTranslation()(NewPayment);
NewPayment.propTypes = {
  t: PropTypes.any,
};

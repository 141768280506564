import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getPermission, getCurrentUser } from "../../helpers/Utils";
import MultipleValueTextInput from "react-multivalue-text-input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SendEmail = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [change, setChange] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [isEmail, setIsEmail] = useState();
  const [emailList, setEmailList] = useState([]);
  const getTemplate = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/my_templates?type=${props.type}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setTemplateData(res.data.templates);
        } else {
          setError(res.data.message);
        }
      });
  };
  useEffect(() => {
    getTemplate();
  }, []);
  
  useEffect(() => {
    if (Array.isArray(props.email)) {
      const filteredEmails = props.email.filter(email => email !== null);
      setEmailList(filteredEmails);
    }
  }, [props.email]);

  useEffect(() => {
    props.getSendEmail();
    setIsEmail(props?.email)
  }, [change]);

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpenModal} centered={true}>
        {props.success ? <Alert color="success">{props.success}</Alert> : null}
        {props.error ? <Alert color="danger">{props.error}</Alert> : ""}
        <AvForm className="form-horizontal">
          <div>
            <Row>
              <Col md={5} className="p-5 pt-3">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  {props.t("Send")} {props.t(props.type)}
                </h5>
                <div className="mt-3 mb-3"></div>
                {showEmail ? (
                  <>
                    {" "}
                    <div className="mb-2">
                      <label>{props.t("Send To")}</label>
                      <MultipleValueTextInput
                        required
                        onItemAdded={(item, allItems) => {
                          console.log(`Item added: ${item}`),
                            props.setEmail(allItems),
                            setIsEmail(allItems);
                        }}
                        onItemDeleted={(item, allItems) => {
                          console.log(`Item removed: ${item}`),
                            props.setEmail(allItems),
                            setIsEmail(allItems);
                        }}
                        name="item-input"
                        className="form-control"
                        // values={props.email}
                        values={emailList}
                        enterKeyHint="done"
                      />
                      <span className="fst-italic">
                        {props.t("Press Enter or Comma button after typing your email.")}
                      </span>
                    </div>
                    <div className="mb-2">
                      <label>Cc</label>
                      <MultipleValueTextInput
                        required
                        onItemAdded={(item, allItems) => {
                          console.log(`Item added: ${item}`),
                            props.setEmail2(allItems);
                        }}
                        onItemDeleted={(item, allItems) => {
                          console.log(`Item removed: ${item}`),
                            props.setEmail2(allItems);
                        }}
                        name="item-input"
                        className="form-control"
                        enterKeyHint="done"
                      // value={props.email2}
                      // onChange={(e) => props.setEmail2(e.target.value)}
                      />
                      <span className="fst-italic">
                        {props.t("Press Enter or Comma button after typing your email.")}
                      </span>
                    </div>
                    <div>
                      <ReactQuill
                        theme="snow"
                        value={props.t(props.messageData)}
                        onChange={props.setMessageData}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <h5>{props.t("PDF Options")}</h5>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Format")}
                        type="select"
                        name="format"
                        value={props.format}
                        onChange={(e) => {
                          props.setFormat(e.target.value), setChange(!change);
                        }}
                      >
                        {props.type == "Sales Order" ||
                          props.type == "Work Order" ||
                          props.type == "Purchase Order" ? (
                          <>
                            <option value="valued">{props.t("Valued")}</option>
                            <option value="Without_values">
                              {props.t("Without values")}
                            </option>
                            <option value="before_tax">{props.t("Before tax")}</option>
                            <option value="without_totals">
                              {props.t("Without totals")}
                            </option>
                          </>
                        ) : (
                          <>
                            {props.type == "Sales Delivery Note" ||
                              props.type == "Work Delivery Note" ||
                              props.type == "Purchase Delivery Note" ? (
                              <>
                                <option value="valued">{props.t("Valued")}</option>
                                <option value="Without_values">
                                  {props.t("Without values")}
                                </option>
                                <option value="without_totals">
                                  {props.t("Without totals")}
                                </option>
                              </>
                            ) : (
                              <>
                                {props.type == "Normal Invoice" ||
                                  props.type == "Refund Invoice" ? (
                                  <>
                                    <option value="valued">{props.t("Valued")}</option>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <option value="valued">{props.t("Valued")}</option>
                                    <option value="Without_values">
                                      {props.t("Without values")}
                                    </option>
                                    <option value="before_tax">
                                      {props.t("Before tax")}
                                    </option>
                                    <option value="Pro_forma">Pro Forma</option>
                                    <option value="without_totals">
                                      {props.t("Without totals")}
                                    </option>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </AvField>
                    </div>

                    <div className="mb-3">
                      {" "}
                      <AvField
                        label={props.t("Template")}
                        type="select"
                        name="template_id"
                        onChange={(e) => {
                          props.setTemplate1(e.target.value),
                            setChange(!change);
                        }}
                      >
                        <option value="0">{props.t("Document type template")} </option>
                        {templateData?.map((item, i) => (
                          <option value={item.id} key={i}>
                            {props.t(item.name)}
                          </option>
                        ))}
                      </AvField>
                    </div>

                    <AvField
                      label={props.t("Prices after tax")}
                      name="prices_tax"
                      type="checkbox"
                      defaultChecked={
                        props.priceTax && props.priceTax == "1" ? true : false
                      }
                      onChange={(e) => {
                        props.setPriceTax(e.target.checked), setChange(!change);
                      }}
                    />

                    <AvField
                      label={props.t("Mailing format")}
                      name="mailing_format"
                      type="checkbox"
                      defaultChecked={
                        props.mailingFormat && props.mailingFormat == "1"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        props.setMailingFormat(e.target.checked),
                          setChange(!change);
                      }}
                    />

                    <AvField
                      label={props.t("Include the main image of the products/services")}
                      name="product_service"
                      type="checkbox"
                      defaultChecked={
                        props.productService && props.productService == "1"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        props.setProductService(e.target.checked),
                          setChange(!change);
                      }}
                    />

                    <AvField
                      label={props.t("Save this as the default configuration")}
                      name="default_config"
                      type="checkbox"
                      onChange={(e) => {
                        props.setDefaultConfig(e.target.checked),
                          setChange(!change);
                      }}
                    />
                    <AvField
                      label={props.t("Disable signed box")}
                      name="disable_signed"
                      type="checkbox"
                      onChange={(e) => {
                        props.setSignedBox(e.target.checked),
                          setChange(!change);
                      }}
                    />
                  </div>
                )}
                <div className="float-end d-flex mt-5">
                  <button
                    className="btn btn-primary"
                    onClick={() => props.setIsOpenModal(false)}
                    type="submit"
                  >
                    {props.t("Cancel")}
                  </button>
                  {getPermission().clients.clients.edit.is_checked === 1 ? (
                    showEmail ? (
                      <>
                        {" "}
                        <span
                          className="btn btn-primary  ms-2 "
                          onClick={() => setShowEmail(false)}
                        >
                          {props.t("Back")}
                        </span>
                        {loading ? (
                          <button
                            className="btn btn-primary  disabled"
                            type="button"
                          >
                            {props.t("Loading")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary ms-2 "
                            type="submit"
                            onClick={() => props.getSendEmail2()}
                          >
                            {props.t("Send")}
                          </button>
                        )}
                      </>
                    ) : (
                      <span
                        className="btn btn-primary ms-2 "
                        onClick={() => setShowEmail(true)}
                      >
                        {props.t("Next")}
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md={7}>
                <iframe
                  src={props.link}
                  style={{ width: "100%", height: "100%" }}
                ></iframe>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
    </div>
  );
};

export default withTranslation()(SendEmail);
SendEmail.propTypes = {
  t: PropTypes.any,
};
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Table,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Modal,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../assets/images/def-img.svg";
import { useHistory } from "react-router-dom";
let clientName = "";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BrandModal from "../Brand/brand-modal";
import Select from "react-select";

const CreateAssests = (props) => {
  const history = useHistory();
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [image, setImage] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModals, setIsOpenModals] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [setActiveMargin] = useState(true);
  const [allCategories, setAllCategories] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [isOpenModalBrand, setIsOpenModalBrand] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brands, setBrands] = useState([]);

  const [referenceType, setReferenceType] = useState("pro");
  const [data, setData] = useState([]);
  const [document, setDocument] = useState("");
  const [setFileDocument] = useState("");
  const [assetID, setAssetID] = useState("");
  const [timer, setTimer] = useState(null);
  const [allRef, setAllRef] = useState([]);
  const [defaultRef, setDefaultRef] = useState("");

  const getAllClients = (e) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getContactTemplate(e);
    }, 1000);
    setTimer(newTimer);
  };
  const AssestsCreateAll = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    let subject_to_maintenance = 0;
    formData.append("reference", value.reference);
    formData.append("reference_number", e.target.reference_number.value);
    formData.append("name", value.name);
    formData.append("identifier", value.identifier);
    formData.append("serial_number", value.serial_number);
    formData.append("brand_id", selectedBrand);
    formData.append("model", value.model);
    formData.append("client_id", clientName.id);
    formData.append("address", value.address !== null ? value.address : "");
    if (e.target.subject_to_maintenance.value == "on") {
      subject_to_maintenance = 1;
    }
    formData.append("subject_to_maintenance", subject_to_maintenance);
    formData.append("start_of_warranty", e.target.start_of_warranty.value);
    formData.append("end_of_warranty", e.target.end_of_warranty.value);
    formData.append("main_image", image);
    formData.append("private_comments", e.target.private_comments.value);
    formData.append("description", value.description);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-assets`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          history.push(`/assets/${res.data.client.id}`);
          setAssetID(res.data.client.id);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  const getContactTemplate = (e) => {
    setData([]);
    if (e !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/clients?search=${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setError("");
            setData(res.data.clients);
          } else {
            setError(res.data.message);
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/clients`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setError("");
            setData(res.data.clients);
          } else {
            setError(res.data.message);
          }
        });
    }
  };
  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Client Asset`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
          res.data.reference?.map((item) => {
            if (item.by_default === "1") {
              setDefaultRef(item.prefix);
            }
          });
        } else {
          //   setError(res.data.message);
        }
      });
  };
  useEffect(() => {
    getContactTemplate();
    getReferences();
    getBrand();
  }, []);

  const selectClient = (e, item) => {
    clientName = item;
    setIsOpenModal(false);
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    );
  };

  const getBrand = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/brand`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        let optionBrands = [];
        if (res.data !== undefined) {
          {
            res.data.brands &&
              res.data.brands.map((brand) => {
                optionBrands.push({
                  value: brand.id,
                  label: brand.name,
                });
              });
          }
        }
        setBrands(optionBrands);
      });
  };


  const getType = (e) => {
    setReferenceType(e.target.value);
  };


  const setComImg = (e) => {
    setImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const filePreview = (e) => {
    setDocument(e.target.files[0]);
    setFileDocument(URL.createObjectURL(e.target.files[0]));
  };
  const createAttachment = (e, value) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("document", document);
    formData.append("description", e.target.description.value);
    formData.append("type", "attachments");
    formData.append("asset_id", assetID);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-asset-attachments`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  function handleSelectBrand(e) {
    setSelectedBrand(e.value);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Assets | Clouget</title>
        </MetaTags>
        <BrandModal
          isOpenModalBrand={isOpenModalBrand}
          setIsOpenModalBrand={setIsOpenModalBrand}
          setBrands={setBrands}
          brands={brands}
        />
        <Container fluid>
          <div className="create_company">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                AssestsCreateAll(e, v);
              }}
            >
              <div className="page-title-box">
                <Row className="align-items-center mt-3">
                  <Col md={6}>
                    <h6 className="page-title">{props.t("New Assets")}</h6>
                  </Col>
                  <Col md={6}>
                    <div className="float-end d-flex">
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          {props.t("Loading")}
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          {props.t("Submit")}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <label>{props.t("Reference")}</label>
                        <div className="d-flex">
                          <AvField
                            style={{
                              width: "100px",
                            }}
                            type="select"
                            name="reference"
                            value={defaultRef}
                            onChange={(e) => getType(e)}
                          >
                            {allRef &&
                              allRef.map((item, i) => (
                                <option key={i} value={item.prefix}>
                                  {item.prefix}
                                </option>
                              ))}
                            <option value="mannual">MANNUAL</option>
                          </AvField>
                          <div className="w-100 ps-3">
                            <Input
                              className="w-100 quantity"
                              type="number"
                              step="1"
                              min="1"
                              name="reference_number"
                              placeholder={props.t("Automatic")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 required">
                        <AvField
                          label={props.t("Asset Name")}
                          type="text"
                          name="name"
                          required="required"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Identifier")}
                          type="text"
                          name="identifier"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Serial Number")}
                          type="text"
                          name="serial_number"
                        />
                      </div>
                      <div className="mb-3">
                        <label>{props.t("Brand")}</label>
                        <div className="d-flex">
                          <div className="w-100 pe-2">
                            <Select
                              options={brands}
                              onChange={handleSelectBrand}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setIsOpenModalBrand(true);
                            }}
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                          >
                            {props.t("New")}
                          </button>
                        </div>
                      </div>
                      {/* <div className="mb-3">
                        <AvField
                          label={props.t("Brand")}
                          type="text"
                          name="brand"
                          value=""
                        />
                      </div> */}
                      <div className="mb-3">
                        <AvField
                          label={props.t("Model")}
                          type="text"
                          name="model"
                          value=""
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Client")}
                          type="text"
                          name="client_id"
                          value={clientName.legal_name}
                          onClick={() => {
                            setIsOpenModal(true), getContactTemplate();
                          }}
                          // readOnly
                          placeholder={props.t("Select a client")}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Address")}
                          type="text"
                          name="address"
                          value={clientName.address}
                        />
                      </div>
                      <div className="mb-3">
                        <label>{props.t("Subject to Maintenance")}</label>
                        <Input
                          type="checkbox"
                          className="ms-2"
                          name="subject_to_maintenance"
                        />
                      </div>
                      <div className="mb-3">
                        <label>{props.t("Start of the Warranty")}</label>
                        <DatePicker
                          selected={startDate}
                          onChange={(e) => setStartDate(e)}
                          dateFormat="yyyy-MM-dd"
                          //   minDate={new Date()}
                          name="start_of_warranty"
                          type="date"
                          className="is-untouched is-pristine av-valid form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <label>{props.t("End of the Warranty")}</label>
                        <DatePicker
                          selected={endDate}
                          onChange={(e) => setEndDate(e)}
                          dateFormat="yyyy-MM-dd"
                          //   minDate={new Date()}
                          type="date"
                          name="end_of_warranty"
                          className="is-untouched is-pristine av-valid form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("General")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Images")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={`${classnames({
                          active: customActiveTab === "3",
                        })}`}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Attachments")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({
                          active: customActiveTab === "4",
                        })}`}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      // disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Related")}
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="p-3">
                    <TabPane tabId="1">
                      <Row>
                        <Col lg="12">
                          <div className="mb-3">
                            <AvField
                              label={props.t("Description")}
                              type="textarea"
                              name="description"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Private Comments")}
                              type="textarea"
                              name="private_comments"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Created from")}
                              type="text"
                              name="created_from"
                              value="Web"
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg="3">
                          <div className="form-inline company_img img-flex">
                            <input
                              className="hidden d-none"
                              onChange={(e) => setComImg(e)}
                              type="file"
                              id="comImg"
                              name="main_image"
                            />
                            <label className="company_img-box" htmlFor="comImg">
                              <img src={imgPreview} />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col md={12}>
                          <div className="attach_btn">
                            <div
                              className="float-end d-none d-md-block btn btn-primary"
                              onClick={() => setIsOpenModals(true)}
                            >
                              New
                            </div>
                            <Table striped className="align-middle mt-5">
                              <thead>
                                <tr>
                                  <th>{props.t("Name")}</th>
                                  <th>{props.t("Description")}</th>
                                  <th>{props.t("Upload Date")}</th>
                                </tr>
                              </thead>
                              <tbody>{/* <AllAttachment /> */}</tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </AvForm>
          </div>
          <Modal size="xl" isOpen={isOpenModal} centered={true}>
            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
            <div className="modal-header">
              <Col md={6}>
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  {props.t("Client selections")}
                </h5>
              </Col>
              <Col md={6}>
                <div className="float-end d-flex">
                  <div className="me-3">
                    <input
                      className="form-control"
                      type="search"
                      placeholder={props.t("Search here")}
                      onChange={(e) => getAllClients(e.target.value)}
                    />
                  </div>
                  <span
                    className="cross__btn waves-effect waves-light"
                    onClick={() => setIsOpenModal(false)}
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </div>

            <div className="modal-body p-0 ">
              <Card>
                <CardBody>
                  <div className="overflow-auto w-100 select-from-row table-responsive">
                    <Table striped className="align-middle">
                      <thead>
                        <tr
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <th>{props.t("Reference")}</th>
                          <th>{props.t("Legal Name")}</th>
                          <th>{props.t("Name")}</th>
                          <th>{props.t("Ced/Ruc")}</th>
                          <th>{props.t("Phone")}</th>
                          <th>{props.t("Email")}</th>
                          <th>{props.t("Address")}</th>
                          <th>{props.t("Activity")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((item, i) => (
                            <tr onClick={(e) => selectClient(e, item)} key={i}>
                              <td>
                                {item.reference}
                                {item.reference_number}
                              </td>
                              <td>{item.legal_name}</td>
                              <td>{item.name}</td>
                              <td>{item.tin}</td>
                              <td>{item.phone_1}</td>
                              <td>{item.email}</td>
                              <td
                                style={{
                                  whiteSpace: "break-spaces",
                                  minWidth: "220px",
                                }}
                              >
                                {item.address}
                              </td>
                              <td>{getCurrentUser().email}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <div className="text-end">
                <button className="btn btn-primary" type="button">
                  Submit
                </button>
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  onClick={() => setIsOpenModal(false)}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </div>
          </Modal>
          <Modal size="lg" isOpen={isOpenModals} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                New Document
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => createAttachment(e)}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Document</label>
                      <input
                        type="file"
                        className="ms-3"
                        name="document"
                        onChange={filePreview}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Description</label>
                      <textarea
                        name="description"
                        className="form-control"
                      ></textarea>
                    </div>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <div className="text-end">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                    <button
                      className="btn btn-primary ms-2"
                      type="button"
                      onClick={() => setIsOpenModals(false)}
                    >
                      {props.t("Cancel")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(CreateAssests);
CreateAssests.propTypes = {
  t: PropTypes.any,
};

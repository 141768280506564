import React, { useState } from "react";
import axios from "axios";
import { Col, Modal, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";

export default function PdfModal(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const submitDefaultPdf = (e, value) => {
    let MailingFormat = "0";
    if (value.mailing_format) {
      MailingFormat = "1";
    }
    let priceTax = "0";
    if (value.price_after_tax) {
      priceTax = "1";
    }
    let mainImage = "0";
    if (value.include_main_image) {
      mainImage = "1";
    }
    const formData = new FormData();
    formData.append("format", e.target.format.value);
    formData.append("price_after_tax", priceTax);
    formData.append("mailing_format", MailingFormat);
    formData.append("include_main_image", mainImage);
    // formData.append("number_of_digit", value.number_of_digit);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/default-pdf/${props.defaultPdf.id}?_method=PUT`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          props.setDefaultPdf_modal(false);
          //   props.getDefaultPdfByOptions();
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          if (props.defaultPdf) {
            props.setDefaultPdf(res.data.data);
          }
          //   else {
          //     props.getDefaultPdfByOptions();
          //   }
          setError("");
          setSuccess("");
        }, 2000);
      });
  };
  return (
    <>
      <Modal size="md" isOpen={props.defaultPdf_modal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {props.defaultPdf.type}
          </h5>
        </div>
        <div className="modal-body">
          {success ? (
            <div className="mb-3">
              <Alert color="success">{props.t(success)}</Alert>
            </div>
          ) : null}

          {error ? (
            <div className="mb-3">
              <Alert color="danger">{props.t(error)}</Alert>
            </div>
          ) : null}
          <AvForm
            onValidSubmit={(e, v) => {
              submitDefaultPdf(e, v);
            }}
          >
            {/* <Row> */}
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  label="Format"
                  type="select"
                  name="format"
                  value={props.defaultPdf && props.defaultPdf.format}
                  // onChange={(e) => {
                  //   props.setFormat(e.target.value), setChange(!change);
                  // }}
                >
                  {props.defaultPdf.type == "Normal Invoice" ||
                  props.defaultPdf.type == "Purchase Invoice" ||
                  props.defaultPdf.type == "Refund Invoice" ? (
                    <option value="valued">Valued</option>
                  ) : props.defaultPdf.type == "Purchase Delivery Note" ||
                    props.defaultPdf.type == "Work Delivery Note" ||
                    props.defaultPdf.type == "Sales Delivery Note" ? (
                    <>
                      <option value="valued">Valued</option>
                      <option value="Without_values">Without values</option>
                      <option value="without_totals">Without totals</option>
                    </>
                  ) : props.defaultPdf.type == "Sales Order" ||
                    props.defaultPdf.type == "Purchase Order" ||
                    props.defaultPdf.type == "Work Order" ? (
                    <>
                      <option value="valued">Valued</option>
                      <option value="Without_values">Without values</option>
                      <option value="before_tax">Before tax</option>
                      <option value="without_totals">Without totals</option>
                    </>
                  ) : (
                    <>
                      <option value="valued">Valued</option>
                      <option value="Without_values">Without values</option>
                      <option value="before_tax">Before tax</option>
                      <option value="Pro_forma">Pro Forma</option>
                      <option value="without_totals">Without totals</option>
                    </>
                  )}
                </AvField>
              </div>
              <AvField
                label="Prices after tax"
                name="price_after_tax"
                type="checkbox"
                defaultChecked={
                  props.defaultPdf && props.defaultPdf.price_after_tax == "1"
                    ? true
                    : false
                }
              />

              <AvField
                label="Mailing format"
                name="mailing_format"
                type="checkbox"
                defaultChecked={
                  props.defaultPdf && props.defaultPdf.mailing_format == "1"
                    ? true
                    : false
                }
                //   onChange={(e) => {
                //     props.setMailingFormat(e.target.checked),
                //       setChange(!change);
                //   }}
              />

              <AvField
                label="Include the main image"
                name="include_main_image"
                type="checkbox"
                defaultChecked={
                  props.defaultPdf && props.defaultPdf.include_main_image == "1"
                    ? true
                    : false
                }
              />
            </Col>
            {/* </Row> */}

            <div className="modal-footer">
              <div className="text-end">
                {/* {loading ? (
                  <button
                    type="button"
                    className="btn btn-primary disabled"
                    disabled
                  >
                    Loading
                  </button>
                ) : ( */}
                <button
                  className="btn btn-primary"
                  type="submit"
                  //   onClick={() => props.setDefaultPdf_modal(false)}
                >
                  {/* {props.reference ? "Update" : "Save"} */}
                  {props.t("Save")}
                </button>

                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  onClick={() => props.setDefaultPdf_modal(false)}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Modal>
    </>
  );
}

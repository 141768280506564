import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let selectedRoleId = [];
let selectedRoleType = [];
let selectedRoleNum = [];
const AllTechnicalTables = (props) => {
  const [displayTechnical, setDisplayTechnical] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deleteUser = (Id) => {
    setdynamic_title("");
    setdynamic_description("");
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/technical-tables/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setdynamic_title("Deleted");
          setdynamic_description("File has been deleted.");
          if (displayTechnical?.reference_type === 'Work Order') {
            setdynamic_description("The work order has been deleted.");
          } else if (displayTechnical?.reference_type === 'Work Delivery Note') {
            setdynamic_description("The work note has been deleted.");
          } else {
            setdynamic_description("The estimate has been deleted.");
          }
          setDisplayTechnical(null);
        } else {
          setdynamic_title("Failed");
          setdynamic_description(res.data.message);
        }
        setsuccess_dlg(true);
      });
    //   .then(function (res) {
    //     setDisplayTechnical(null);
    //     setdynamic_description(res.data.message);
    //   });
  };

  useEffect(() => {
    selectedRoleId = [];
    selectedRoleType = [];
    selectedRoleNum = [];
  }, []);

  const getSelectedId = (e, check, type, num) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      selectedRoleType.push(type);
      selectedRoleNum.push(num);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueType = selectedRoleType.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueNum = selectedRoleNum.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      selectedRoleType = uniqueType;
      selectedRoleNum = uniqueNum;
      props.setSelectedIds(selectedRoleId);
      props.setSelectedType(selectedRoleType);
      props.setSelectedNum(selectedRoleNum);
    } else {
      selectedRoleId = props.selectedIds;
      selectedRoleType = props.selectedType;
      selectedRoleNum = props.selectedNum;
      var array = selectedRoleId;
      var array2 = selectedRoleType;
      var array3 = selectedRoleNum;
      var index = array.indexOf(e);
      var index2 = array2.indexOf(type);
      var index3 = array3.indexOf(num);
      if (index !== -1) {
        array.splice(index, 1);
      }
      if (index2 !== -1) {
        array2.splice(index2, 1);
      }
      if (index2 !== -1) {
        array3.splice(index3, 1);
      }
      props.setSelectedIds(selectedRoleId);
      props.setSelectedType(selectedRoleType);
      props.setSelectedNum(selectedRoleNum);
    }
  };
  //   useEffect(() => {
  //     selectedRoleId = [];
  //   }, []);

  //   const getSelectedId = (e, check) => {
  //     if (check.target.checked) {
  //       selectedRoleId.push(e);
  //       const uniqueID = selectedRoleId.filter((val, id, array) => {
  //         return array.indexOf(val) == id;
  //       });
  //       selectedRoleId = uniqueID;
  //       props.setSelectedIds(selectedRoleId);
  //     } else {
  //       selectedRoleId = props.selectedIds;
  //       var array = selectedRoleId;
  //       var index = array.indexOf(e);
  //       if (index !== -1) {
  //         array.splice(index, 1);
  //       }
  //       props.setSelectedIds(selectedRoleId);
  //     }
  //   };
  return (
    <>
      {displayTechnical !== null ? (
        <tr>
          <td>
            <input
              onChange={(e) =>
                getSelectedId(
                  displayTechnical.id,
                  e,
                  displayTechnical.reference_type,
                  displayTechnical.reference_number
                )
              }
              name="chk"
              value={displayTechnical.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {displayTechnical.reference}
              {displayTechnical.reference_number}
            </Link>
          </td>
          <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {displayTechnical.client_name}
            </Link>
          </td>
          {/* <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {displayTechnical.title}
            </Link>
          </td> */}
          <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {displayTechnical.created_by_name}
            </Link>
          </td>
          <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {props.t(
                displayTechnical.status == "pending"
                  ? "Pending"
                  : displayTechnical.status == "accepted"
                  ? "Accepted"
                  : displayTechnical.status == "closed"
                  ? "Closed"
                  : displayTechnical.status == "refused"
                  ? "Refused"
                  : displayTechnical.status == "invoiced"
                  ? "Invoiced"
                  : displayTechnical.status == "normal invoice"
                  ? "Normal Invoice"
                  : displayTechnical.status == "in_progress"
                  ? "In progress"
                  : displayTechnical.status == "pending invoice"
                  ? "Pending Invoice"
                  : displayTechnical.status
              )}
            </Link>
          </td>
          <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {displayTechnical.date}
            </Link>
          </td>
          <td>
            <Link to={`/technical-service/${displayTechnical.id}`}>
              {displayTechnical.amount == "0.00"
                ? "0.00"
                : displayTechnical.amount}
            </Link>
          </td>
          <td></td>
          <td>
            {getPermission().work_estimate.work_estimate.delete.is_checked ===
            1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Yes, delete it!")}
          warning
          showCancel
          
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteUser(displayTechnical.id);
            setconfirm_alert(false);
            //   setsuccess_dlg(true);
            //   setdynamic_title("Deleted");
            //   setdynamic_description("El Documento fue eliminado.");
          }}
          onCancel={() => setconfirm_alert(false)}
        ></SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success={dynamic_title == "Deleted" ? true : false}
          error={dynamic_title == "Failed" ? true : false}
          title={props.t(dynamic_title)}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {props.t(dynamic_description)}
        </SweetAlert>
      ) : null}
    </>
  );
};
export default withTranslation()(AllTechnicalTables);
AllTechnicalTables.propTypes = {
  t: PropTypes.any,
};

/* eslint-disable no-undef */
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Col, Row, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import countryList from "react-select-country-list";
const axios = require("axios").default;

const ListAddress = (props) => {
  //   const [displayPurchaseDelivery, setDisplayPurchaseDelivery] = useState(
  //     props.data
  //   );
  const [displayContact, setDisplayContact] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [address, setAddress] = useState(props.data.address);
  const [city, setCity] = useState(props.data.city);
  const [state, setState] = useState(props.data.state);
  const [type, setType] = useState(props.data.type);
  const [zip, setZip] = useState(props.data.zip_code);
  const [country, setCountry] = useState(props.data.country);
  const [info, setInfo] = useState(props.data.extra_information);
  const [description, setDescription] = useState(props.data.description);

  const delAddress = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/supplier-addresses/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setDisplayContact(null);
        setdynamic_description(res.data.message);
      });
  };

  const getSingleAddress = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/supplier-addresses/${displayContact.id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setContact(res.data.supplier_address);
          //   setCountry(res.data.supplier_address.country);
        }
      });
  };
  //   useEffect(() => {
  //     getSingleAddress();
  //   }, []);
  const supplierContact = () => {
    //   const supplierContact = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("address", address);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("zip_code", zip);
    formData.append("country", country);
    formData.append("extra_information", info);
    formData.append("type", type);
    formData.append("description", description);
    // formData.append("supplier_id", displayContact.id);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/supplier-addresses/${displayContact.id}?_method=PUT`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          setIsOpenAddress(false);
          setLoading(false);
          setDisplayContact(res.data.supplier_addresses);
          //   setCountry(res.data.supplier_addresses.country);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };
  if (displayContact === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.address}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.city}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.state}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.zip_code}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.country}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.extra_information}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenAddress(true), getSingleAddress();
            }}
          >
            {displayContact.description}
          </td>
          <td>
            <button
              type="button-input"
              className="btn btn-danger"
              onClick={() => setconfirm_alert(true)}
            >
              {props.t("Delete")}
            </button>
          </td>
          <td role="button">{/* {displayPurchaseOrder.activity} */}</td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              delAddress(displayContact.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("File has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
        <Modal size="lg" isOpen={isOpenAddress} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Edit Other Addresses
            </h5>
            <button
              type="button"
              className="cross__btn btn btn-primary waves-effect waves-light"
              onClick={() => setIsOpenAddress(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <AvForm
            // onValidSubmit={(e, v) => {
            //   createOtherAddress(e, v);
            // }}
            >
              <Row>
                <Col lg={6}>
                  <AvField
                    className="mb-3"
                    label="Address"
                    type="text"
                    name="address"
                    value={contact.address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <AvField
                    className="mb-3"
                    label="State/Province"
                    type="text"
                    name="state"
                    value={contact.state}
                    onChange={(e) => setState(e.target.value)}
                  />
                  <div className="mb-3">
                    <AvField
                      type="select"
                      name="country"
                      label="Country"
                      value={contact.country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countryList().data.map((item) => (
                        <option value={item.value} key={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </AvField>
                  </div>
                  <AvField
                    className="mb-3"
                    label="Type"
                    type="select"
                    name="type"
                    value={contact.type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="invoicing">Invoicing</option>
                    <option value="delivery">Delivery/Work</option>
                    <option value="other">Other</option>
                  </AvField>
                </Col>
                <Col lg={6}>
                  <AvField
                    className="mb-3"
                    label="City/town"
                    type="text"
                    name="city"
                    value={contact.city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <AvField
                    className="mb-3"
                    label="Zip code"
                    type="text"
                    name="zip_code"
                    value={contact.zip_code}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </Col>
                <Col lg={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      label="Extra Info."
                      type="text"
                      name="extra_information"
                      value={contact.extra_information}
                      onChange={(e) => setInfo(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <AvField
                      label="Description"
                      type="textarea"
                      name="description"
                      value={contact.description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="modal-footer">
                <div className="text-end">
                  {loading ? (
                    <button
                      className="btn btn-primary  disabled"
                      type="button"
                      disabled
                    >
                      Loading
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={() => supplierContact()}
                    >
                      Update
                    </button>
                  )}
                  <button
                    className="btn btn-primary ms-2"
                    type="button"
                    onClick={() => setIsOpenAddress(false)}
                  >
                    {props.t("Cancel")}
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Modal>
      </>
    );
};

export default ListAddress;
